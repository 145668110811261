/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

// OrderDetails page components
import PaymentDetails from "./components/PaymentDetails";
import BillingInformation from "./components/BillingInformation";
import OrderSummary from "./components/OrderSummary";
import SnapshotOrderSummary from "./components/SnapshotOrderSummary";

function OrderDetails({ data }) {
  return (
    <MDBox>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <MDBox pt={1}>
            <MDBox>
              <BillingInformation
                data={data?.purchase_prod?.[0]}
                purchased_data={data}
              />
            </MDBox>
            <Divider />
            {data?.purchase_prod?.map((prod, key) => (
              <Card sx={{ marginBottom: "1rem" }}>
                <MDBox p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <PaymentDetails data={prod} />
                    </Grid>
                    <Grid item xs={6}>
                      {prod.prod_data?.type ? (
                        <SnapshotOrderSummary data={prod} />
                      ) : (
                        <OrderSummary data={prod} />
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            ))}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default OrderDetails;

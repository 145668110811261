import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import { Select } from "antd";
import MUISelect, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import Multiselect from '../Multi_Selecte/Multiselect'
import localforage from "localforage";
import Multiselect from "./components/Multiselect";
import TypeModal from "layouts/agency/snapshots/components/TypeModal";
import env from "config";
import { TextareaAutosize } from "@mui/material";
const Category_Edit = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [name, setname] = useState(props.data.name);

  const BASE_URL = `${env.API_URL}/v1`;

  const inputRef = useRef();
  const multi_categroies = [
    {
      label: "Snapshot",
      value: "snapshot",
    },
    {
      label: "Service",
      value: "service",
    },
    {
      label: "Websites",
      value: "websites",
    },
    {
      label: "Funnels",
      value: "funnels",
    },
    {
      label: "Automations",
      value: "automation",
      type: "main",
    },
  ];

  const handlemodal = () => {
    console.log(props.handleChange);
    setTimeout(() => {
      setOpenAddProduct(true);
      inputRef.current.value = props.data.name;
    }, 100);
  };
  useEffect(async () => {
    // inputRef.current.value = props.data.name
  }, [props]);
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      var axios = require("axios");

      setProcessing(true);
      var type = JSON.parse(e.target.type.value);

      var data = "";
      var index = {};
      type.forEach((element) => {
        if (element.type == "custom") {
          index[element.label] = 1;
        }
      });
      data = {
        name: e.target.name.value,
        description: e.target.description.value,
        agency_id: props.data.agency_id,
        custom: index,
        type: type,
      };
      console.log(data);
      axios
        .post(BASE_URL + "/snapshot/category/update/" + props.data._id, {
          data,
        })
        .then(async function (response) {
          console.log(response.data);
          props.handleChange(true);

          props.handlemessag("category edit successfully", "success");
        })
        .catch(function (error) {
          console.log(error);
          props.handlemessag("category not edit successfully", "false");
        });
      setOpenAddProduct(false);
      // const { response } = await AppService.stripe.addSubscription({ payload })

      // setProcessing(false)
      // onClose(response?.data)
    };
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Category
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <input
                  type="name"
                  label="Name"
                  defaultValue={props.data.name}
                  name="name"
                  ref={inputRef}
                  className="form-control"
                />
              </MDBox>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={1}
                defaultValue={props.data.description}
                className="form-control"
                name="description"
                placeholder="description"
                style={{ width: "100%", paddingLeft: "13px" }}
              />
              <MDBox mb={1} mt={2} sx={{ fontSize: "14px" }}>
                <Multiselect
                  edit_data={props.data.type}
                  data={props.multiselect}
                  name="type"
                />
              </MDBox>
            </Grid>

            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Edit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <EditIcon
        sx={{ marginRight: "15px", cursor: "pointer" }}
        onClick={handlemodal}
      />

      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Category_Edit;

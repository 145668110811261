import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { RemoveRedEye } from "@mui/icons-material";
import PurchasesDetails from "../purchases_details";
import { useAppServices } from "hook/services";
const Default = ({ data }) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [apps_data, setapps_data] = useState([]);
  const AppService = useAppServices();
  const getAppconfiguration = async (apps_data) => {
    const { response } = await AppService.app.getPurchasedBundle({
      query: `bundle_id=${data.purchaseapp_data?.pkg_id}`,
    });
    console.log(response, "getPurchasedBundle");
    if (response) {
      setapps_data(response.data);
    }
  };
  const onLoad = () => {
    if (data.type == "bundle" || data.type == "reselling_bundle") {
      getAppconfiguration();
    }
  };
  useEffect(async () => {
    onLoad();
  }, []);
  function AddProduct({ open, onClose, data }) {
    return (
      <MDModal
        open={open}
        onClose={onClose}
        width={"auto"}
        height={"80vh"}
        overflow={"auto"}
      >
        <PurchasesDetails data={data} apps_data={apps_data} />
      </MDModal>
    );
  }
  const closeAddProduct = () => {
    setOpenAddProduct(false);
  };
  return (
    <>
      <RemoveRedEye
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenAddProduct(true)}
      />

      <AddProduct open={openAddProduct} onClose={closeAddProduct} data={data} />
    </>
  );
};

export default Default;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import { Grid } from '@mui/material'
import { useBrandInfo } from 'context/brand'
import DeleteIcon from '@mui/icons-material/Delete';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import homeDecor1 from 'assets/images/home-decor-1.jpg'
import React, { useState, useEffect } from 'react'
import MDButton from 'components/MDButton'
import Category_Edit from 'layouts/agency/components/Category_Modal/Edit'
import { Edit } from '@mui/icons-material'
import env from 'config'
function ComplexStatisticsCard({ color, title, count, percentage, icon, data, category_data ,handleChange}) {
  const [brand] = useBrandInfo()
  const [category_edit_data, setcategory_edit_data] = useState('')

  const handledelete=(id)=>()=>{
    const BASE_URL = `${env.API_URL}/v1`

    // console.log)
    var axios = require('axios')

    axios.post(BASE_URL+'/snapshot/category/delete/'+id).then(async function (response) {
      console.log(response.data);
    })
      .catch(function (error) {
        console.log(error);
      });
  }
    // useEffect(async () => {
    //   console.log(handleChange, 'handleChange')
    //   console.log(category_data, 'NewSnapshot')
    // }, [])
  return (
    <>
      {category_data.map((category, key) => (
        <Card style={{ marginBottom: '50px' }}>
          <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
            <MDBox
              variant="gradient"
              bgColor={color}
              color={color === 'light' ? 'dark' : 'white'}
              coloredShadow={color}
              borderRadius="xl"
              display="flex"

              alignItems="center"
              width="100%"
              height="4rem"
              mt={-3}
            >
              <Grid container>
                <Grid item xs={6}>

                  <MDTypography variant="button"  ml={3} display="flex" justifyContent="start" fontWeight="bold" color="white">
                    {category.name}
                  </MDTypography>
                </Grid>
                <Grid item xs={6}>

                  <MDTypography variant="h4" mr={5} display="flex" justifyContent="end" fontWeight="bold" color="white">
                    
                        <Category_Edit  data={category} handleChange={handleChange}/>

                       

                    <DeleteIcon sx={{ cursor: "pointer" }} onClick={handledelete(category._id)}/>
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
          <MDBox pb={2} mt={5} px={2}>
            <Grid container spacing={4}>
              {data.NewSnapshots.map((item, key) => (
                item.category.includes(category._id) ? (<Grid item xs={12} md={3} >
                  <MDBox mb={3}>
                    <DefaultProjectCard
                      image={item.image_url}
                      label={`App #${key + 1}`}
                      title={item.name}
                      handleChange={handleChange}
                      item={item}
                      data={data}
                      description={
                        (
                          <>
                            <div>App Details</div>
                            <div>
                              Price: {item.payable=="no"?'Free':'$'+item.purchase_listener.substr(0, item.purchase_listener.indexOf('('))}
                            </div>
                          </>
                        ) ||
                        'As Uber works through a huge amount of internal management turmoil.'
                      }
                      action={{
                        type: 'internal',
                        route: `/${brand.ghl?.location_id}/snapshot/iframs`,
                        color: 'info',
                        label: 'Edit',
                        
                      }}
                      button_label='Delete'
                    />
                  </MDBox>
                </Grid>) : (<></>)

              ))}
            </Grid>
          </MDBox>
        </Card>
      ))}

    </>

  )
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: 'info',
  percentage: {
    color: 'success',
    text: '',
    label: '',
  },
}

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'white',
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    data: PropTypes.array,
    category_data: PropTypes.array,
  }),
  icon: PropTypes.node.isRequired,
  handleChange:PropTypes.func,
}

export default ComplexStatisticsCard

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Loader from "examples/Loader";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples

import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "../DefaultInfoCard";

// card page components
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import PaymentMethod from "../PaymentMethod";
import Invoice from "../Invoice";
import localforage from "localforage";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
// import cardInformation from './components/cardInformation'
// import Transactions from './components/Transactions'
import Billing from "../Billing";
import env from "config";
function Card_details() {
  const [agency] = useAgencyInfo();
  const AppService = useAppServices();

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [customer, setCustomer] = useState({});
  const [total_locations, setTotalLocations] = useState(0);
  const [primaryCard, setPrimaryCard] = useState({});
  const [agency_stripe, setagency_stripe] = useState("");
  const [prices, setprices] = useState([]);
  const [message, setmessage] = useState("");
  const [errormsg, seterrormsg] = useState("");
  const [package_id, setpackage_id] = useState("");
  const [processing, setprocessing] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const BASE_URL = `${env.API_URL}/v1`;

  console.log(subscriptions);
  var axios = require("axios");
  const getStripe = async (e) => {
    const { response } = await AppService.stripe.filter({
      id: agency._id,
    });
    console.log(response, "responseresponse");
    if (response.data.stripe_data) {
      setagency_stripe(response.data.stripe_data);
      setprocessing(false);
    } else {
      setagency_stripe("");
      setprocessing(false);
    }
  };
  useEffect(async () => {
    getStripe();
    // await axios
    //   .get(BASE_URL + "/snapshot/agency/stripe/filter/" + agency._id)
    //   .then(async function (response) {
    //     if (response.data.data.stripe_data) {
    //       setagency_stripe(response.data.data.stripe_data);
    //       console.log(response.data.data.stripe_data, "agency_stripe");
    //     }
    //     if (response.data.data.prices.data) {
    //       setprices(response.data.data.prices.data);
    //     }
    //     // console.log(response.data,'card')
    //     setprocessing(false);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     setprocessing(false);
    //   });
  }, [refresh]);
  const handlemessage = (e, type) => {
    // alert(type)
    setrefresh(!refresh);
    if (type == "success") {
      setmessage(e);
    } else {
      seterrormsg(e);
    }
    setTimeout(() => {
      seterrormsg("");
      setmessage("");
    }, 3000);
  };
  const getPaymentMethods = async () => {
    const { response } = await AppService.agency.get_payment_methods({
      query: `customer_id=${agency.stripe?.customer_id}`,
    });

    const getPrimaryCard = () =>
      response.data.find(
        (item) =>
          item.id == response.customer.invoice_settings?.default_payment_method
      );

    if (response) {
      setPaymentMethods(response.data);
      setPrimaryCard(getPrimaryCard());
      setCustomer(response.customer);
    }
  };

  const getSubscriptions = async () => {
    const { response } = await AppService.agency.subscriptions({
      query: `_id=${agency._id}`,
    });

    if (response) {
      setSubscriptions(response.data.subscriptions);
      setTotalLocations(response.data.total_locations);
    }
  };

  const onLoad = () => {
    getPaymentMethods();
    getSubscriptions();
  };

  useEffect(onLoad, []);

  console.log(primaryCard);

  return (
    <MDBox mt={4}>
      {processing ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8}>
                <Grid container justifyContent="center" spacing={3}>
                  {errormsg != "" ? (
                    <Stack sx={{ width: "90%" }} spacing={2}>
                      <Alert severity="error">{errormsg}</Alert>
                    </Stack>
                  ) : (
                    <></>
                  )}
                  {message != "" ? (
                    <Stack sx={{ width: "90%" }} spacing={2}>
                      <Alert severity="success">{message}</Alert>
                    </Stack>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12} xl={5}>
                    <MDBox maxWidth={350}>
                      {agency_stripe == "" ? (
                        <MasterCard
                          number={`****************`}
                          holder={"N/A"}
                          expires={"**" / "**"}
                        />
                      ) : (
                        <MasterCard
                          number={`************${
                            agency_stripe?.card[0]?.last4 || "****"
                          }`}
                          holder={agency_stripe?.name}
                          expires={`${
                            agency_stripe?.card[0]?.exp_month || "**"
                          }/${
                            agency_stripe?.card[0]?.exp_year
                              .toString()
                              .substr(2) || "**"
                          }`}
                        />
                      )}
                    </MDBox>
                  </Grid>
                  {/* {
                prices[2]?.id == package_id ?
                  (
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                      disabled="true"
                        icon="account_balance_wallet"
                        title="Monthly"
                        description=""
                        value={prices[2] ? `$${prices[2].unit_amount / 100} / mo` : 'N/A'}
                        price_id={prices[2] ? `${prices[2].id}` : ''}
                        handlemessage={handlemessage}
                      />
                    </Grid>
                  )
                  :
                  (<></>)
              }
              {
                prices[0]?.id == package_id ?
                  (
                    <Grid item xs={12} md={7} xl={3}>
                      <DefaultInfoCard
                      disabled="true"
                        icon="account_balance_wallet"
                        title="Yearly"
                        description=""
                        value={prices[0] ? `$${prices[0].unit_amount / 100} / Yr` : 'N/A'}
                        price_id={prices[0] ? `${prices[0].id}` : ''}

                        handlemessage={handlemessage}


                      />
                    </Grid>
                  )
                  :
                  (<></>)
              } */}
                  {/* {package_id == null ? (
                    <>
                      <Grid item xs={12} md={6} xl={6}>
                        <DefaultInfoCard
                          icon="account_balance_wallet"
                          title="Upgrade To Premium"
                          description="Belong Interactive"
                          value={
                            prices[2]
                              ? `$${prices[2].unit_amount / 100} / mo`
                              : "N/A"
                          }
                          price_id={prices[2] ? `${prices[2].id}` : ""}
                          handlemessage={handlemessage}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )} */}

                  <Grid item xs={12}>
                    <PaymentMethod
                      agency_stripe={agency_stripe}
                      paymentMethodState={{ paymentMethods, setPaymentMethods }}
                      customerState={{ customer, setCustomer }}
                      handlemessage={handlemessage}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Invoice />
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </MDBox>
  );
}

export default Card_details;

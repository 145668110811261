
import MDButton from 'components/MDButton'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import { useFormik } from 'formik'
import MDInput from 'components/MDInput'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import localforage from 'localforage'
import env from 'config'
import Multiselect from './inputs/Multiselect'
import ChargeWrapper from './ChargeWrapper'
const ChargeCustomer = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [agency_id, setagency_id] = useState()
  const [processing, setProcessing] = useState(false)
  const BASE_URL = `${env.API_URL}/v1`

  useEffect(async () => {
    const localBrand = await localforage.getItem('user');
    setagency_id(localBrand.agency_id)
    console.log(props,'props')
  }, [])
  function AddProduct({ open, onClose }) {

    const initState = {
      name: '',
    }


    const handleSubmit = async (e) => {
      e.preventDefault();
      if (e.target.name.value != '') {
        var axios = require('axios')

        setProcessing(true)
        const user = JSON.parse(e.target.user.value);
        const locations = JSON.parse(e.target.locations.value);
        const snapshots = JSON.parse(e.target.snapshots.value);
        var actual_price=''
        const price = e.target.price.value;
        // const charge = e.target.charge.value;
        if(e.target.charge){
          actual_price=e.target.charge.value
        }else{
          actual_price=e.target.price.value
        }

        const data = {
          user: user,
          snapshots:snapshots,
          locations: locations,
          charge: actual_price,
          actual_price: price,
        }
        console.log(data)
        axios.post(BASE_URL+'/snapshot/create_snapshot/custom_load/'+agency_id, { data }).then(async function (response) {
          console.log(response.data);
          props.handleChange(true)
          setOpenAddProduct(false)
        })
          .catch(function (error) {
            console.log(error);
          setOpenAddProduct(false)

          });
        // const { response } = await AppService.stripe.addSubscription({ payload })

        setProcessing(false)
        // onClose(response?.data)
      }else{
        alert('please fill the requried field!')
      }
    }
      const handleSelectChange = (e, { value }, name) => {
        formik.setValues({ ...formik.values, [name]: value })
      }

      const formik = useFormik({
        initialValues: { ...initState },
        onSubmit: handleSubmit,
      })
      return (
        <MDModal open={open} onClose={onClose}>
          <MDBox>
            <MDTypography component="h1" mb={3} variant="h5">
              Load Snapshot For User
            </MDTypography>

            <MDBox component="form" onSubmit={handleSubmit} role="form" sx={{ width: 335 }}>
           <ChargeWrapper charge_data={props.charge_data}/>

              <MDBox> 
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  sx={{ mt: 4, mb: 1 }}
                  loading={processing}
                 disabled={processing}
                  fullWidth
                >
                  Add
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDModal>
      )
       }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id) 
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton
        sx={{ marginLeft: 'auto' }}
        variant="outlined"
        color="info"
        onClick={() => setOpenAddProduct(true)}
      >
        Make Purchase
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />

    </>
  )
}

export default ChargeCustomer
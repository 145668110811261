
import MDButton from 'components/MDButton'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import { useFormik } from 'formik'
import MDInput from 'components/MDInput'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit';
import { useRef } from 'react';

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import env from 'config'
import localforage from 'localforage'
const Editcustom_link = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [agency_id, setagency_id] = useState()
  const [name, setname] = useState()


  const BASE_URL = `${env.API_URL}/v1`
  var type=props.type;
  const nameRef = useRef();


  if(type=="both"){
    type=[
      {
          "label": "Snapshot",
          "value": "snapshot"
      },
      {
          "label": "Service",
          "value": "service"
      },
      {
          "label": "Funnels",
          "value": "funnels"
      },
      {
          "label": "Websites",
          "value": "websites"
      },
  ]
  }
  useEffect(async() => {
    const localBrand = await localforage.getItem('user');
    setagency_id(localBrand.agency_id)
    setTimeout(() => {
      console.log(props.data.label)
      nameRef.current.value=props.data.label
      console.log(nameRef)


      }, 100);
  }, [])
    function AddProduct({ open, onClose }) {
    
        const initState = {
          name: '',
        }
        const [processing, setProcessing] = useState(false)
        
      
        const handleSubmit = async (e) => {
      e.preventDefault();

        var axios = require('axios')
          // console.log(JSON(type))
          setProcessing(true)
          const name=e.target.name.value.replace(/\s/g, '');
          var data='';
          
          if(props.type=="both"){

             data={
              name:name,
              agency_id:agency_id,
              type:[
                {
                    "label": "Snapshot",
                    "value": "snapshot"
                },
                {
                    "label": "Websites",
                    "value": "websites"
                },
                {
                    "label": "Funnels",
                    "value": "funnels"
                },
                {
                    "label": "Service",
                    "value": "service"
                }
            ]
            
            }
          }else if(type=="custom"){
            data={
              name:name,
              agency_id:agency_id,
              type:[
                {
                    "label": props.name,
                    "value": props.name
                } 
            ]
            
            }
          }else{
             data={
              name:name,
              agency_id:agency_id,
              type:[
                {
                    "label": props.type,
                    "value": props.type
                } 
            ]
            
            }
          }
          // console.log(data)
          axios.post(BASE_URL+'/snapshot/custom_link/update/'+props.data.value, { data }) .then(async function (response) {
            console.log(response.data);
            props.handleChange(true)
            setOpenAddProduct(false)
            // props.handleMessage('Category Added Successfully','true')
          })
          .catch(function (error) {
            console.log(error);
            setOpenAddProduct(false)
            props.handleMessage(error.response.data.message,'false')

          });
          // const { response } = await AppService.stripe.addSubscription({ payload })
          
          // setProcessing(false)
          // onClose(response?.data)
        }
        const handleSelectChange = (e, { value }, name) => {
          formik.setValues({ ...formik.values, [name]: value })
        }
        
        const formik = useFormik({
          initialValues: { ...initState },
          onSubmit: handleSubmit,
        })
        return (
          <MDModal open={open} onClose={onClose}>
          <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
          Snapshot Category
              </MDTypography>
      
              <MDBox component="form" onSubmit={handleSubmit} role="form" sx={{ width: 335 }}>
              <Grid item md={12}>
              <MDBox mb={1}>
                <MDInput
                  type="name"
                  label="Name"
                  name="name"
                  ref={nameRef}
                  fullWidth
                />
              </MDBox>
              <input type="hidden" name="type" value={type}/>
            </Grid>
      
                <MDBox>
                
                  <MDButton
                    variant="gradient"
                    color="info"
                    type="submit"
                    sx={{ mt: 4, mb: 1 }}
                    fullWidth
                  >
                    Edit
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDModal>
        )
      }
      const closeAddProduct = (subscription) => {
        // if (subscription?._id) 
        setOpenAddProduct(false)
      }
  return (
    <>
        
          <EditIcon sx={{ marginTop: "8px", cursor: "pointer" }}   onClick={() => setOpenAddProduct(true)} />
                <AddProduct open={openAddProduct} onClose={closeAddProduct} />

    </>
  )
}

export default Editcustom_link
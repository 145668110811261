import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import localforage from "localforage";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import env from "config";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import "./style.css";
import { useUserInfo } from "context/user";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const BuyModal = (props) => {
  const AppService = useAppServices();
  const [user] = useUserInfo();
  const [
    agency,
    Update,
    clear,
    updateAgency,
    locationSettings,
    updateLocationSettings,
  ] = useAgencyInfo();
  const stripePromise = loadStripe(agency.stripe.publish_key);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [brand, setbrand] = useState([]);
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();
  const params = useParams();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const inputRef = useRef();
  var path = pathname.split("/")[2];
  // const locationSettings = useAgencyInfo()[5];

  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  var axios = require("axios");
  let navigate = useNavigate();
  console.log(props, "props.priceprops.price");
  const MakePrice = (item) => {
    console.log(item, "MakePrice");
    var totalprice = 0;
    if (item.setup_payable == "yes") {
      totalprice += parseFloat(item.setup_fee);
    }
    if (item.management_payable == "yes") {
      totalprice += parseFloat(
        item.management_sub.substr(0, item.management_sub.indexOf("("))
      );
    }
    // for (let index = 0; index < item.apps.length; index++) {
    //   const element = item.apps[index];
    //   var newArray = configapps.filter(function (e) {
    //     return (
    //       e.app_id ===
    //       element.value.substring(
    //         element.value.indexOf("(") + 1,
    //         element.value.indexOf(")", element.value.indexOf("("))
    //       )
    //     );
    //   });
    //   console.log(newArray);
    //   if (newArray.length) {
    //     var temp_price = newArray[0].selling_price?.value.substr(
    //       0,
    //       newArray[0].selling_price?.value.indexOf(" ")
    //     );
    //     totalprice += parseFloat(temp_price);
    //   } else {
    //     totalprice +=
    //       parseFloat(element.value.substr(0, element.value.indexOf(" "))) * 3;
    //   }
    // }
    return totalprice;
  };
  const handlemodal = async (newState) => {
    if (brand == null) {
      setOpenAddProduct(true);
      // props.handleAlert(
      //   "for purchase a snapshot make sure you must be login!",
      //   "auth"
      // );
    } else {
      setOpenAddProduct(true);
    }
  };
  useEffect(async () => {
    const brand_data = await localforage.getItem("brand");
    console.log(props.data, "fgyu");
    // alert(brand_data)
    setbrand(brand_data);
  }, []);
  function AddProduct({ open, onClose, handleAlert, stripe_data }) {
    const [processing, setProcessing] = useState(false);
    const stripe = useStripe();

    const handleSubmit = async (newState) => {
      // e.preventDefault();

      setProcessing(true);

      if (stripe_data != "") {
        const payload = {
          ...props.data,
          price: props.price,
          user_id: user._id,
          agency_id: agency._id,
          seller_agency_id: params.agency_id,
        };
        console.log(payload);
        const { response } = await AppService.bundles.load({ payload });
        if (response) {
          if (response.status == "completed") {
            setProcessing(false);
            onClose();
            props.handleRefresh();
          } else {
            stripe
              .confirmCardPayment(response.data.client_secret, {})
              .then(async function (result) {
                const status = "succeeded";
                if (result?.paymentIntent?.status == "succeeded") {
                  const payload = {
                    ...props.data,
                    user_id: user._id,
                    agency_id: agency._id,
                    price: props.price,
                    seller_agency_id: params.agency_id,
                    agency_sub: response.agency_sub,
                    sub_id: response.subscription_data.id,
                    status: "succeeded",
                  };
                  const update_result =
                    await AppService.bundles.confirm_subscription({
                      payload,
                    });
                  if (update_result.response) {
                    setProcessing(false);
                    onClose();
                    props.handleRefresh();
                  }
                } else {
                  setProcessing(false);
                  // alert("fyg");
                  onClose();
                  props.handleRefresh();
                }
              });
          }
        } else {
          setProcessing(false);
          props.handleRefresh();
          setOpenAddProduct(false);
        }
      } else {
        setProcessing(false);
        onClose();

        alert(
          "For Purchase a Bundle make Sure Your Card Must Be Registered!",
          "billing"
        );
      }
    };
    return (
      <MDModal open={open} onClose={onClose} className={"buy--modal"}>
        <div>
          <form className="form">
            <div className="banner"></div>
            <label className="title">
              {props.data.type == "service"
                ? "Get Your Service Purchased"
                : "Get Your Offer Loaded"}
            </label>
            <label className="title-main">{props.data.name}</label>

            <div className="benefits">
              <span>Description</span>
              <ul>
                <li>
                  <span>{props.data.description}</span>
                </li>
              </ul>
            </div>
            {props.data.setup_fee != "" && props.data.setup_fee != undefined ? (
              <div className="benefits">
                <span>SetUp Fee</span>
                <ul>
                  <li>
                    <span>
                      {locationSettings.currency_sign} {props.data.setup_fee}
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
            {props.data.management_sub != "" &&
            props.data.management_sub != undefined ? (
              <div className="benefits">
                <span>Management price</span>
                <ul>
                  <li>
                    <span>
                      {locationSettings.currency_sign}{" "}
                      {props.data.management_sub?.substr(
                        0,
                        props.data.management_sub?.indexOf("(")
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
            <div className="modal--footer">
              <label className="price">
                <sup>{locationSettings.currency_sign}</sup>
                {MakePrice(props.data)}
              </label>
              <MDButton
                loading={processing}
                disabled={processing}
                className="upgrade--btn"
                type="button"
                onClick={handleSubmit}
              >
                Confirm to Buy
              </MDButton>
            </div>
          </form>
        </div>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  // const handleAlert = async (newState) => {
  //   const localBrand = await localforage.getItem("loc_data");
  //   setState({ ...state, open: true });
  //   setTimeout(() => {
  //     navigate(
  //       `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/settings`
  //     );
  //   }, 2000);
  // };
  return (
    <>
      {props?.type == "iframe_page" ? (
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          onClick={handlemodal}
          sx={{ px: 2, py: 1, width: "60%" }}
          fullwidth
        >
          Buy Bundle ({props.data.name})
        </MDButton>
      ) : (
        <MDButton
          variant="outlined"
          size="small"
          color="primary"
          className="purchase-btn"
          onClick={handlemodal}
        >
          {props.label}
        </MDButton>
      )}
      <Elements stripe={stripePromise}>
        <AddProduct
          open={openAddProduct}
          stripe_data={props.stripe_data}
          onClose={closeAddProduct}
          handleAlert={props.handleAlert}
        />
      </Elements>
    </>
  );
};

export default BuyModal;

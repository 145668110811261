/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate, useParams } from "react-router-dom";
import "./styles/style.css";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "./SidenavCollapse";
import ghllogo from "assets/images/2.png";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import UpgradeModal from "./UpgradeModal";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import SidenavItem from "./SidenavItem";
import SidenavList from "./SidenavList";
import { useUserInfo } from "context/user";
import { useAgencyInfo } from "context/agency";

function Sidenav({
  color,
  brand,
  brand_data,
  user_data,
  level,
  agency,
  settings,
  brandName,
  routes,
  Users,
  CategoryData,
  ...rest
}) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const collapseName = location.pathname.split("/")[2];
  const parent_path = location.pathname.split("/")[1];
  const items = location.pathname.split("/").slice(1);
  const itemParentName = items[0];
  const itemName = items[items.length - 1];
  const [user] = useUserInfo();
  // alert(itemName)
  console.log(parent_path, "useruseruseruser");
  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // alert(params.user_id)
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();
    // alert(itemName)
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);
  if (level == "settings") {
    var renderRoutes = settings.map(({ name, route, key }) => {
      let returnValue = (
        <NavLink key={name} to={route}>
          <SidenavCollapse
            name={name}
            // icon={icon}
            active={key === itemName}
          />
        </NavLink>
      );
      return returnValue;
    });
  } else if (params.agency_id) {
    var renderRoutes = CategoryData.map(({ name, _id }) => {
      let returnValue = (
        <NavLink key={_id} to={`/agency/market/${params.agency_id}/${_id}`}>
          <SidenavCollapse
            name={name}
            // icon={icon}
            active={_id === params.cat_id}
          />
        </NavLink>
      );
      return returnValue;
    });
  } else {
    var renderRoutes = Users.map((item) => {
      let returnValue = (
        <NavLink
          key={item.user.agency_id}
          to={`./${item.user.agency_id}/business Info`}
        >
          <SidenavCollapse
            name={item.agency?.profile?.bussiness_name}
            // icon={icon}
            active={item.user.agency_id === collapseName}
          />
        </NavLink>
      );
      return returnValue;
    });
  }

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      brand_data={brand_data}
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {/* {parent_path == "settings" ? (
        <MDBox pt={2} pl={2}>
          <MDButton size="small" onClick={() => navigate("./dashboard")}>
            Go Back
          </MDButton>
        </MDBox>
      ) : (
        <MDBox />
      )} */}
      <MDBox pt={2} pl={2}>
        <MDButton size="small" onClick={() => navigate("/agency/market")}>
          Go Back
        </MDButton>
      </MDBox>
      <MDBox
        pt={parent_path == "settings" ? 1 : 3}
        pb={1}
        px={4}
        textAlign="center"
      >
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDBox p={2} pb={0} mt="auto">
        {/* <UpgradeModal/> */}
      </MDBox>
      <MDBox p={2} mt={0}>
        <MDButton
          // component="a"
          // href="https://www.creative-tim.com/product/material-dashboard-pro-react"
          // target="_blank"
          // rel="noreferrer"
          onClick={() => navigate(`/agency/market/settings/purchases`)}
          variant="gradient"
          color={"info"}
          fullWidth
        >
          Manage
        </MDButton>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  brand_data: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default Sidenav;

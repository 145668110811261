import { Environment } from "../enums";

const env = {
  // API_URL : 'https://dev.walletcampaigns.com/api',
  // API_URL : 'http://35.176.217.108:8080/api'
  // http://localhost:8082
  // http://localhost:8082
  API_URL: "http://localhost:8082/api",
  GHL: {
    CLIENT_ID: "64f8604282165264339a0ccc-lm7nf7n0",
    CLIENT_SECRET: "84249575-8af0-44bf-adb0-d8cf81d6cecb",
    Location_CLIENT_ID: "64f8770dcbb4d2884275dbb1-lm7qwbtu",
    Location_CLIENT_SECRET: "fc8bf3b7-8e84-4986-80f6-c9cafcc5ccbc",
    Location_SCOPE:
      "contacts.readonly contacts.write campaigns.readonly conversations.readonly conversations.write forms.readonly conversations/message.write links.readonly links.write conversations/message.readonly locations.readonly locations/customFields.readonly locations/customFields.write locations/customValues.write locations/customValues.readonly locations/tags.write locations/tags.readonly locations/tasks.write locations/tasks.readonly opportunities.readonly opportunities.write locations/templates.readonly surveys.readonly users.write users.readonly workflows.readonly",
    SCOPE:
      "contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly snapshots.readonly locations.write oauth.write oauth.readonly medias.readonly medias.write",
    APPSCOPE:
      "contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly snapshots.readonly locations.write oauth.readonly oauth.write calendars.readonly calendars.write calendars/groups.readonly calendars/groups.write calendars/events.readonly calendars/events.write medias.readonly medias.write",
    REDIRECT: {
      LOCAITON: "http://localhost:3000/integrations/ghl/location",
      BUSINESS: "http://localhost:3000/integrations/ghl/business",
      AGENCY: "http://localhost:3002/integrations/ghl/agency",
      AGENCY_APP: "http://localhost:3002/agency/auth/appconnect",
      SUPER_ADMIN:
        "https://agency.levelupmarketplace.io/integrations/ghl/sadmin",
      Location_ASSOCIATE: "http://localhost:3002/integrations/ghl",
      SuperAdmin_ASSOCIATE:
        "https://agency.levelupmarketplace.io/superadmin/auth",
      ASSOCIATE: "https://agency.levelupmarketplace.io/agency/auth/agency",
    },
  },
};

// if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
// }

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.API_URL = "https://api.ghl.store/api";
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = "https://api.ghl.store/api";
  env.GHL.REDIRECT.AGENCY =
    "https://agency.levelupmarketplace.io/integrations/ghl/agency";
  env.GHL.REDIRECT.Location_ASSOCIATE =
    "https://agency.levelupmarketplace.io/integrations/ghl";
}

export default env;

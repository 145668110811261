import * as React from "react";
import env from "config";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDemoData } from "@mui/x-data-grid-generator";
import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Alert, Grid } from "@mui/material";
import { GridApi } from "@mui/x-data-grid-pro";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import "./category.css";

import Dragtable from "./Components/Dragtable";
import Header from "./Components/Header";
import localforage from "localforage";
function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export default function Categories() {
  const [categories, setcategories] = React.useState([]);
  const [categories_snap, setcategories_snap] = React.useState([]);
  const [categories_serv, setcategories_serv] = React.useState([]);
  const [categories_funnel, setcategories_funnel] = React.useState([]);
  const [categories_website, setcategories_website] = React.useState([]);
  const [categories_funnelapp, setcategoriesfunnelapp] = React.useState([]);
  const [categories_webhookapp, setcategories_webhookapp] = React.useState([]);

  const [custom_link, setcustom_link] = useState([]);
  const [elements, setelements] = useState([]);
  const [custom_elements, setcustom_elements] = useState([]);
  const [rows, setRows] = React.useState([]);
  const [refresh, setrefresh] = React.useState(false);
  const { data, loading: initialLoadingState } = useDemoData({
    dataSet: "Commodity",
    rowLength: 20,
    maxColumns: 20,
  });
  const [loading, setLoading] = React.useState(true);

  const BASE_URL = `${env.API_URL}/v1`;

  var axios = require("axios");

  React.useEffect(async () => {
    // alert('ljkdfh')
    const localBrand = await localforage.getItem("user");

    var custom_array = [];
    var multi_categroies = [
      {
        label: "Snapshot",
        value: "snapshot",
        type: "main",
      },
      {
        label: "Service",
        value: "service",
        type: "main",
      },
      {
        label: "Websites",
        value: "websites",
        type: "main",
      },
      {
        label: "Funnels",
        value: "funnels",
        type: "main",
      },
      {
        label: "Automation",
        value: "automation",
        type: "main",
      },
      {
        label: "Apps",
        value: "webhookapp",
        type: "main",
      },
      {
        label: "Widget",
        value: "funnelapp",
        type: "main",
      },
    ];
    const Category = await axios
      .get(BASE_URL + "/snapshot/custom_link/" + localBrand.agency_id)
      .then(async function (response) {
        response.data.data.forEach((element) => {
          custom_array.push({
            label: element.name,
            value: element._id,
          });
          multi_categroies.push({
            label: element.name,
            value: element.name,
            type: "custom",
          });
        });
        setcustom_link(custom_array);

        // console.log(multi_categroies,"custom_array")
        // console.log(custom_array,"custom_array")
        return response.data;
      })
      .catch(function (error) {
        setcustom_link([]);

        console.log(error);
      });
    axios
      .get(BASE_URL + "/snapshot/category/" + localBrand.agency_id)
      .then((response) => {
        var row_data = [];
        var snap = [];
        var service = [];
        var funnels = [];
        var automation = [];
        var websites = [];
        var funnelapp = [];
        var webhookapp = [];
        var temp_custom = [];
        var main_datas = response.data.data;
        response.data.data.forEach((element) => {
          const temp = {
            id: element._id,
            name: element.name,
            description: element.description,
            type: element.type,
            index: element.index,
            custom_index: element.custom,
            snapshot_index: element.snapshot_index,
            service_index: element.service_index,
            automation_index: element.automation_index,
            funnel_index: element.funnel_index,
            website_index: element.website_index,
            funnelapp_index: element.website_index,
            webhookapp_index: element.website_index,
          };
          row_data.push(temp);

          // if(element.type=="snapshot"|| element.type=="both"){
          //   snap.push(temp)
          // }
          temp.type.forEach((element1) => {
            if (element1.value == "snapshot") {
              snap.push(temp);
            }

            if (element1.value == "service") {
              service.push(temp);
            }

            if (element1.value == "funnels") {
              funnels.push(temp);
            }

            if (element1.value == "websites") {
              websites.push(temp);
            }
            if (element1.value == "automation") {
              automation.push(temp);
            }
            if (element1.value == "webhookapp") {
              webhookapp.push(temp);
            }
            if (element1.value == "funnelapp") {
              funnelapp.push(temp);
            }
          });
        });
        var custom = [];

        custom_array.forEach((el) => {
          var temp = [];
          row_data.forEach((element) => {
            element.type.forEach((element1) => {
              if (element1.value == el.label) {
                temp.push(element);
              }
            });
          });
          custom[el.label] = temp;
        });

        row_data.sort((a, b) =>
          a.index > b.index ? 1 : b.index > a.index ? -1 : 0
        );
        snap.sort((a, b) =>
          a.snapshot_index > b.snapshot_index
            ? 1
            : b.snapshot_index > a.snapshot_index
            ? -1
            : 0
        );
        service.sort((a, b) =>
          a.service_index > b.service_index
            ? 1
            : b.service_index > a.service_index
            ? -1
            : 0
        );
        funnels.sort((a, b) =>
          a.funnel_index > b.funnel_index
            ? 1
            : b.funnel_index > a.funnel_index
            ? -1
            : 0
        );
        websites.sort((a, b) =>
          a.website_index > b.website_index
            ? 1
            : b.website_index > a.website_index
            ? -1
            : 0
        );
        automation.sort((a, b) =>
          a.automation_index > b.automation_index
            ? 1
            : b.automation_index > a.automation_index
            ? -1
            : 0
        );

        setcategories(row_data);
        setcategories_snap(snap);
        setcategories_serv(service);
        setcategories_funnel(funnels);
        setcategories_website(websites);
        setLoading(false);
        var temp_customelements = [];
        var temp = [
          <Dragtable
            mt={2}
            heading="Everything Store"
            rows={row_data}
            loading={false}
            handleChange={handleRefresh}
            type="both"
            custom_link={multi_categroies}
          />,
          <Dragtable
            mt={2}
            heading="Snapshot Store"
            rows={snap}
            loading={false}
            handleChange={handleRefresh}
            type="snapshot"
            custom_link={multi_categroies}
          />,
          <Dragtable
            mt={2}
            heading="Service Store"
            rows={service}
            loading={false}
            handleChange={handleRefresh}
            type="service"
            custom_link={multi_categroies}
          />,
          <Dragtable
            mt={2}
            heading="Funnel Store"
            rows={funnels}
            loading={false}
            handleChange={handleRefresh}
            type="funnels"
            custom_link={multi_categroies}
          />,
          <Dragtable
            mt={2}
            heading="Website Store"
            rows={websites}
            loading={false}
            handleChange={handleRefresh}
            type="websites"
            custom_link={multi_categroies}
          />,
          <Dragtable
            mt={2}
            heading="Automation Store"
            rows={automation}
            loading={false}
            handleChange={handleRefresh}
            type="automation"
            custom_link={multi_categroies}
          />,
          <Dragtable
            mt={2}
            heading="Apps Store"
            rows={webhookapp}
            loading={false}
            handleChange={handleRefresh}
            type="widget"
            custom_link={multi_categroies}
          />,
          <Dragtable
            mt={2}
            heading="Widget Store"
            rows={funnelapp}
            loading={false}
            handleChange={handleRefresh}
            type="funnelapp"
            custom_link={multi_categroies}
          />,
        ];
        custom_array.forEach((element) => {
          console.log(element, "custom_element");
          var data = custom[element.label].sort((a, b) =>
            a.custom_index[element.label] > b.custom_index[element.label]
              ? 1
              : b.custom_index[element.label] > a.custom_index[element.label]
              ? -1
              : 0
          );
          console.log(data, "custom_data");

          temp_customelements.push(
            <Dragtable
              mt={2}
              heading={element.label + " store"}
              rows={data}
              loading={false}
              handleChange={handleRefresh}
              type={"custom"}
              name={element.label}
              custom_link={multi_categroies}
              edit_data={element}
            />
          );
        });
        setelements(temp);
        setcustom_elements(temp_customelements);
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, [refresh]);
  const handleRefresh = () => {
    // if (subscription?._id)
    setrefresh(!refresh);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        type="category"
        handleChange={handleRefresh}
        custom_elements={custom_elements.length}
      />
      <Header
        mt={5}
        elements={elements}
        custom_elements={custom_elements}
        custom_link={custom_link}
      />
    </DashboardLayout>
  );
}

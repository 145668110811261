import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Multiselect from "./inputs/Multiselect";
import Payable from "./Payable";
import localforage from "localforage";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Singleselect from "./inputs/Singleselect";
import env from "config";
import SubscriptionPayable from "./subscriptionpayabale";
import TagsInput from "components/TagsInput";
import { useAgencyInfo } from "context/agency";
const Offer_Edit = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [agency] = useAgencyInfo();
  const [processingpayable, setprocessingpayable] = useState(props.data.name);
  const [stripeProducts_data, setstripeProducts_data] = useState([]);
  const [Catgories_data, setCatgories_data] = useState([]);
  const BASE_URL = `${env.API_URL}/v1`;
  var stripeProducts = props.data?.stripeProducts?.filter(function (item) {
    return item.type != "recurring";
  });
  var striperecurringprod = props.data?.stripeProducts?.filter(function (item) {
    return item.type == "recurring";
  });
  const nameRef = useRef();
  const iframe_urlRef = useRef();
  const docRef = useRef();
  const descriptionRef = useRef();
  var image_file = "";
  const handleimage = async (e) => {
    image_file = e.target.files[0];
  };
  const handlemodal = () => {
    setTimeout(() => {
      console.log(props.item);
      setOpenAddProduct(true);
      nameRef.current.value = props.item.name;
      iframe_urlRef.current.value = props.item.iframe_url;
      docRef.current.value = props.item.doc;
      descriptionRef.current.value = props.item.description;
    }, 100);
  };
  useEffect(async () => {}, []);
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (
        e.target.iframe_url.value.substr(0, 5) == "https" &&
        e.target.doc.value.substr(0, 5) == "https"
      ) {
        var img_data = "";
        var axios = require("axios");
        if (image_file != "") {
          const form_data = new FormData();
          form_data.append("image", image_file, image_file.name);
          console.log(form_data.get("image"));

          img_data = await axios
            .post(BASE_URL + "/utils/upload/image", form_data)
            .then((response) => {
              // console.log(response.data,'image_data');
              // setOpenAddProduct(false)

              return response.data.data;
            });
        } else {
          img_data = props.item.image_url;
        }

        setProcessing(true);

        const name = e.target.name.value;
        const setup_payable = e.target.setup_payable.value;
        const management_payable = e.target.management_payable.value;
        const setup_fee = e.target.setup_fee.value;
        const management_sub = e.target.management_sub.value;
        const category = e.target.category.value.split(",");
        // const apps = e.target.apps.value.split(",");
        // var snapshot = [];
        // var snapshot_string = [];
        // if (agency.type != "free") {
        //   var snapshot = JSON.parse(e.target.snapshot.value.split(","));
        // } else {
        //   var snapshot_string = e.target.snapshot_string.value.split(",");
        // }
        const iframe_url = e.target.iframe_url.value;
        const doc = e.target.doc.value;
        const workflows = e.target.workflows.value;
        const description = e.target.description.value;
        const image = img_data;

        const data = {
          name: name,
          setup_payable: setup_payable,
          management_payable: management_payable,
          setup_fee: setup_fee,
          management_sub: management_sub,
          category: JSON.parse(category),
          apps: props.item.apps,
          // snapshot: props.item.snapshot,
          // snapshot_string: snapshot_string,
          iframe_url: iframe_url,
          workflows: workflows,
          image_url: image,
          description: description,
          doc: doc,
          agency_id: props.item.agency_id,
          type: props.item.type,
        };
        console.log(data, "data");
        axios
          .post(
            BASE_URL + "/snapshot/create_snapshot/update/" + props.item._id,
            { data }
          )
          .then(async function (response) {
            console.log(response.data);
            props.handleChange(true);
            props.handleRefresh(true);
            props.handlemessag("snapshot edit successfully", "success");
          })
          .catch(function (error) {
            console.log(error);
            props.handlemessag("snapshot edit successfully", "false");
          });

        setOpenAddProduct(false);
      } else {
        alert("please check your url make sure it will be start with https");
      }

      // const { response } = await AppService.stripe.addSubscription({ payload })

      //   setProcessing(false)
      //   onClose(response?.data)
    };
    return (
      <MDModal
        open={open}
        onClose={onClose}
        width={600}
        height={"auto"}
        overflow={"auto"}
      >
        <MDBox>
          <MDTypography component="h1" mb={2} variant="h5">
            Bundel
          </MDTypography>

          <MDBox
            component="form"
            role="form"
            sx={{ width: 535, height: "100vh-100px" }}
            onSubmit={handleSubmit}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <input
                  type="text"
                  placeholder="name"
                  name="name"
                  defaultValue={props.item.name}
                  className="form-control"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Add your snapshot description.
                </MDTypography>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={1}
                  className="form-control"
                  defaultValue={props.item.description}
                  name="description"
                  placeholder="description"
                  style={{ width: "100%", paddingLeft: "13px" }}
                />
              </MDBox>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <MDBox py={2}>
                  <input
                    type="file"
                    name="image"
                    className="form-control h-1"
                    onChange={handleimage}
                  />

                  <p className="img_text">
                    Upload a 640 x 360 pixel PNG, GIF, or JPG.
                  </p>
                </MDBox>
              </Grid>
              <Grid item md={4} className="img_adj">
                <img
                  src="https://via.placeholder.com/100"
                  width={80}
                  height={80}
                  alt=""
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <MDTypography
                mb={1}
                mt={0}
                pt={0}
                variant="button"
                display="block"
                gutterBottom
              >
                What categories does your snapshot belong to?
              </MDTypography>
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                {
                  <Multiselect
                    data={props.data.Categories}
                    edit_data={props.item.category}
                    name="category"
                  />
                }
              </MDBox>
            </Grid>
            {/* <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                <span sx={{ display: "none" }}>
                  What apps does your snapshot belong to?
                </span>
              </MDTypography>
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <Multiselect
                  data={props.Purchasedapps}
                  edit_data={props.item.apps}
                  name="apps"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                what snaphots are included
              </MDTypography>
              {agency.type != "free" ? (
                <MDBox mb={1} sx={{ fontSize: "14px" }}>
                  {
                    <Multiselect
                      data={props.data.snapshots}
                      edit_data={props.item.snapshot}
                      name="snapshot"
                    />
                  }
                </MDBox>
              ) : (
                <MDBox mb={1} sx={{ fontSize: "14px" }}>
                  <TagsInput
                    name="snapshot_string"
                    edit_value={props.item.snapshot_string}
                  />
                </MDBox>
              )}
            </Grid> */}
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                What is the url that has information about your snapshot?
              </MDTypography>
              <MDBox mb={1}>
                <input
                  type="text"
                  placeholder="https://www.example.com/"
                  name="iframe_url"
                  defaultValue={props.item.iframe_url}
                  className="form-control"
                />
              </MDBox>
            </Grid>

            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                What is the URL with documentation about this snapshot?
              </MDTypography>
              <MDBox mb={1}>
                <input
                  type="text"
                  placeholder="https://www.example.com/"
                  name="doc"
                  defaultValue={props.item.doc}
                  className="form-control"
                />
              </MDBox>
            </Grid>
            <Payable
              data={stripeProducts}
              payable={props.item.setup_payable}
              payablename="setup_payable"
              label="is there a setup fee"
              edit_data={props.item.setup_fee}
              name={"setup_fee"}
            />
            <SubscriptionPayable
              data={striperecurringprod}
              payable={props.item.management_payable}
              payablename="management_payable"
              edit_data={props.item.management_sub}
              label="is there management subscriptions"
              name={"management_sub"}
            />

            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                When a customer purchases the snapshot what workflow do you want
                to trigger?
              </MDTypography>
              <MDBox mb={1}>
                {
                  <Singleselect
                    data={props.data.workflows}
                    type="snapshot"
                    edit_data={props.item.workflows}
                    name="workflows"
                    label="workflows"
                  />
                }
              </MDBox>
            </Grid>
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 0, mb: 1 }}
                fullWidth
              >
                Edit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="outlined"
        color="info"
        type="submit"
        onClick={handlemodal}
      >
        Edit
      </MDButton>

      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Offer_Edit;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import rank1 from "assets/ranks/rank1.jpeg";
import rank2 from "assets/ranks/rank2.jpeg";
import rank3 from "assets/ranks/rank3.jpeg";
import rank4 from "assets/ranks/rank4.jpeg";
import rank5 from "assets/ranks/rank5.jpeg";
import React from "react";
import localforage from "localforage";
import { array } from "prop-types";
import env from "config";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";

const Data = () => {
  const AppService = useAppServices();
  const location_settings = useAgencyInfo()[4];
  const [agency] = useAgencyInfo();
  const [users, setusers] = React.useState([]);
  var axios = require("axios");
  const BASE_URL = `${env.API_URL}/v1`;
  const getCustomers = async (e) => {
    const { response } = await AppService.user.getuserpurchases({
      query: `id=${agency._id}`,
    });
    if (response) {
      var temp_users = response.data.sort((a, b) =>
        a.amount_spend < b.amount_spend
          ? 1
          : b.amount_spend < a.amount_spend
          ? -1
          : 0
      );
      // var user_data = [];
      // for (
      //   let index = 0;
      //   index < temp_users.length && temp_users.length < 6;
      //   index++
      // ) {
      //   const element = temp_users[index];
      //   var amount_spend = "";
      //   if (element?.amount_spend == null) {
      //     amount_spend = 0;
      //   } else {
      //     amount_spend = element.amount_spend;
      //   }
      //   var temp = {
      //     username: element.username,
      //     amount_spend: "$" + amount_spend,
      //     products: element.products,
      //     purchased_locatons: element.purchased_locatons,
      //   };
      //   user_data.push(temp);
      // }
      setusers(temp_users);
    } else {
    }
  };
  // var axios = require('axios');
  const onLoad = () => {
    getCustomers();
  };
  React.useEffect(async () => {
    onLoad();
  }, []);
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "no", accessor: "no", width: "15%", align: "left" },
      { Header: "name", accessor: "name", width: "25%", align: "left" },
      {
        Header: "PURCHASED PRODUCTS",
        accessor: "Amount_purchases",
        width: "25%",
        align: "left",
      },
      {
        Header: "amount spent",
        accessor: "amount_spent",
        width: "10%",
        align: "left",
      },
      {
        Header: "number of locations",
        accessor: "see_locations",
        align: "center",
      },
    ],

    rows: [
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank1}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[0]?.username}
          </MDTypography>
        ),
        Amount_purchases: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[0]?.products}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {location_settings.currency_sign + users[0]?.amount_spend}
          </MDTypography>
        ),
        see_locations: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[0]?.purchased_locatons}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank2}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[1]?.username}
          </MDTypography>
        ),
        Amount_purchases: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[1]?.products}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[1]?.amount_spend}
          </MDTypography>
        ),
        see_locations: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[1]?.purchased_locatons}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank3}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[2]?.username}
          </MDTypography>
        ),
        Amount_purchases: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[2]?.products}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[2]?.amount_spend}
          </MDTypography>
        ),
        see_locations: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[2]?.purchased_locatons}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank4}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[3]?.username}
          </MDTypography>
        ),
        Amount_purchases: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[3]?.products}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[3]?.amount_spend}
          </MDTypography>
        ),
        see_locations: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[3]?.purchased_locatons}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank5}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[4]?.username}
          </MDTypography>
        ),
        Amount_purchases: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[4]?.products}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[4]?.amount_spend}
          </MDTypography>
        ),
        see_locations: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {users[4]?.purchased_locatons}
          </MDTypography>
        ),
      },
    ],
  };
};
export default Data;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { loadStripe } from '@stripe/stripe-js'
import BasicModal from '../BasicModal'
import React, { useEffect,useState} from 'react'
import localforage from 'localforage'

function DefaultInfoCard({ color, icon, title, description, value,price_id,handlemessage,disabled}) {
  const [stripe_key, setstripe_key] = useState('')
  const [email, setemail] = useState('')

  const stripePromise = loadStripe(stripe_key)

  useEffect(async() => {
  var axios = require('axios');
// handlemessage('done')
// alert(title)
    const localBrand = await localforage.getItem('user');
    setemail(localBrand.email)
  //   console.log(localBrand.stripe.customer_id)
  //  setcustomer_id(localBrand.stripe.customer_id)
     axios.get('https://api.snapshopstore.com/api/v1/snapshot/superadmin_settings/filter').then(async function (response) {
       setstripe_key(response.data.data.strpe_public_key)
       console.log(response.data.data.strpe_public_key,'strpe_public_key');
        
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  return (
    <Card sx={{}}>
      <MDBox p={2} mx={0} display="flex" justifyContent="center">
      <Elements stripe={stripePromise}>
      <BasicModal color={color} icon={icon} price_id={price_id} handlemessage={handlemessage} title={'Monthly'} description={`You are currently being charged 0 each month to use the snapshot store. But you are being charged 10% of your purchases.

Upgrade to Premium to stop paying 10% commission and to get better access to other features.

Get 2 months for free when paying annually.`} value={value} disabled={disabled} url={"https://snapshotstore.io/upgrade?contact_email="+email} label="Upgrade"/>
    </Elements>
      
      
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <MDTypography  mb={1} variant="body1" sx={{fontSize:'16px'}}>
        You are currently being charged $0 a month to use the snapshot store and charged 10% commission of all purchases.
          </MDTypography>
          <MDTypography   variant="body1" sx={{fontSize:'16px'}}>
          Upgrade to premium to stop the 10% commission payments and to gain access to additional features.
          </MDTypography>
          <MDTypography   variant="body1" sx={{fontSize:'16px'}} pt={1}>
        <b>  Get 2 months free with annual payments.</b>
          </MDTypography>
        
      </MDBox>
    </Card>
  )
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color       : 'info',
  value       : '',
  description : '',
}

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color       : PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  icon        : PropTypes.node.isRequired,
  title       : PropTypes.string.isRequired,
  description : PropTypes.string,
  price_id : PropTypes.string,
  disabled : PropTypes.string,
  handlemessage:PropTypes.func,
  value       : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default DefaultInfoCard

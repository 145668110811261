import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import env from "config";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Alert, Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Loader from "examples/Loader";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../Users/style.css";

import { useEffect, useState } from "react";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import Data from "examples/Navbars/DashboardNavbar/data";
function Snapshot_details() {
  const [agency] = useAgencyInfo();
  const { data, loader, error } = Data();
  const Service = useAppServices();
  const [Conjo, setConjo] = useState([]);
  const [snapshot, setsnapshot] = useState([]);
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const [processing, setProcessing] = useState(true);
  const [refresh, setrefresh] = useState(true);
  const location_settings = useAgencyInfo()[4];
  const epoch = moment.unix(1581292800);

  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Price",
      options: {
        filter: false,
      },
    },
    {
      name: "Categories",
      options: {
        filter: false,
      },
    },
    {
      name: "No of Purchased Products",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount Spent",
      options: {
        filter: true,
        filterType: "custom",

        filterOptions: {
          names: [],
          logic(date, filters) {
            if (filters[0] && filters[1]) {
              return date < filters[0] || date > filters[1];
            } else if (filters[0]) {
              return date < filters[0];
            } else if (filters[1]) {
              return date > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date</FormLabel>
              <FormGroup row>
                <TextField
                  label="start"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="end"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dateFilterChecked}
                      onChange={(event) =>
                        setDateFilterChecked(event.target.checked)
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },

        print: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getSnapshots = async () => {
    const { response } = await Service.snapshots.getsnapshots({
      query: `id=${agency._id}`,
    });
    console.log(response, "getSnapshots");
    if (response) {
      var tabel_data = [];
      response?.s_pur.forEach((element) => {
        var price = "";
        const temp = [
          element.name,
          location_settings.currency_sign + parseFloat(element.price),
          <Stack spacing={1} alignItems="left">
            <Stack direction="row" spacing={1}>
              {element.category.map((category, key) => (
                <Chip label={category.label} size="small" color="primary" />
              ))}
            </Stack>
          </Stack>,
          element.no_of_pro,
          location_settings.currency_sign + element.amount_spend,
        ];
        tabel_data.push(temp);
      });
      setConjo(tabel_data);
      setsnapshot(response?.s_pur);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  const onLoad = () => {
    getSnapshots();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <DashboardLayout>
          {error != "" ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="warning">{error}</Alert>
            </Stack>
          ) : (
            <>
              <DashboardNavbar
                type="snapshot_page"
                handleChange={onLoad}
                data={data}
              />
              <Grid container>
                {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}

                <Grid item xs={12}>
                  <MUIDataTable
                    title={"Snapshot"}
                    data={Conjo}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DashboardLayout>
      )}
    </>
  );
}

export default Snapshot_details;

import EditIcon from '@mui/icons-material/Edit';
import MDButton from 'components/MDButton'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import { useRef } from 'react';
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Multiselect from './inputs/Multiselect';
import env from 'config';

import localforage from 'localforage';
function AddProduct({ open, onClose ,props }) {
  
  const BASE_URL = `${env.API_URL}/v1`
  
  const [processing, setProcessing] = useState(false)
  const [name, setname] = useState(false)
  const inputRef = useRef();
  useEffect(() => {
    setname(props.data.name)
    console.log(props,'propsssssssssssss')
  }, [inputRef,])
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const localBrand = await localforage.getItem('user');
    
  var axios = require('axios')
 const name=e.target.name.value;
          var data='';
var type=JSON.parse(e.target.type.value);
          
          if(props.type=="both"){
            var index={};
            props.multiselect.forEach(element => {
              if(element.type=="custom"){
                index[element.label]=1;
              }
            });
             data={
              name:name,
              agency_id:localBrand.agency_id,
              custom:index,
              type:type
            }
            console.log('data')
          }else if(props.type=="custom"){
            var index={};
            index[name]=1;
            data={
              name:name,
              agency_id:localBrand.agency_id,
              custom:index,
              type:[
                {
                    "label": props.name,
                    "value": props.name
                } 
            ]
            
            }
          }else{
var index={};
type.forEach(element => {
  if(element.type=="custom"){
    
index[element.label]=1;
  }
});
             data={
              name:name,
              agency_id:localBrand.agency_id,
              custom:index,
              type:type
            
            }
          }
    setProcessing(true)
    
    // const data={
    //   name:e.target.name.value,
    //   agency_id:props.data.agency_id,
    //   type:JSON.parse(e.target.type.value)

    // }
    console.log(data,'lksjdfkljlkfdsk')
    axios.post(BASE_URL+'/snapshot/category/update/'+props.data.id,{data}).then(async function (response) {
      console.log(response.data);
  props.handleChange(true)

    })
    .catch(function (error) {
      console.log(error);
    });
    onClose()
  // setOpenAddProduct(false)
  // const { response } = await AppService.stripe.addSubscription({ payload })
    
    // setProcessing(false)
    // onClose(response?.data)
  }
  return (
    <MDModal open={open} onClose={onClose}>
    <MDBox>
    <MDTypography component="h1" mb={3} variant="h5">
    Snapshot Category
        </MDTypography>

        <MDBox component="form" onSubmit={handleSubmit} role="form" sx={{ width: 335 }}>
        <Grid item md={12}>
        <MDBox mb={1}>
          <input
            type="name"
            label="Name"
            name="name"
            value={name}
            onChange={(e)=>setname(e.target.value)}
            className="form-control"
          />
          </MDBox>
          <MDBox mb={1} mt={2} sx={{ fontSize: '14px' }}>
          <Multiselect edit_data={props.data.type} data={props.multiselect} name="type"/>
        </MDBox>

      </Grid>

          <MDBox>
          
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 4, mb: 1 }}
              fullWidth
            >
              Add
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDModal>
  )
}
const CategoryEdit = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [name, setname] = useState(props.data.name)
 
  

  const handlemodal = (e) => {
    setOpenAddProduct(props.data.id)
    console.log(e)
  }
  useEffect(async() => {
    console.log(props.data,'main_data')
    console.log(props.type)
    console.log(props.multiselect)
    
    // inputRef.current.value = props.data.name
  }, [])
      const closeAddProduct = (subscription) => {
        // if (subscription?._id) 
        setOpenAddProduct(false)
        
      }
  return (
    <>
               <EditIcon sx={{ marginRight: "15px", cursor: "pointer" }}   onClick={handlemodal} />
                {
                  props.data.id==openAddProduct?(<AddProduct open={openAddProduct} onClose={closeAddProduct} props={props}/>):(<></>)
                }
                

    </>
  )
}

export default CategoryEdit
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { Autocomplete, CircularProgress } from '@mui/material'
import MDInput from 'components/MDInput'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import DataTable from 'examples/Tables/DataTable'
import categoryTableData from './data/categoryTableData'
import AddBoxIcon from '@mui/icons-material/AddBox'
import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import { useFormik } from 'formik'

function Customers() {
  const { columns, rows, loader } = categoryTableData()
  const [openAddProduct, setOpenAddProduct] = useState(false)

  function AddProduct({ open, onClose }) {
    
    const initState = {
      name: '',
    }
    const [processing, setProcessing] = useState(false)
    
  
    const handleSubmit = async (form) => {
    var axios = require('axios')

      setProcessing(true)
      
      const payload = {
        ...form,
      }
      const data={
        ...form,

      }
      // console.log(...form.name)
      // axios.post('http://localhost:8082/api/v1/snapshot/category/submit', { data }) .then(async function (response) {
      //   console.log(response.data);
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
      // const { response } = await AppService.stripe.addSubscription({ payload })
      
      // setProcessing(false)
      // onClose(response?.data)
    }
    const handleSelectChange = (e, { value }, name) => {
      formik.setValues({ ...formik.values, [name]: value })
    }
    
    const formik = useFormik({
      initialValues: { ...initState },
      onSubmit: handleSubmit,
    })
    return (
      <MDModal open={open} onClose={onClose}>
      <MDBox>
      <MDTypography component="h1" mb={3} variant="h5">
      Snapshot Category
          </MDTypography>
  
          <MDBox component="form" onSubmit={formik.handleSubmit} role="form" sx={{ width: 335 }}>
          <Grid item md={12}>
          <MDBox mb={1}>
            <MDInput
              type="name"
              label="Name"
              name="name"
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
              helperText={
                formik.touched.name && formik.errors.name ? formik.errors.name : ''
              }
              success={formik.touched.name && !formik.errors.name}
              fullWidth
            />
          </MDBox>
        </Grid>
  
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  
  const closeAddProduct = (subscription) => {
    if (subscription?._id) 
    // updateTable(subscription)
    setOpenAddProduct(false)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center  "
              >
                <MDTypography variant="h6" color="white">
                  Snapshot Categories
                </MDTypography>
                <MDButton
                  sx={{ marginLeft: 'auto' }}
                  variant="outlined"
                  startIcon={<AddBoxIcon />}
                  onClick={() => setOpenAddProduct(true)}
                >
                  Add Category
                </MDButton>
                <AddProduct open={openAddProduct} onClose={closeAddProduct} />
              </MDBox>
              
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  loader={loader}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Customers

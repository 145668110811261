import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";

const Payable = (props) => {
  const [processpayable, setprocesspayable] = useState(
    props.payable == "no" ? false : true || false
  );
  const [generalservice, setgeneralservice] = useState(
    props.service_type == "general" ? true : false
  );
  console.log(props, "kjdhprops");
  return (
    <>
      <Grid item md={12}>
        <MDBox mb={1}>
          <FormControl>
            {props.selling_type != "reselling_service" ? (
              <>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <MDTypography
                    mb={1}
                    variant="button"
                    display="block"
                    gutterBottom
                  >
                    Which type of Service This is?
                  </MDTypography>
                </FormLabel>
                <RadioGroup
                  disabled={props.selling_type}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="service_type"
                  sx={{ marginTop: "-6px" }}
                >
                  {generalservice == false ? (
                    <>
                      <FormControlLabel
                        value="general"
                        onClick={() => setgeneralservice(true)}
                        control={<Radio />}
                        label="General"
                      />

                      <FormControlLabel
                        value="va"
                        onClick={() => setgeneralservice(false)}
                        control={<Radio />}
                        checked
                        label="VA"
                      />
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        checked
                        value="general"
                        onClick={() => setgeneralservice(true)}
                        control={<Radio />}
                        label="General"
                      />
                      <FormControlLabel
                        value="no"
                        onClick={() => setgeneralservice(false)}
                        control={<Radio />}
                        label="VA"
                      />
                    </>
                  )}
                </RadioGroup>
              </>
            ) : (
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                The Type Of Service IS{" "}
                <b style={{ textTransform: "uppercase" }}>
                  {props.service_type}
                </b>
                <input
                  type="hidden"
                  name="service_type"
                  value={props.service_type}
                />
              </MDTypography>
            )}
          </FormControl>
        </MDBox>
      </Grid>
      {generalservice == true ? (
        <>
          <Grid item md={12}>
            <MDBox mb={1}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <MDTypography
                    variant="h6"
                    sx={{ fontSize: "17px", fontWeight: "400" }}
                    display="block"
                    gutterBottom
                  >
                    Is the free?
                  </MDTypography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name={props.payablename}
                  sx={{ marginTop: "-6px" }}
                >
                  {processpayable == false ? (
                    <>
                      <FormControlLabel
                        value="yes"
                        onClick={() => setprocesspayable(true)}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="no"
                        onClick={() => setprocesspayable(false)}
                        control={<Radio />}
                        checked
                        label="Yes"
                      />
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        value="yes"
                        checked
                        onClick={() => setprocesspayable(true)}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="no"
                        onClick={() => setprocesspayable(false)}
                        control={<Radio />}
                        label="Yes"
                      />
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </MDBox>
          </Grid>
          {processpayable == true ? (
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                How much is for this ?
              </MDTypography>
              <MDBox mb={1}>
                <input
                  defaultValue={props.edit_data}
                  type="number"
                  name={props.name}
                  className="form-control"
                  step="0.01"
                  style={{
                    width: "97%",
                    height: "40px",
                  }}
                />
              </MDBox>
            </Grid>
          ) : (
            <input type="hidden" name={props.name} />
          )}
        </>
      ) : (
        <>
          <Grid item md={12}>
            <MDTypography mb={1} variant="button" display="block" gutterBottom>
              How Much it Costs The Customer Per Hour?
            </MDTypography>
            <MDBox mb={1}>
              <input
                defaultValue={props.edit_data}
                type="number"
                name={props.name}
                className="form-control"
                step="0.01"
                style={{
                  width: "97%",
                  height: "40px",
                }}
              />
              <input type="hidden" name={props.payablename} value={"yes"} />
            </MDBox>
          </Grid>
        </>
      )}
    </>
  );
};

export default Payable;

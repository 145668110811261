/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useAgencyInfo } from "context/agency";

function OrderSummary({ data }) {
  const location_settings = useAgencyInfo()[4];
  console.log(data, "type");
  var profit = 0;
  if (data.config_app_type == "basic") {
    profit =
      parseFloat(data.price) -
      parseFloat(
        data.app.basic.value.substr(0, data.app.basic.value.indexOf(" "))
      );
  } else {
    profit = parseFloat(data.price);
  }
  return (
    <>
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Summary
        </MDTypography>
      </MDBox>
      {data.purchaseapp_data?.management_sub_data ? (
        <MDBox display="flex" justifyContent="space-between" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Management Subscription:
            <br />
            <span style={{ fontSize: "13px" }}>
              ({data.purchaseapp_data?.management_sub_data?.sub_id})
            </span>
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              {location_settings.currency_sign + " "}
              {data.purchaseapp_data?.management_sub_data?.latest_invoice
                ?.total / 100}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <></>
      )}
      {data.purchaseapp_data?.cost_management_sub_data ? (
        <MDBox display="flex" justifyContent="space-between" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Cost Management Subscription:
            <br />
            <span style={{ fontSize: "13px" }}>
              ({data.purchaseapp_data?.cost_management_sub_data?.sub_id})
            </span>
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              {location_settings.currency_sign + " "}
              {data.purchaseapp_data?.cost_management_sub_data?.latest_invoice
                ?.total / 100}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <></>
      )}
      {data.purchaseapp_data?.setup_charge ? (
        <MDBox display="flex" justifyContent="space-between" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Setup Charge:
            <br />
            <span style={{ fontSize: "13px" }}>
              ({data.purchaseapp_data?.setup_charge?.id})
            </span>
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              {location_settings.currency_sign + " "}
              {data.purchaseapp_data?.setup_charge?.amount / 100}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <></>
      )}
      {data.purchaseapp_data?.cost_setup_charge ? (
        <MDBox display="flex" justifyContent="space-between" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Cost Setup Charge:
            <br />
            <span style={{ fontSize: "13px" }}>
              ({data.purchaseapp_data?.cost_setup_charge?.id})
            </span>
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              {location_settings.currency_sign + " "}
              {data.purchaseapp_data?.cost_setup_charge?.amount / 100}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <></>
      )}
      {/* <MDBox display="flex" justifyContent="space-between" mt={2}>
      <MDTypography variant="button" fontWeight="regular" color="text">
        Profit:
      </MDTypography>
      <MDBox ml={1}>
        <MDTypography variant="body2" fontWeight="medium">
          {location_settings.currency_sign + profit.toFixed(2)}
        </MDTypography>
      </MDBox>
    </MDBox> */}
    </>
  );
}

export default OrderSummary;

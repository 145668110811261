/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import localforage from "localforage";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
import { useBrandInfo } from "context/brand";
import DeleteIcon from "@mui/icons-material/Delete";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "./DefaultProjectCard";
import homeDecor1 from "assets/images/home-decor-1.jpg";
import React, { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import Category_Edit from "layouts/agency/components/Category_Modal/Edit";
import { Edit } from "@mui/icons-material";
import env from "config";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import { useParams } from "react-router-dom";
import Loader from "examples/Loader";
function ComplexStatisticsCard({
  color,
  title,
  count,
  percentage,
  icon,
  stripeData,
  configapps,
  Purchasedapps,
  formCategory,
  data,
  category_data,
  handleChange,
  handleCategoryChange,
  refresh,
  handlemessag,
}) {
  const [agency] = useAgencyInfo();
  const params = useParams();
  const [StoreData, setStoreData] = useState([]);
  const [purchases, setpurchases] = useState([]);
  const [workflows, setworkflows] = useState([]);
  const [stripeProducts, setstripeProducts] = useState([]);
  const [processing, setprocessing] = useState(true);
  const AppService = useAppServices();
  const [custom_link, setcustom_link] = useState([]);
  const location_settings = useAgencyInfo()[4];

  const BASE_URL = `${env.API_URL}/v1`;
  const MakePrice = (item) => {
    console.log(item, "MakePrice");
    var totalprice = 0;
    if (item.setup_payable == "yes") {
      totalprice += parseFloat(item.setup_fee);
    }
    if (item.management_payable == "yes") {
      totalprice += parseFloat(
        item.management_sub.substr(0, item.management_sub.indexOf("("))
      );
    }
    for (let index = 0; index < item.apps.length; index++) {
      const element = item.apps[index];
      var newArray = configapps.filter(function (e) {
        return (
          e.app_id ===
          element.value.substring(
            element.value.indexOf("(") + 1,
            element.value.indexOf(")", element.value.indexOf("("))
          )
        );
      });
      console.log(newArray);
      if (newArray.length) {
        var temp_price = newArray[0].selling_price?.value.substr(
          0,
          newArray[0].selling_price?.value.indexOf(" ")
        );
        totalprice += parseFloat(temp_price);
      } else {
        totalprice +=
          parseFloat(element.value.substr(0, element.value.indexOf(" "))) * 3;
      }
    }
    return totalprice;
  };
  const handledelete = (id) => () => {
    var axios = require("axios");

    axios
      .post(BASE_URL + "/snapshot/category/delete/" + id)
      .then(async function (response) {
        console.log(response.data);
        handleChange(true);
        handlemessag("category delete successfully", "success");
        refresh();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSnapshots = async () => {
    var axios = require("axios");
    const localBrand = await localforage.getItem("user");
    // alert(localBrand.agency_id)
    // const { response } = await AppService.bundles.stordata({
    //   query: `agency_id=${agency.agency_id}`,
    // });
    const { response } = await AppService.snapshots.filter({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "getStripe");
    if (response) {
      const filteredData = response.data.filter(
        (item) =>
          item.type === "reselling_bundle" || item.type === "reselling_service"
      );
      setStoreData(filteredData);

      // setpurchases(response.purchases)
      // setworkflows(response.workflows.workflows)
      // var stripeProducts = [];
      // var stripeProducts_data=[]
      // if (
      //   response.data?.prices != ""
      //   // response.data?.location_settings.currency != ""
      // ) {
      //   var newArray = response?.prices?.data.filter(function (item) {
      //     return (
      //       // item.currency == response.data?.location_settings.currency &&
      //       item.type == "recurring"
      //     );
      //   });
      //   stripeProducts = newArray;
      //   console.log(
      //     newArray,
      //     "stripeProductsstripeProductsstripeProductsstripeProducts"
      //   );
      // }
      // for (let index = 0; index < stripeProducts.length; index++) {
      //   const element = stripeProducts[index];
      //   var temp_prices = {};
      //   if (element.type == "recurring") {
      //     temp_prices = {
      //       label:
      //         element.unit_amount / 100 +
      //         "(" +
      //         element.recurring.interval +
      //         ")-" +
      //         "(" +
      //         element.nickname +
      //         ")-" +
      //         element.id,
      //       value:
      //         element.unit_amount / 100 +
      //         "(" +
      //         element.nickname +
      //         ")-" +
      //         element.id,
      //       type: element.type,
      //       currency: element.currency,
      //     };
      //   } else {
      //     temp_prices = {
      //       label:
      //         element.unit_amount / 100 +
      //         "(" +
      //         element.nickname +
      //         ")-" +
      //         element.id,
      //       value:
      //         element.unit_amount / 100 +
      //         "(" +
      //         element.nickname +
      //         ")-" +
      //         element.id,
      //       type: element.type,
      //       currency: element.currency,
      //     };
      //   }

      //   stripeProducts_data.push(temp_prices);
      // }
      // setstripeProducts(stripeProducts_data)
      setprocessing(false);
    } else {
      setprocessing(false);
    }
    console.log(response, "getSnapshots");
  };
  useEffect(async () => {
    await getSnapshots();
    // console.log(NewSnapshot,'NewSnapshot')
    // alert(refresh)
  }, [refresh]);
  return (
    <Card style={{ marginBottom: "50px" }}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          alignItems="center"
          width="100%"
          height="4rem"
          mt={-3}
        >
          <Grid container>
            <Grid item xs={6}>
              <MDTypography
                variant="button"
                ml={3}
                display="flex"
                justifyContent="start"
                fontWeight="bold"
                color="white"
              >
                {title}
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDTypography
                variant="h4"
                mr={5}
                display="flex"
                justifyContent="end"
                fontWeight="bold"
                color="white"
              ></MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox pb={2} mt={5} px={2}>
        {processing ? (
          <Loader />
        ) : (
          <Grid container spacing={4}>
            {StoreData.map((item, key) => (
              <Grid item xs={12} md={3}>
                <MDBox mb={3}>
                  <DefaultProjectCard
                    image={item.image_url}
                    workflows={workflows}
                    stripeData={data.stripeData}
                    stripeProducts={data.stripeProducts}
                    purchases={purchases}
                    formCategory={formCategory}
                    title={item.name}
                    handleChange={getSnapshots}
                    Purchasedapps={Purchasedapps}
                    handlemessag={handlemessag}
                    handleRefresh={handleChange}
                    item={item}
                    data={data}
                    description={
                      (
                        <>
                          <div>
                            <b>Description:</b> {item.description}
                          </div>
                          <div>
                            <b>Type:</b> {item.type}
                          </div>
                          <div>
                            <b>Price:</b> {location_settings.currency_sign}
                            {MakePrice(item)}
                          </div>
                        </>
                      ) ||
                      "As Uber works through a huge amount of internal management turmoil."
                    }
                    action={{
                      type: "internal",
                      route: `/agency/snapshot/${item._id}/SnapshotDetail`,
                      color: "info",
                      label: "Edit",
                    }}
                    button_label="Buy"
                  />
                </MDBox>
              </Grid>
            ))}
          </Grid>
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    data: PropTypes.array,
    category_data: PropTypes.array,
  }),
  icon: PropTypes.node.isRequired,
  handleChange: PropTypes.func,
  handlemessag: PropTypes.func,
  handleCategoryChange: PropTypes.func,
  refresh: PropTypes.string,
};

export default ComplexStatisticsCard;

import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import env from "config";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Alert, Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Loader from "examples/Loader";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../Users/style.css";

import { useEffect, useState } from "react";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { CopyAll, Done } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";
function Calender() {
  const navigate = useNavigate();
  const [agency] = useAgencyInfo();
  const location_settings = useAgencyInfo()[4];
  const Service = useAppServices();
  const [Conjo, setConjo] = useState([]);
  const [data, setdata] = useState([]);
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const [processing, setProcessing] = useState(true);
  const [refresh, setrefresh] = useState(true);
  const [copy, setcopy] = useState(false);
  const epoch = moment.unix(1581292800);

  const handlecopy = () => {
    setcopy(true);
    setTimeout(() => {
      setcopy(false);
    }, 2000);
  };
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Location",
      options: {
        filter: false,
      },
    },
    {
      name: "Created At",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getCalendars = async () => {
    const { response } = await Service.calendars.filter({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "getCalendars");
    if (response) {
      const temp_loc = [];
      var tabel_data = [];
      response.data.forEach((element) => {
        const temp = [
          element.name,
          element.location.label,
          element.createdAt,
          <>
            <CopyToClipboard
              text={`https://agency.levelupmarketplace.io/calender/${element._id}/${agency._id}`}
              onCopy={handlecopy}
              ml={2}
            >
              <MDButton variant="contained" color="info" size="small" ml={2}>
                {copy ? "Copied" : "Copy"}
              </MDButton>
            </CopyToClipboard>
          </>,
        ];
        tabel_data.push(temp);
      });
      setConjo(tabel_data);
      for (let index = 0; index < response.locations.length; index++) {
        const element = response.locations[index];
        temp_loc.push({
          label: element.name,
          value: element.id,
        });
      }
      setdata(temp_loc);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  // "TtzMWtV2AxjW6LLcxafM"
  const onLoad = () => {
    getCalendars();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <DashboardLayout>
          <>
            <DashboardNavbar
              type="calender_page"
              handleChange={onLoad}
              data={data}
            />
            <Grid container>
              <Grid item xs={12}>
                <MUIDataTable
                  title={"Calendar"}
                  data={Conjo}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          </>
        </DashboardLayout>
      )}
    </>
  );
}

export default Calender;

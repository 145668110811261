/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

// OrderDetails page components
import Header from "./components/Header";
import OrderInfo from "./components/OrderInfo";
import TrackOrder from "./components/TrackOrder";
import PaymentDetails from "./components/PaymentDetails";
import BillingInformation from "./components/BillingInformation";
import OrderSummary from "./components/OrderSummary";
import BundleOrderSummary from "./components/BundleOrderSummary";
import MDTypography from "components/MDTypography";
import { useAgencyInfo } from "context/agency";

function OrderDetails({ data, apps_data }) {
  const location_settings = useAgencyInfo()[4];
  return (
    <MDBox>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <MDBox pt={1} pb={3}>
              <MDBox mb={3}>
                {data.type == "snapshot" || data.type == "service" ? (
                  <></>
                ) : (
                  <Header data={data} />
                )}
                <OrderInfo data={data} />
              </MDBox>
              <Divider />
              <MDBox mt={3}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={6}>
                    <PaymentDetails data={data} />
                  </Grid>
                  {data.type == "bundle" || data.type == "reselling_bundle" ? (
                    <>
                      <Grid item xs={12} mt={2}>
                        <MDBox mb={2}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Summary
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      {apps_data.map((item) => (
                        <>
                          <Grid item xs={12} sx={{ ml: "auto" }}>
                            <BundleOrderSummary data={item} />
                          </Grid>
                        </>
                      ))}
                      <Grid item xs={12}>
                        <MDBox mb={2}>
                          {data.purchaseapp_data?.management_sub_data ? (
                            <MDBox
                              display="flex"
                              justifyContent="space-between"
                              mb={0.5}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                <b>Management Subscription</b>:
                                <br />
                                <span style={{ fontSize: "13px" }}>
                                  (
                                  {
                                    data.purchaseapp_data?.management_sub_data
                                      ?.sub_id
                                  }
                                  )
                                </span>
                              </MDTypography>
                              <MDBox ml={1}>
                                <MDTypography
                                  variant="body2"
                                  fontWeight="medium"
                                >
                                  {location_settings.currency_sign + " "}
                                  {data.purchaseapp_data?.management_sub_data
                                    ?.latest_invoice?.total / 100}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          ) : (
                            <></>
                          )}
                          {data.purchaseapp_data?.cost_management_sub_data ? (
                            <MDBox
                              display="flex"
                              justifyContent="space-between"
                              mb={0.5}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                <b> Cost Management Subscription</b>:
                                <br />
                                <span style={{ fontSize: "13px" }}>
                                  (
                                  {
                                    data.purchaseapp_data
                                      ?.cost_management_sub_data?.sub_id
                                  }
                                  )
                                </span>
                              </MDTypography>
                              <MDBox ml={1}>
                                <MDTypography
                                  variant="body2"
                                  fontWeight="medium"
                                >
                                  {location_settings.currency_sign + " "}
                                  {data.purchaseapp_data
                                    ?.cost_management_sub_data?.latest_invoice
                                    ?.total / 100}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          ) : (
                            <></>
                          )}
                          {data.purchaseapp_data?.setup_charge ? (
                            <MDBox
                              display="flex"
                              justifyContent="space-between"
                              mb={0.5}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                <b>Setup Charge</b>:
                                <br />
                                <span style={{ fontSize: "13px" }}>
                                  ({data.purchaseapp_data?.setup_charge?.id})
                                </span>
                              </MDTypography>
                              <MDBox ml={1}>
                                <MDTypography
                                  variant="body2"
                                  fontWeight="medium"
                                >
                                  {location_settings.currency_sign + " "}
                                  {data.purchaseapp_data?.setup_charge?.amount /
                                    100}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          ) : (
                            <></>
                          )}
                          {data.purchaseapp_data?.cost_setup_charge ? (
                            <MDBox
                              display="flex"
                              justifyContent="space-between"
                              mb={0.5}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                <b> Cost Setup Charge</b>:
                                <br />
                                <span style={{ fontSize: "13px" }}>
                                  (
                                  {data.purchaseapp_data?.cost_setup_charge?.id}
                                  )
                                </span>
                              </MDTypography>
                              <MDBox ml={1}>
                                <MDTypography
                                  variant="body2"
                                  fontWeight="medium"
                                >
                                  {location_settings.currency_sign + " "}
                                  {data.purchaseapp_data?.cost_setup_charge
                                    ?.amount / 100}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          ) : (
                            <></>
                          )}
                        </MDBox>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={6} sx={{ ml: "auto" }}>
                      <OrderSummary data={data} />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <BillingInformation data={data} />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default OrderDetails;

import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import env from "config";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Alert, Grid, Switch } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Loader from "examples/Loader";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../Users/style.css";

import { useEffect, useState } from "react";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useParams } from "react-router-dom";
function Default() {
  const [agency] = useAgencyInfo();
  const Service = useAppServices();
  const [Conjo, setConjo] = useState([]);
  const [purchasedata, setpurchasedata] = useState([]);
  const [loader, setloader] = useState(true);
  const [newusers, setnewusers] = useState("");
  const [Users_data, setUsers_data] = useState([]);
  const location_settings = useAgencyInfo()[4];
  const params = useParams();

  const columns = [
    {
      name: "Location Name",
      options: {
        filter: false,
      },
    },
    {
      name: "User Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Phone",
      options: {
        filter: false,
      },
    },
    {
      name: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "Address",
      options: {
        filter: false,
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const handlestatus = async (e, loc) => {
    console.log(e.target.checked, loc);
    const payload = {
      location_id: loc.id,
      form_id: params.id,
    };
    const { response } = await Service.servey.Active({ payload });
    console.log(response, "handlestatus");
    if (response) {
      getLocations();
    }
  };
  const disablestatus = async (status_id) => {
    console.log(status_id);
    // const payload = {
    //   _id: status_id,
    // };
    // console.log(payload);
    const { response } = await Service.servey.disable({
      query: `_id=${status_id}`,
    });
    // console.log(response, "handlestatus");
    if (response) {
      getLocations();
    }
  };
  const getLocations = async () => {
    const { response } = await Service.servey.getstatus({
      query: `agency_id=${agency._id}&form_id=${params.id}`,
    });
    console.log(response, "responseresponse");
    if (response) {
      var tabel_data = [];
      var location_data = [];
      response?.data.forEach(async (element) => {
        location_data.push({
          label: element.name,
          value: element._id,
        });

        const label = { inputProps: { "aria-label": "Active" } };
        // setLocations(location_data)
        var newArray = response?.servey_status.filter(function (item) {
          return item.location_id == element.id;
        });
        var status = false;
        var status_id = null;
        if (newArray[0]) {
          status = true;
          status_id = newArray[0]._id;
        }
        const temp = [
          element.name,
          `${element.firstName}  ${element.lastName}`,
          element.phone,
          element.email,
          element.address,
          <>
            {status == true ? (
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked
                    color="secondary"
                    onChange={(e) => disablestatus(status_id)}
                  />
                }
              />
            ) : (
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    onChange={(e) => handlestatus(e, element)}
                  />
                }
              />
            )}
          </>,
        ];
        tabel_data.push(temp);
      });
      setConjo(tabel_data);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  const onLoad = () => {
    getLocations();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <DashboardLayout>
          <DashboardNavbar />
          <Grid container spacing={3}>
            {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}

            <Grid item xs={12}>
              <MDBox>
                <MDButton>Back</MDButton>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MUIDataTable
                title={"Locations"}
                data={Conjo}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
}

export default Default;

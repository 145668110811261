/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// Material Dashboard 2 React layouts
import TransformIcon from "@mui/icons-material/Transform";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AgencyApp from "./integration/ghl_app";
import Dashboard from "./dashboard";
import Theme from "./theme";
import ScannerIcon from "@mui/icons-material/Scanner";
import DarkModeIcon from "@mui/icons-material/DarkMode";
// import Tables from './tables'
// import Billing from './billing'
import Customlinks from "./Customlinks/Customlinks";
// import Notifications from './notifications'
import Profile from "./profile";
import Category from "./category";
import Snapshots from "./snapshots";
import Stripe from "./integration/stripe";
import Bundles from "./Bundles";
import Auth from "./integration/auth";
import AgencyAuth from "./integration/agency_auth";
import Iframs from "./snapshots/components/Iframs/Iframs";
import Categories from "./Categories/Categories";
import Locations from "./Locations/Locations";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Users from "./Users/Users";
import ProspectiveAccounts from "./ProspectiveAccounts";
import Store from "./Store/Store";
import Services from "./Services/Services";
import Purchases from "./Purchases/Purchases";
import Apps from "./Apps/Apps";
import Market from "./Market/MarketPlace/Market";
import Temp_Category from "./temp_cateory";
import Snapshot_details from "./Snapshot_details/Snapshot_details";
import Demo from "./Demo/Demo";
import AppIframe from "./Apps/components/Iframs/Iframs";
import WorkflowApps from "./WorkflowApps";
import FunnelApps from "./FunnelApps";
import AppsIcon from "@mui/icons-material/Apps";
import WidgetsIcon from "@mui/icons-material/Widgets";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import PurchasesDetails from "./Purchases/Components/purchases_details";
import Tasks from "./Tasks/Tasks";
import SchoolIcon from "@mui/icons-material/School";
import Teams from "./Teams";
import Packages from "./packages";
import Survey from "./Survey";
import LocConfigure from "./Survey/LocConfigure";

import SurveyBuilder from "./Survey/components/ServeyBuilder";
import { useAgencyInfo } from "context/agency";
import UserProspective from "./UserProspective";
import LocationProspective from "./LocationProspective";
import LayersIcon from "@mui/icons-material/Layers";
import MarketPurchases from "./Market/Purchases/MarketPurchases";
import SellerIframe from "./Market/MarketPlace/Iframs/Iframs";
import Subscriptions from "./Market/Subscriptions";
import Calender from "./Calenders/calender";
function useRoutes() {
  const [agency] = useAgencyInfo();
  var routes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Store",
      key: "Stores",
      icon: <Icon fontSize="small">store</Icon>,
      collapse: [
        {
          type: "collapse",
          name: "Preview",
          key: "store",
          icon: <Icon fontSize="small">store</Icon>,
          route: "/store",
          component: <Snapshots />,
        },
        // {
        //   type: "collapse",
        //   name: "packages",
        //   key: "packages",
        //   icon: <Icon fontSize="small">store</Icon>,
        //   route: "/packages",
        //   component: <Packages />,
        // },
        {
          type: "collapse",
          name: "Teams",
          key: "teams",
          icon: <Icon fontSize="small">Teams</Icon>,
          route: "/teams",
          component: <Teams />,
        },
        {
          type: "collapse",
          name: "Theme",
          key: "theme",
          icon: <DarkModeIcon fontSize="small" />,
          route: "/theme",
          component: <Demo />,
        },
        {
          type: "collapse",
          name: "Navigation",
          key: "categories",
          icon: <Icon fontSize="small">category</Icon>,
          route: "/categories",
          component: <Categories />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Tasks",
      key: "tasks",
      icon: <ReceiptIcon fontSize="small" />,
      route: "/tasks",
      component: <Tasks />,
    },
    {
      type: "collapse",
      name: "Purchases",
      key: "Purchase",

      icon: <ReceiptIcon fontSize="small" />,
      // route: "/purchases",
      // component: <Purchases />,

      collapse: [
        {
          type: "collapse",
          name: "All",
          key: "purchases",
          icon: <ReceiptIcon fontSize="small" />,
          route: "/purchases",
          component: <Purchases />,
        },
        {
          type: "collapse",
          name: "Users",
          key: "users",
          icon: <Icon fontSize="small">person</Icon>,
          route: "/users",
          component: <Users />,
        },
        {
          type: "collapse",
          name: "Locations",
          key: "locations",
          icon: <LocationOnIcon fontSize="small" />,
          route: "/locations",
          component: <Locations />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Listings",
      key: "listings",
      icon: <Icon fontSize="small">store</Icon>,
      collapse: [
        {
          type: "collapse",
          name: "Services",
          key: "services",
          icon: <ManageAccountsIcon fontSize="small" />,
          route: "/services",
          component: <Services />,
        },
        {
          type: "collapse",
          name: "Snapshots",
          key: "snapshots",
          icon: <TransformIcon fontSize="small" />,
          route: "/snapshots",
          component: <Snapshot_details />,
        },
        {
          type: "collapse",
          name: "Bundles",
          key: "bundles",
          icon: <LayersIcon fontSize="small" />,
          route: "/bundles",
          component: <Bundles />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Prospective",
      key: "prospective",

      icon: <AnalyticsIcon fontSize="small" />,
      // route: "/purchases",
      // component: <Purchases />,

      collapse: [
        {
          type: "collapse",
          name: "Acoounts",
          key: "account",
          icon: <LocationOnIcon fontSize="small" />,
          route: "/prospective/account",
          component: <ProspectiveAccounts />,
        },
        {
          type: "collapse",
          name: "Locations",
          key: "location",
          icon: <LocationOnIcon fontSize="small" />,
          route: "/prospective/location",
          component: <LocationProspective />,
        },
        {
          type: "collapse",
          name: "Users",
          key: "user",
          icon: <ManageAccountsIcon fontSize="small" />,
          icon: <Icon fontSize="small">person</Icon>,
          route: "/prospective/user",
          component: <UserProspective />,
        },
      ],
    },
    {
      route: "/purchases/details",
      component: <PurchasesDetails />,
    },
    {
      type: "collapse",
      name: "GHL Apps",
      key: "ghlapp",
      icon: <WidgetsIcon fontSize="small" />,
      // route: "/ghlapps",
      // component: <Apps />,

      collapse: [
        {
          type: "collapse",
          name: "All",
          key: "ghlapps",
          icon: <ViewQuiltIcon fontSize="small" />,
          route: "/ghlapps",
          component: <Apps />,
        },
        {
          type: "collapse",
          name: "Widget",
          key: "widget",
          icon: <ViewQuiltIcon fontSize="small" />,
          route: "/widget",
          component: <FunnelApps />,
        },
        {
          type: "collapse",
          name: " Apps",
          key: "apps",
          icon: <AppsIcon fontSize="small" />,
          route: "/apps",
          component: <WorkflowApps />,
        },
      ],
    },

    {
      type: "collapse",
      name: "Market Place",
      key: "market",
      icon: <StorefrontIcon fontSize="small" />,
      route: "/market",
      component: <Market />,
    },
    {
      type: "collapse",
      name: "Survey",
      key: "survey",
      icon: <StorefrontIcon fontSize="small" />,
      route: "/survey",
      component: <Survey />,
    },
    {
      type: "collapse",
      name: "Calender Maps",
      key: "calender",
      icon: <StorefrontIcon fontSize="small" />,
      route: "/calender",
      component: <Calender />,
    },
    {
      route: "/survey/:id",
      component: <SurveyBuilder />,
    },
    {
      route: "/survey/location_configure/:id",
      component: <LocConfigure />,
    },
    {
      route: "/market/settings",
      component: <MarketPurchases />,
    },
    {
      route: "/market/settings/purchases",
      component: <MarketPurchases />,
    },
    {
      route: "/market/settings/subscription",
      component: <Subscriptions />,
    },
    {
      route: "/market/:agency_id",
      component: <Market />,
    },
    {
      route: "/market/:agency_id/:cat_id",
      component: <Market />,
    },
    {
      type: "collapse",
      name: " University",
      key: "University",
      icon: <SchoolIcon fontSize="small" />,
      href: "https://university.levelupmarketplace.io/",
    },
    // {
    //   type      : 'collapse',
    //   name      : 'Theme',
    //   key       : 'theme',
    //   icon      : <DarkModeIcon fontSize="small"/>,
    //   route     : '/theme',
    //   component : <Theme />,
    // },
    {
      route: "/snapshot/:id/SnapshotDetail",
      component: <Iframs />,
    },
    {
      route: "/market/:id/AppDetail",
      component: <SellerIframe />,
    },
    {
      route: "/App/:id/AppDetail",
      component: <AppIframe />,
    },
    {
      route: "/stripe",
      component: <Stripe />,
    },
    {
      route: "/auth",
      component: <Auth />,
    },
    {
      route: "/auth/agency",
      component: <AgencyAuth />,
    },
    {
      route: "/auth/appconnect",
      component: <AgencyApp />,
    },
  ];
  if (agency.type == "premium") {
    // alert('')
    routes = routes.filter((item) => item.key !== "tasks");
  }
  return routes;
}

export default useRoutes;

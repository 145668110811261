import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid } from "@mui/material";
import localforage from "localforage";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../Users/style.css";
import Loader from "examples/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import env from "config";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import { RemoveRedEye } from "@material-ui/icons";
import LocationDetailsModal from "./LocationDetailsModal";

function Locations() {
  const [agency] = useAgencyInfo();
  const Service = useAppServices();
  const [locations, setlocations] = useState([]);
  const [Conjo, setConjo] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [ghl_locations, setghl_locations] = useState([]);
  const location_settings = useAgencyInfo()[4];
  const [errormsg, seterrormsg] = useState("");
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const epoch = moment.unix(1581292800);
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Address",
      options: {
        filter: false,
      },
    },
    {
      name: "Purchased Products",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount Spent",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  var axios = require("axios");
  const getLocations = async () => {
    const { response } = await Service.Purchase_snaphot.getlocations({
      query: `id=${agency._id}`,
    });
    if (response) {
      var tabel_data = [];
      for (let index = 0; index < response.Purchase_locations.length; index++) {
        const element = response.Purchase_locations[index];

        const temp_data = [
          element.loc_name,
          element.loc_add,
          element.no_of_prod,
          location_settings.currency_sign + element.total_amount,
          <LocationDetailsModal data={element} />,
        ];
        tabel_data.push(temp_data);
      }
      setConjo(tabel_data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  const onLoad = () => {
    getLocations();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <DashboardLayout>
          {/* <DashboardNavbar /> */}
          <Grid container>
            {errormsg != "" ? (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{errormsg}</Alert>
              </Stack>
            ) : (
              <Grid item xs={12}>
                <MUIDataTable
                  title={"Locations"}
                  data={Conjo}
                  columns={columns}
                  options={options}
                />
              </Grid>
            )}
            {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
}

export default Locations;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";
import localforage from "localforage";
import env from "config";
import Loader from "examples/Loader";
import { useAgencyInfo } from "context/agency";
function DashboardLayout({ children }) {
  const [agency] = useAgencyInfo();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const [agency_data, setagency_data] = useState([]);
  const [processing, setProcessing] = useState(true);

  const BASE_URL = `${env.API_URL}/v1`;
  var agency_id = "";
  useEffect(async () => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <>
      <MDBox className="main_body">
        <MDBox
          sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
            p: 3,
            position: "relative",

            [breakpoints.up("xl")]: {
              marginLeft:
                agency?.Customization?.navbar?.Categries != true
                  ? miniSidenav
                    ? pxToRem(120)
                    : pxToRem(274)
                  : 0,
              transition: transitions.create(["margin-left", "margin-right"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
              }),
            },
          })}
        >
          {children}
        </MDBox>
      </MDBox>
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;

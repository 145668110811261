import FormField from "components/FormField";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// import Multiselect from "./components/Multiselect";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";

function ModalComponent({ open, onClose, handleRefresh }) {
  const AppService = useAppServices();
  const [agency] = useAgencyInfo();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      agency_id: agency._id,
      name: e.target.name.value,
      description: e.target.description.value,
      reference_link: e.target.reference_link.value,
      purpose: e.target.purpose.value,
    };
    console.log(payload, "payload");

    const { response } = await AppService.request_app.create({ payload });
    console.log(response, "response");
    if (response) {
      onClose();
    }
  };
  return (
    <MDModal open={open} onClose={onClose} width={400}>
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDTypography variant="h5" mb={3}>
          Request App
        </MDTypography>
        <MDBox>
          <MDTypography
            variant="button"
            sx={{ fontSize: "14px", fontWeight: "500" }}
            mb={1}
          >
            Name
          </MDTypography>
          <MDInput label="Name" placeholder="Name" name="name" fullWidth />
        </MDBox>
        <MDBox>
          <MDTypography
            variant="button"
            sx={{ fontSize: "14px", fontWeight: "500" }}
            mb={1}
          >
            Description
          </MDTypography>
          <MDInput
            label="Description"
            placeholder="Description"
            name="description"
            fullWidth
          />
        </MDBox>
        <MDBox>
          <MDTypography
            variant="button"
            sx={{ fontSize: "14px", fontWeight: "500" }}
            mb={1}
          >
            Reference Link
          </MDTypography>
          <MDInput
            label="Reference Link"
            placeholder="Reference Link"
            name="reference_link"
            fullWidth
          />
        </MDBox>
        <MDBox>
          <MDTypography
            variant="button"
            sx={{ fontSize: "14px", fontWeight: "500" }}
            mb={1}
          >
            Purpose
          </MDTypography>
          <MDInput
            label="Purpose"
            placeholder="Purpose"
            name="purpose"
            fullWidth
          />
        </MDBox>
        <MDBox display="flex" justifyContent="flex-end">
          <MDButton
            variant="gradient"
            color="primary"
            type="button"
            sx={{ mt: 4, mb: 1 }}
            onClick={onClose}
          >
            close
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            sx={{ mt: 4, mb: 1, ml: 1 }}
          >
            Request
          </MDButton>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}
ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func,
};
function Modal({ handleRefresh }) {
  const [openAddMember, setOpenAddMember] = useState(false);

  return (
    <>
      <MDButton
        variant="contained"
        size="small"
        color="info"
        onClick={() => setOpenAddMember(true)}
      >
        Request App
      </MDButton>
      <ModalComponent
        open={openAddMember}
        onClose={() => setOpenAddMember(false)}
        handleRefresh={handleRefresh}
      />
    </>
  );
}
Modal.propTypes = {
  handleRefresh: PropTypes.func,
};
export default Modal;

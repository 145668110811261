import makeAnimated from 'react-select/animated';
import React, { useRef, useState, useEffect } from 'react'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const animatedComponents = makeAnimated();

const Singleselect = (props) => {
  const [data, setdata] = useState([])


  useEffect(async () => {
    console.log(props.data, 'working');
  }, [])

  return (
                
                <FormControl sx={{ m: 0, minWidth: '100%' }}>
                  <InputLabel id="demo-simple-select-autowidth-label">Product Price</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    autoWidth
                    name={props.name}
                    label="workflows"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {props.data.map((data) => (
                      <MenuItem value={data.value} key="">{data.label}</MenuItem>
                       ))}
                  </Select>
                </FormControl>

  )
}

export default Singleselect

import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useRef, useState, useEffect } from "react";
import MDModal from "components/MDModal";
import Grid from "@mui/material/Grid";
// import Multiselect from "../Multi_Selecte/Multiselect";
// import Singleselect from "../Single_Selecte";
import MDBox from "components/MDBox";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import localforage from "localforage";
import MDTypography from "components/MDTypography";
import Payable from "./Payable";
import makeAnimated from "react-select/animated";
// import "./modal.css";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useMediaQuery, useTheme } from "@material-ui/core";
import env from "config";
import SubscriptionPayable from "./subscriptionpayabale";
// import TagsInput from "components/TagsInput";
import { useAgencyInfo } from "context/agency";
import Multiselect from "layouts/agency/components/Multi_Selecte/Multiselect";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
const animatedComponents = makeAnimated();
const Snapshot_modal = (props) => {
  const [agency] = useAgencyInfo();
  const [openAddProduct, setOpenAddProduct] = useState(false);

  var stripeProducts = props.stripeProducts.filter(function (item) {
    return item.type != "recurring";
  });
  var striperecurringprod = props.stripeProducts.filter(function (item) {
    return item.type == "recurring";
  });
  // console.log(striperecurringprod, "striperecurringprod");
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlyLargeScreen = useMediaQuery(theme.breakpoints.down("md"));
  var image_file = "";
  const BASE_URL = `${env.API_URL}/v1`;

  function AddProduct({ open, onClose, item_data }) {
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "100%",
        padding: "10px 12px",
        transition: theme.transitions.create([
          "border-color",
          "background-color",
          "box-shadow",
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    }));
    const handleimage = async (e) => {
      image_file = e.target.files[0];
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (
        e.target.iframe_url.value.substr(0, 5) == "https" &&
        e.target.doc.value.substr(0, 5) == "https"
      ) {
        // item_data.loc_description
        var axios = require("axios");
        const form_data = new FormData();
        console.log(image_file);
        if (image_file == "" && item_data.image_url != undefined) {
          var img_data = item_data.image_url;
        }
        if (image_file != "") {
          var img_data = item_data.image_url;
          form_data.append("image", image_file, image_file.name);
          console.log(form_data.get("image"));

          img_data = await axios
            .post(`${BASE_URL}/utils/upload/image`, form_data)
            .then((response) => {
              setOpenAddProduct(false);
              return response.data.data;
            });
        }
        const localBrand = await localforage.getItem("user");
        const agency_id = localBrand.agency_id;
        const name = e.target.name.value;
        const setup_payable = e.target.setup_payable.value;
        const management_payable = e.target.management_payable.value;
        const category = e.target.category.value.split(",");
        // var snapshot = [];
        // var snapshot_string =[]
        // if (agency.type != "free") {
        //   var snapshot = item_data.snapshot;
        // } else {
        //   var snapshot_string = item_data.snapshot;
        // }
        // const apps = data;

        var snapshot_string = item_data.snapshot
          .map((obj) => obj.label)
          .join(", ");
        const iframe_url = e.target.iframe_url.value;
        const setup_fee = e.target.setup_fee.value;
        const management_sub = e.target.management_sub.value;
        const workflows = e.target.workflows.value;
        const doc = e.target.doc.value;
        const description = e.target.description.value;
        const image = img_data;

        const data = {
          name: name,
          setup_payable: setup_payable,
          management_payable: management_payable,

          cost_setup_payable: item_data.cost_setup_payable,
          cost_management_payable: item_data.cost_management_payable,
          cost_setup_fee: item_data.cost_setup_fee,
          cost_management_sub: item_data.cost_management_sub,
          lockfield: item_data.lockfield,

          setup_fee: setup_fee,
          management_sub: management_sub,
          doc: doc,
          apps: item_data.apps,
          description: description,
          category: JSON.parse(category),
          // snapshot: snapshot,
          snapshot_string: snapshot_string,
          iframe_url: iframe_url,
          workflows: workflows,
          image_url: image,
          agency_id: agency_id,
          type: "reselling_bundle",
          seller_id: item_data.agency_id,
          pkg_id: item_data._id,
          status: "draft",
        };
        console.log(data, "data");
        // return 0
        axios
          .post(`${BASE_URL}/snapshot/create_snapshot`, { data })
          .then(async function (response) {
            console.log(response.data);
            onClose();
            props.handleRefresh(true);
            // props.handlemessag("snapshot create successfully", "success");
          })
          .catch(function (error) {
            console.log(error);
            // props.handlemessag("snapshot not create successfully", "false");
          });
      } else {
        alert("please check your url make sure it will be start with https");
      }

      // // const { response } = await AppService.stripe.addSubscription({ payload })
    };
    return (
      <MDModal
        open={open}
        onClose={onClose}
        width={600}
        height={"auto"}
        overflow={"auto"}
        className="css-1stqary"
      >
        <MDBox>
          <MDTypography component="h1" mb={1} variant="h5">
            Create Bundle
          </MDTypography>

          <MDBox
            component="form"
            role="form"
            sx={{ width: 535 }}
            onSubmit={handleSubmit}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  What is your bundle called?
                </MDTypography>
                <BootstrapInput
                  defaultValue={item_data.loc_name}
                  disabled={item_data.lockfield.name}
                  id="bootstrap-input"
                  fullWidth
                  placeholder="name"
                  name="name"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Add your bundle description.
                </MDTypography>
                <TextareaAutosize
                  aria-label="minimum height"
                  disabled={item_data.lockfield.description}
                  defaultValue={item_data.loc_description}
                  minRows={1}
                  className="form-control"
                  name="description"
                  placeholder="description"
                  style={{ width: "100%", paddingLeft: "13px" }}
                />
              </MDBox>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Upload a cover image for your bundle
                </MDTypography>
                <MDBox py={2}>
                  <input
                    type="file"
                    name="image"
                    className="form-control h-1"
                    disabled={item_data.lockfield.image_file}
                    onChange={handleimage}
                  />

                  <p className="img_text">
                    Upload a 640 x 360 pixel PNG, GIF, or JPG.
                  </p>
                </MDBox>
              </Grid>
              <Grid item md={4} mt={1} className="img_adj">
                <img
                  src={
                    item_data.loc_image_url
                      ? item_data.loc_image_url
                      : "https://via.placeholder.com/100"
                  }
                  width={100}
                  height={80}
                  alt=""
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                <span sx={{ display: "none" }}>
                  What categories does your bundle belong to?
                </span>
              </MDTypography>
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <Multiselect data={props.formCategory} name="category" />
              </MDBox>
            </Grid>
            {/* 
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                <span sx={{ display: "none" }}>what apps are included</span>
              </MDTypography>
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <Multiselect item_data={props.Purchasedapps} name="apps" />
              </MDBox>
            </Grid>
           <Grid item md={12}>
            <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                what snaphots are included
              </MDTypography>
            {agency.type != "free" ? (
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <Multiselect item_data={props.snapshots} name="snapshot" />
              </MDBox>
            ) : (
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <TagsInput name="snapshot_string" />
              </MDBox>
            )}
            </Grid> */}
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                What is the URL that tells customers about your bundle?
              </MDTypography>
              <MDBox mb={1}>
                <BootstrapInput
                  defaultValue={item_data.iframe_url}
                  id="bootstrap-input"
                  disabled={item_data.lockfield.iframe_url}
                  fullWidth
                  placeholder="https://www.example.com/"
                  name="iframe_url"
                />
              </MDBox>
            </Grid>

            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                What is the URL with documentation about this bundle?
              </MDTypography>
              <MDBox mb={1}>
                <BootstrapInput
                  defaultValue={item_data.loc_doc}
                  id="bootstrap-input"
                  disabled={item_data.lockfield.doc}
                  fullWidth
                  placeholder="https://www.example.com/"
                  name="doc"
                />
              </MDBox>
            </Grid>
            <Payable
              data={stripeProducts}
              edit_data={""}
              payablename="setup_payable"
              label="is there a setup fee"
              name={"setup_fee"}
            />
            <SubscriptionPayable
              data={striperecurringprod}
              edit_data={""}
              payablename="management_payable"
              label="is there management subscriptions"
              name={"management_sub"}
            />

            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                When a customer purchases the bundle what workflow do you want
                to trigger?
              </MDTypography>
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <FormControl sx={{ m: 0, minWidth: "100%" }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    workflows
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    autoWidth
                    name="workflows"
                    label="workflows"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {props.workflows.map((workflows) => (
                      <MenuItem value={workflows.id} key="">
                        {workflows.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 1, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }

  const closeAddProduct = (subscription) => {
    //   if (subscription?._id) updateTable(subscription)
    setOpenAddProduct(false);
  };
  const handleClick = () => {
    console.log(props);
    setOpenAddProduct(true);
  };
  return (
    <>
      <MDButton
        variant="outlined"
        color="info"
        onClick={handleClick}
        size="small"
      >
        Configure
      </MDButton>
      <AddProduct
        open={openAddProduct}
        onClose={closeAddProduct}
        item_data={props.item}
      />
    </>
  );
};

export default Snapshot_modal;

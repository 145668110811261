import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import React from 'react'
import { Grid } from '@mui/material'
function Iframs() {
  const [snapshot_data, setsnapshot_data] = useState([])
  const [processing, setProcessing] = useState(true)
  const params=useParams()
  const snapshot_id = params.id
    const iframeStyles = {
        width: '102.5%',
        height: 'calc(100vh - 120px)',
        marginLeft: '-17px',
        marginBottom: '-27px',
        border: 'none',
      }
      useEffect(async () => {
    var axios = require('axios');
    // alert(snapshot_id)
        axios.get('https://app.api.snapshotstore.io/api/v1/snapshot/create_snapshot/filter/'+snapshot_id).then(async function (response) {
      console.log(response.data);
      setsnapshot_data(response.data.data)
      setProcessing(false)
    })
    .catch(function (error) {
      console.log(error);
    });
      }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar  />
      <Grid container mt={1}>
            </Grid>
      <iframe style={iframeStyles} src={snapshot_data.iframe_url}  />
    </DashboardLayout>
  )
}

export default Iframs
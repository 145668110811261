/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
// import MDButton from 'components/MDButton'

// Billing page components
import { useEffect, useState } from 'react'
import { useAgencyInfo } from 'context/agency'
import localforage from 'localforage'
import Invoices from './Invoices'
import { useAppServices } from 'hook/services'

function Invoice() {
  const AppService=useAppServices()
  const [agency]=useAgencyInfo()
  const [invoices, setInvoices] = useState([])
  // var axios = require('axios');

  // const getBilling = async () => {
  //   const agency = await localforage.getItem('agency');

  //   // alert(agency.stripe?.user_id)
  //   axios.get('http://localhost:8082/api/v1/snapshot/agency/stripe/invoices/'+agency._id).then(async function (response) {
  //       console.log(response.data,'invoices');
  //       setInvoices(response.data.data.data)
         
  //      })
  //      .catch(function (error) {
  //        console.log(error);
  //      });
  //   // const { response } = await AppService.agency.billing({
  //   //   query: `customer_id=${agency.stripe?.customer_id}`,
  //   // })

  //   // response && setInvoices(response.data)
  // }
  const getBilling = async (e) => {
    const { response } = await AppService.stripe.invoices({
      id:agency._id
    });
    // console.log(response,'responseresponse')
    if (response) {
      setInvoices(response.data.data)

    }
  };
  const onLoad = () => {

    getBilling()
  }

  useEffect(onLoad, [])

  const getDate = (timestamp) => {
    const option = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(timestamp * 1000).toLocaleDateString('en-US', option)
  }

  return (
    <Card sx={{ maxHeight: '410px' }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Payments
        </MDTypography>
        {/* <MDButton variant="outlined" color="info" size="small">
          view all
        </MDButton> */}
      </MDBox>
      <MDBox sx={{ maxHeight: '100%', overflow: 'auto' }} p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {invoices?.map((invoice, idx) => (
            <Invoices
              key={invoice.id}
              date={getDate(invoice.created)}
              // id={`#${invoice.receipt_number}`}
              price={`$${(invoice.amount_captured / 100).toFixed(2)}`}
              pdf={invoice.receipt_url}
              noGutter={invoices.length == idx + 1}
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default Invoice

import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import { Select } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Multiselect from "./components/Multiselect";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import MUISelect, { SelectChangeEvent } from "@mui/material/Select";
import localforage from "localforage";
import env from "config";
import { TextareaAutosize } from "@mui/material";
const Category_Modal = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [agency_id, setagency_id] = useState();
  const [custom_link, setcustom_link] = useState([]);
  const BASE_URL = `${env.API_URL}/v1`;

  useEffect(async () => {
    console.log(props);
    const localBrand = await localforage.getItem("user");
    setagency_id(localBrand.agency_id);
    var axios = require("axios");
    // alert('ljkjdh')
    var multi_categroies = [
      {
        label: "Snapshot",
        value: "snapshot",
        type: "main",
      },
      {
        label: "Service",
        value: "service",
        type: "main",
      },
      {
        label: "Websites",
        value: "websites",
        type: "main",
      },
      {
        label: "Funnels",
        value: "funnels",
        type: "main",
      },
      {
        label: "Automations",
        value: "automation",
        type: "main",
      },
    ];
    const Category = await axios
      .get(BASE_URL + "/snapshot/custom_link/" + localBrand.agency_id)
      .then(async function (response) {
        console.log(response.data, "catefgory");
        response.data.data.forEach((element) => {
          multi_categroies.push({
            label: element.name,
            value: element.name,
            type: "custom",
          });
          // setcustom_link(multi_categroies)
        });

        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    setcustom_link(multi_categroies);
  }, [props.refresh]);
  function AddProduct({ open, onClose }) {
    const initState = {
      name: "",
    };
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (e.target.type.value.length != 0 && e.target.name.value != "") {
        var axios = require("axios");

        setProcessing(true);
        const name = e.target.name.value;
        const description = e.target.description.value;
        const type = JSON.parse(e.target.type.value);
        var data = "";
        var index = {};
        type.forEach((element) => {
          if (element.type == "custom") {
            index[element.label] = 1;
          }
        });
        data = {
          name: name,
          description: description,
          agency_id: agency_id,
          custom: index,
          type: type,
          created_by: "agency",
        };
        console.log(data);
        axios
          .post(BASE_URL + "/snapshot/category/submit", { data })
          .then(async function (response) {
            console.log(response.data);
            setOpenAddProduct(false);
            props.handleChange(true);
            props.handlemessag("category create successfully", "success");
          })
          .catch(function (error) {
            console.log(error);
            props.handlemessag("category not create successfully", "false");
          });
        // const { response } = await AppService.stripe.addSubscription({ payload })

        // setProcessing(false)
        // onClose(response?.data)
      } else {
        alert("please fill the requried field!");
      }
    };
    const handleSelectChange = (e, { value }, name) => {
      formik.setValues({ ...formik.values, [name]: value });
    };

    const formik = useFormik({
      initialValues: { ...initState },
      onSubmit: handleSubmit,
    });
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Category
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDInput type="name" label="Name" name="name" fullWidth />
              </MDBox>
              <MDBox mb={1} mt={2} sx={{ fontSize: "14px" }}>
                <Multiselect data={custom_link} name="type" />
              </MDBox>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={1}
                className="form-control"
                name="description"
                placeholder="description"
                style={{ width: "100%", paddingLeft: "13px" }}
              />
            </Grid>

            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        sx={{ marginLeft: 3 }}
        variant="outlined"
        color="info"
        startIcon={<AddBoxIcon />}
        onClick={() => setOpenAddProduct(true)}
      >
        Category
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Category_Modal;

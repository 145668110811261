import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useAgencyInfo } from 'context/agency'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
function Stripe() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [agency, update] = useAgencyInfo()
  const code = new URLSearchParams(search).get('code')
  const AppServices = useAppServices()

  const [Error, setError] = useState('')

  const updateAgency = async (creds) => {
    console.log(creds)
    const payload = {
      _id: agency._id,
      stripe: {
        key: creds.access_token,
        refresh_token: creds.refresh_token,
        publish_key: creds.stripe_publishable_key,
        user_id: creds.stripe_user_id,
        access_token: creds.access_token,
      },
      
    }
    console.log(payload,"payload")

    const { response } = await AppServices.agency.update({ payload })

    if (!response) return setError('Something went wrong while integration, please try latter')

    response && update({ ...payload })
    return navigate(-2)
  }

  const getCreds = async () => {
    const { response } = await AppServices.stripe.integrateAcocunt({ query: `code=${code}` })
    if (!response) return setError('Something went wrong while integration, please try latter')
    response && updateAgency(response.data)
    return ''
  }

  const onLoad = () => {
    getCreds()
  }

  useEffect(onLoad, [])

  return (
    <DashboardLayout>
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
    </DashboardLayout>
  )
}

export default Stripe

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import env from "config";

// react-router components
import Category_Modal from "../../../layouts/agency/components/Category_Modal";
import Snapshot_Modal from "../../../layouts/agency/components/Snapshot_modal";
import Service_modal from "../../../layouts/agency/components/Service_modal";
import CustomlinkModal from "layouts/agency/components/CustomlinkModal";
import OfferModal from "../../../layouts/agency/components/OfferModal";
import CreateUsers from "layouts/agency/Users/Components/CreateUsers";
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import ChargeCustomer from "layouts/agency/Purchases/Components/ChargeCustomer";
// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { useLogout } from "hook/auth";
import { Button } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import ServeyModal from "layouts/agency/components/ServeyModal";
import { useAgencyInfo } from "context/agency";
import CalenderModal from "layouts/agency/components/CalenderModel";
// import { Switch } from '@mui/material'
// import MDTypography from 'components/MDTypography'

function DashboardNavbar({
  absolute,
  light,
  isMini,
  Purchasedapps,
  type,
  data,
  handleChange,
  custom_elements,
  snapshot,
  charge_data,
  handlemessag,
}) {
  const [agency] = useAgencyInfo();
  const [navbarType, setNavbarType] = useState();
  const [catrefresh, setcatrefresh] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [copy, setcopy] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const Logout = useLogout();
  const BASE_URL = env.API_URL + "/v1";

  useEffect(() => {
    console.log(data, "charge_datacharge_data");

    // Setting the navbar type
    // console.log(data,'data_found')
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    console.log(snapshot, "snapshot");
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const conditions = {
    snapshot: "",
  };
  // Render the notifications menu
  const handlecopy = () => {
    setcopy(true);
    setTimeout(() => {
      setcopy(false);
    }, 1000);
  };
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={Logout}
        icon={<Icon>logout</Icon>}
        title="logout"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const handleCustomChnage = () => {
    setcatrefresh(!catrefresh);
  };
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        ></MDBox>

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {type == "snapshot" ? (
              <>
                <CustomlinkModal
                  handleChange={handleCustomChnage}
                  handlemessag={handlemessag}
                />
                <Category_Modal
                  handleChange={handleChange}
                  refresh={catrefresh}
                  handlemessag={handlemessag}
                />
                <Snapshot_Modal
                  snapshots={data.snapshots}
                  Catgories={data.Categories}
                  stripeProducts={data.stripeProducts}
                  handleChange={handleChange}
                  handlemessag={handlemessag}
                  workflows={data.workflows}
                />
                <Service_modal
                  snapshots={data.snapshots}
                  Catgories={data.Categories}
                  stripeProducts={data.stripeProducts}
                  handleChange={handleChange}
                  handlemessag={handlemessag}
                  workflows={data.workflows}
                />
                <OfferModal
                  snapshots={data.snapshots}
                  Purchasedapps={Purchasedapps}
                  Catgories={data.Categories}
                  stripeProducts={data.stripeProducts}
                  handleChange={handleChange}
                  handlemessag={handlemessag}
                  workflows={data.workflows}
                />
              </>
            ) : (
              <></>
            )}
            {type == "servey_page" ? (
              <ServeyModal handleChange={handleChange} data={data} />
            ) : type == "calender_page" ? (
              <CalenderModal handleChange={handleChange} data={data} />
            ) : (
              <></>
            )}
            {type == "bundle" ? (
              <>
                <OfferModal
                  snapshots={data.snapshots}
                  location_settings={data.location_settings}
                  Purchasedapps={Purchasedapps}
                  Catgories={data.Categories}
                  stripeProducts={data.stripeProducts}
                  handleChange={handleChange}
                  handlemessag={handlemessag}
                  workflows={data.workflows}
                />
              </>
            ) : (
              <></>
            )}
            {type == "service_page" ? (
              <>
                <Service_modal
                  snapshots={data.snapshots}
                  Catgories={data.Categories}
                  stripeProducts={data.stripeProducts}
                  handleChange={handleChange}
                  workflows={data.workflows}
                />
              </>
            ) : (
              <></>
            )}
            {type == "snapshot_page" ? (
              <>
                <Snapshot_Modal
                  snapshots={data.snapshots}
                  Catgories={data.Categories}
                  stripeProducts={data.stripeProducts}
                  handleChange={handleChange}
                  workflows={data.workflows}
                />
              </>
            ) : (
              <></>
            )}

            {type == "category" ? (
              <>
                <CustomlinkModal
                  handleChange={handleChange}
                  custom_elements={custom_elements}
                />
              </>
            ) : (
              <></>
            )}

            {type == "users" ? (
              // <>
              //   <CopyToClipboard
              //     text={BASE_URL + "/auth/user/" + agency._id}
              //     onCopy={handlecopy}
              //     sx={{ mr: 2 }}
              //   >
              //     <Button
              //       variant="contained"
              //       type="button"
              //       color="primary"
              //       className={"text-light btn-success"}
              //     >
              //       {copy ? "Copied" : "Copy Webhook Url"}
              //     </Button>
              //   </CopyToClipboard>
              <CreateUsers
                handleChange={handleChange}
                handlemessag={handlemessag}
              />
            ) : (
              // </>
              <></>
            )}
            {type == "purchases" ? (
              <>
                <ChargeCustomer
                  handleChange={handleChange}
                  charge_data={charge_data}
                />
              </>
            ) : (
              <></>
            )}

            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
           size="small"
           disableRipple
           color="inherit"
           sx={navbarIconButton}
           aria-controls="notification-menu"
           aria-haspopup="true"
           variant="contained"
           onClick={handleOpenMenu}
         >
           <Icon sx={iconsStyle}>account_circle</Icon>
         </IconButton> */}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  isMini: "",
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  type: PropTypes.string,
  data: PropTypes.array,
  snapshot: PropTypes.array,
  handleChange: PropTypes.func,
  handlemessag: PropTypes.func,
};

export default DashboardNavbar;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useAgencyInfo } from "context/agency";

function BillingInformation({ data, purchased_data }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const location_settings = useAgencyInfo()[4];

  return (
    <>
      {/* <MDTypography variant="h6" fontWeight="medium">
        Billing Information
      </MDTypography> */}
      <MDBox mb={2}>
        <MDTypography
          variant="button"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Customer Info
        </MDTypography>
      </MDBox>
      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        borderRadius="lg"
        mt={2}
      >
        <MDBox width="50%" display="flex" flexDirection="column" lineHeight={1}>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" fontWeight="regular" color="text">
              Customer Name:&nbsp;&nbsp;&nbsp;
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {data?.user_data?.username}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" fontWeight="regular" color="text">
              Customer Email:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {data?.user_data?.email}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDTypography variant="caption" fontWeight="regular" color="text">
            Customer Phone:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {data?.user_data?.phone}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox width="50%" display="flex" flexDirection="column" lineHeight={1}>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" fontWeight="regular" color="text">
              No of Purchased Product:&nbsp;&nbsp;&nbsp;
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {purchased_data?.no_of_prod}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" fontWeight="regular" color="text">
              Amount Spend:&nbsp;&nbsp;&nbsp;
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {location_settings.currency_sign + purchased_data?.total_amount}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default BillingInformation;

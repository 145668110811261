import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import env from "config";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Alert, Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Loader from "examples/Loader";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../Users/style.css";

import { useEffect, useState } from "react";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/MDBox";
import LoadApps from "./components/LoadApps"
import LoadSnapshot from "./components/LoadSnapshot"
function Default() {
  const [agency] = useAgencyInfo();
  const Service = useAppServices();
  const [Conjo, setConjo] = useState([]);
  const [purchasedata, setpurchasedata] = useState([]);
  const [loader, setloader] = useState(true);
  const [newusers, setnewusers] = useState("");
  const [Users_data, setUsers_data] = useState([]);
  const location_settings = useAgencyInfo()[4];

  const columns = [
    {
      name: "Location Name",
      options: {
        filter: false,
      },
    },
    {
      name: "User Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Phone",
      options: {
        filter: false,
      },
    },
    {
      name: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "Address",
      options: {
        filter: false,
      },
    },
    {
      name: "Purchased Prod",
      options: {
        filter: false,
      },
    },
    {
      name: "Total Revenue",
      options: {
        filter: false,
      },
    },
    {
      name: "Active Subsubcriptions",
      options: {
        filter: false,
      },
    },
    {
      name: "MRR Total",
      options: {
        filter: false,
      },
    },
    {
      name: "Last Purchase",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getDashboardData = async (e) => {
    const { response } = await Service.snapshots.getdashboard({
      query: `id=${agency._id}`,
    });
    console.log(response, "getDashboardData");
    if (response) {
      setUsers_data(response.data);
      var temp_users = 0;
      response.data.users.forEach((element) => {
        if (element.roles.includes("admin")) {
          temp_users++;
        }
      });
      setnewusers(temp_users);
    } else {
      setUsers_data([]);
    }
  };
  const getPurchaseData = async () => {
    const { response } = await Service.prospective.getpurchasedata({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "getPurchaseData");
    if (response) {
      setpurchasedata(response.data)
    } 
  };
  const getLocations = async () => {
    const { response } = await Service.prospective.filter({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "responseresponse");
    if (response) {
      var tabel_data = [];
      response?.data.forEach(async(element) => {
        var price = "";
        // setLocations(location_data)
        const temp = [
          element.location.name,
          `${element.location.firstName}  ${element.location.lastName}`,
          element.location.phone,
          element.location.email,
          element.location.address,
          element.total_prod,
         `${location_settings.currency_sign}  ${element.total_revenue}`,
          element.active_sub,
          `${location_settings.currency_sign} ${element?.mrr_total || 0}`,
          `${location_settings.currency_sign} ${element?.last_prchase?.amount || 0}`,
          <MDBox display="flex" >
          <LoadApps charge_data={purchasedata}/>
          <LoadSnapshot charge_data={purchasedata}/>
          </MDBox>
        ];
        tabel_data.push(temp);
      });
      setConjo(tabel_data);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  const onLoad =async () => {
  await  getPurchaseData()
    getDashboardData()
    getLocations();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <DashboardLayout>
          <DashboardNavbar type="location_prospective" handleChange={''}
              charge_data={purchasedata} />
          <Grid container spacing={3}>
            {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}
            <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Purchases"
                count={Users_data?.total_purchases}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Store Views"
                count={Users_data?.storeviews?.total}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count={
                  Users_data.totalrevenue != undefined
                    ? `${location_settings.currency_sign}  ${Users_data?.totalrevenue}`
                    : ""
                }
                percentage={{
                  color: "success",
                  amount: "",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="New users"
                count={newusers}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
            <Grid item xs={12}>
              <MUIDataTable
                title={"Locations"}
                data={Conjo}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
}

export default Default;

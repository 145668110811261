import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import localforage from "localforage";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import env from "config";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import "./style.css"
import { useUserInfo } from "context/user";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const BuyModal = (props) => {
  const AppService = useAppServices();
  const [user]=useUserInfo()
  const [agency]=useAgencyInfo()
  const stripePromise = loadStripe(agency.stripe.publish_key);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [brand, setbrand] = useState([]);
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();
  const params = useParams();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const inputRef = useRef();
  var path = pathname.split("/")[2];
  const location_settings = useAgencyInfo()[5];

  var loc_name = decodeURIComponent(pathname.split("/")[4])
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = decodeURIComponent(pathname.split("/")[5])
  var axios = require("axios");
  let navigate = useNavigate();

  const handlemodal = async (newState) => {
    if (brand == null) {
      setOpenAddProduct(true);
      // props.handleAlert(
      //   "for purchase a snapshot make sure you must be login!",
      //   "auth"
      // );
    } else {
      setOpenAddProduct(true);
    }
  };
  useEffect(async () => {
    const brand_data = await localforage.getItem("brand");
    console.log(props.data,'fgyu')
    // alert(brand_data)
    setbrand(brand_data);
  }, []);
  function AddProduct({ open, onClose, handleAlert, stripe_data }) {
    const [processing, setProcessing] = useState(false);
    const stripe = useStripe();

    const handleSubmit = async (newState) => {
      // e.preventDefault();

      setProcessing(true);

      if (stripe_data != "") {
          const payload = {
            ...props.data,
            user_id: user._id,
            agency_id: agency._id,
            seller_agency_id: params.agency_id,
          };
          const { response } = await AppService.bundles.load({ payload });
          if (response) {
            if (response.status == "completed") {
              setProcessing(false);
              onClose();
              props.handelchange();
            } else {
              stripe
                .confirmCardPayment(response.data.client_secret, {})
                .then(async function (result) {
                  const status = "succeeded";
                  // if (result?.paymentIntent?.status == "succeeded") {
                  //   const payload = {
                  //     create_status: true,
                  //     app_id: data.app_id,
                  //     config_app_data: response.agency_sub,
                  //     locationId: loc_data.location_id,
                  //     user_id: brand._id,
                  //     loc_name: loc_name,
                  //     loc_add: loc_add,
                  //     agency_id: agency._id,
                  //     sub_id: response.subscription_data.id,
                  //     status: "succeeded",
                  //   };
                  //   const update_result = await AppService.location_apps.update({
                  //     payload,
                  //   });
                  //   if (update_result.response) {
                  //     setProcessing(false);
                  //     onClose();
                  //     props.handelchange();
                  //   }
                  // } else {
                  //   setProcessing(false);
                  //   // alert("fyg");
                  //   onClose();
                  //   props.handelchange();
                  // }
                });
            }
          } else {
            setProcessing(false);
            props.handelchange();
            setOpenAddProduct(false);
          }
        
      } else {
        setProcessing(false);
        onClose();

        alert(
          "For Purchase a Bundle make Sure Your Card Must Be Registered!",
          "billing"
        );
      }
    };
    return (
      <MDModal open={open} onClose={onClose} className={"buy--modal"}>
        {/* <MDBox sx={{ width: 365 }}>
          <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
            <b>Snapshot Name:</b> {props.data.name}
          </MDTypography>
          <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
            <b>Description:</b> {props.data.description}
          </MDTypography>
          <MDTypography variant="body1" sx={{ fontSize: "16px" }}>
            <b>Price:</b>{" "}
            {props.data.payable == "no"
              ? "Free"
              : "$" +
                props.data?.purchase_listener?.substr(
                  0,
                  props.data?.purchase_listener?.indexOf("(")
                )}
          </MDTypography>
          <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
            Your {props.data.type} will be loaded on location{" "}
            <b>({loc_name}) </b>
          </MDTypography>
          <MDBox component="form" role="form" sx={{ width: 365 }}>
            <MDBox className="d-flex justify-content-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                sx={{ mt: 4, mb: 1, ml: 1 }}
                onClick={handleSubmit}
              >
                confirm {props.label}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox> */}
        <div>
          <form className="form">
            <div className="banner"></div>
            <label className="title">
              {props.data.type == "snapshot"
                ? "Get Your Snapshot Loaded"
                : "Get Your Service Requested"}
            </label>
            <label className="title-main">{props.data.name}</label>

            <div className="benefits">
              <span>Description</span>
              <ul>
                <li>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 16"
                    height="16"
                    width="16"
                  >
                    <rect fill="black" rx="8" height="16" width="16"></rect>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="1.5"
                      stroke="white"
                      d="M5 8.5L7.5 10.5L11 6"
                    ></path>
                  </svg> */}
                  <span>{props.data.description}</span>
                </li>
              </ul>
            </div>

            <div className="modal--footer">
              <label className="price">
                <sup>{location_settings.currency_sign}</sup>
                {props.data.payable == "no"
                  ? "Free"
                  : props.data?.purchase_listener}
              </label>
              <MDButton
                loading={processing}
                disabled={processing}
                className="upgrade--btn"
                type="button"
                onClick={handleSubmit} 
              >
                Confirm to Buy
              </MDButton>
            </div>
          </form>
        </div>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  // const handleAlert = async (newState) => {
  //   const localBrand = await localforage.getItem("loc_data");
  //   setState({ ...state, open: true });
  //   setTimeout(() => {
  //     navigate(
  //       `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/settings`
  //     );
  //   }, 2000);
  // };
  return (
    <>
      <MDButton
        variant="outlined"
        size="small"
        color="primary"
        className="purchase-btn"
        onClick={handlemodal}
      >
        {props.label}
      </MDButton>
      <Elements stripe={stripePromise}>
      <AddProduct
        open={openAddProduct}
        stripe_data={props.stripe_data}
        onClose={closeAddProduct}
        handleAlert={props.handleAlert}
      />
      </Elements>
    </>
  );
};

export default BuyModal;

import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import { Select } from "antd";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Multiselect from "../Multi_Selecte/Multiselect";
import localforage from "localforage";
import Singleselect from "../Single_Selecte";
import Payable from "./components/Payable";
import env from "config";
const Snapshot_Edit = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [processingpayable, setprocessingpayable] = useState(props.data.name);
  const [stripeProducts_data, setstripeProducts_data] = useState([]);
  const [Catgories_data, setCatgories_data] = useState([]);
  // const [agency_id, setagency_id] = useState('')
  const nameRef = useRef();
  const iframe_urlRef = useRef();
  const workflowsRef = useRef();
  const BASE_URL = `${env.API_URL}/v1`;
  var image_file = props.image_url;

  const handlemodal = () => {
    setTimeout(() => {
      console.log(props.item);
      setOpenAddProduct(true);
      nameRef.current.value = props.item.name;
      iframe_urlRef.current.value = props.item.iframe_url;
      workflowsRef.current.value = props.item.workflows;
    }, 100);
  };
  useEffect(async () => {
    var data = [];
    var Temp_Catgories_data = [];
    props.data.stripeProducts.data.forEach((element) => {
      console.log(props.item);
      const temp = {
        id: element.id,
        name: element.unit_amount / 100,
        nickname: element.nickname,
      };
      data.push(temp);
    });
    await setstripeProducts_data(data);
    props.data.Catgories.forEach((element) => {
      const temp = {
        id: element._id,
        name: element.name,
      };
      Temp_Catgories_data.push(temp);
    });
    // console.log(props, 'props')
    await setCatgories_data(Temp_Catgories_data);
  }, []);
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false);
    const handleimage = async (e) => {
      image_file = e.target.files[0];
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      var axios = require("axios");

      setProcessing(true);

      const name = e.target.name.value;
      const payable = e.target.payable.value;
      const category = e.target.category.value.split(",");
      const snapshot = e.target.snapshot.value.split(",");
      const iframe_url = e.target.iframe_url.value;
      const purchase_listener = e.target.purchase_listener.value;
      const workflows = e.target.workflows.value;
      const form_data = new FormData();
      form_data.append("image", image_file, image_file.name);
      console.log(form_data.get("image"));
      const image = await axios
        .post(`${BASE_URL}/utils/upload/image`, form_data)
        .then((response) => {
          setOpenAddProduct(false);
          return response.data.data;
        });

      const data = {
        name: name,
        payable: payable,
        category: category,
        snapshot: snapshot,
        iframe_url: iframe_url,
        purchase_listener: purchase_listener,
        workflows: workflows,
        image_url: image,
        loc_id: "location_id",
        agency_id: props.item.agency_id,
      };
      console.log(data, "data");
      axios
        .post(BASE_URL + "/snapshot/create_snapshot/update/" + props.item._id, {
          data,
        })
        .then(async function (response) {
          console.log(response.data);
          props.handleChange(true);
        })
        .catch(function (error) {
          console.log(error);
        });

      setOpenAddProduct(false);
      // const { response } = await AppService.stripe.addSubscription({ payload })

      // setProcessing(false)
      // onClose(response?.data)
    };
    return (
      <MDModal open={open} onClose={onClose} width={600}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Snapshots
          </MDTypography>

          <MDBox
            component="form"
            role="form"
            sx={{ width: 535 }}
            onSubmit={handleSubmit}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <input
                  type="text"
                  placeholder="name"
                  name="name"
                  ref={nameRef}
                  className="form-control"
                />
              </MDBox>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <MDBox mb={1} py={2}>
                  <input
                    type="file"
                    name="image"
                    className="form-control h-1"
                    onChange={handleimage}
                  />

                  <p className="img_text">
                    Upload a 235 x 134 pixel PNG, GIF, or JPG to display in
                    browser tabs.
                  </p>
                </MDBox>
              </Grid>
              <Grid item md={4} className="img_adj">
                <img
                  src="https://via.placeholder.com/100"
                  width={100}
                  height={100}
                  alt=""
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                test
              </MDTypography>
              <MDBox mb={1}>
                <Multiselect
                  data={Catgories_data}
                  edit_data={props.item.category}
                  name="category"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                What snap shot are we selling?
              </MDTypography>
              <MDBox mb={1}>
                <Multiselect
                  data={props.data.snapshots}
                  edit_data={props.item.snapshot}
                  name="snapshot"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                What is the url that has information about your snapshot?
              </MDTypography>
              <MDBox mb={1}>
                <input
                  type="text"
                  placeholder="Iframs"
                  name="iframe_url"
                  ref={iframe_urlRef}
                  className="form-control"
                />
              </MDBox>
            </Grid>

            <Payable
              data={stripeProducts_data}
              edit_data={props.item.purchase_listener}
              name={"purchase_listener"}
            />

            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                When a customer purchases the snap shot what workflow do you
                want to trigger?
              </MDTypography>
              <MDBox mb={1}>
                <input
                  type="text"
                  placeholder="workflows"
                  name="workflows"
                  ref={workflowsRef}
                  className="form-control"
                />
              </MDBox>
            </Grid>
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 0, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="outlined"
        color="info"
        type="submit"
        onClick={handlemodal}
      >
        Edit
      </MDButton>

      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Snapshot_Edit;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import env from "config";
import BuyModal from "./BuyModal";
import BuyOfferModal from "./BuyOfferModal ";
import Card_details from "./Card_details/Card_details";
import { useState } from "react";
import Offer_Edit from "./Offer_Edit";
import PaymentMethod from "./PaymentMethod";
import ServiceConfigure from "./ServiceConfigure";
function DefaultProjectCard({
  image,
  label,
  formCategory,
  workflows,
  title,
  description,
  action,
  price,
  authors,
  button_label,
  stripeProducts,
  purchases,
  button_url,
  Purchasedapps,
  handleChange,
  handleRefresh,
  stripeData,
  data,
  item,
  handlemessag,
}) {
  const [customer, setCustomer] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  console.log(purchases, "purchases");
  const handledelete = (id) => () => {
    const BASE_URL = `${env.API_URL}/v1`;

    var axios = require("axios");

    axios
      .post(BASE_URL + "/snapshot/create_snapshot/delete?id=" + id)
      .then(async function (response) {
        console.log(response.data);
        handleChange(true);
        handleRefresh(true);
        handlemessag("snapshot delete successfully", "success");
      })
      .catch(function (error) {
        console.log(error);
        handlemessag("snapshot delete successfully", "false");
      });
  };
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <MDAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            maxWidth: "100%",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </MDBox>
      <MDBox mt={1} mx={0.5}>
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
        >
          {label}
        </MDTypography>
        <MDBox mb={1}>
          {action.type === "internal" ? (
            <MDTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          ) : (
            <MDTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mb={3} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox>

        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDButton
            variant="outlined"
            size="small"
            component={Link}
            state={{ data: item, purchases: purchases }}
            to={`/agency/market/${item._id}/AppDetail`}
            color="info"
          >
            view
            {/* {props.label} */}
          </MDButton>
          {stripeData != "" ? (
            purchases.findIndex((e) => e.pkg_id === item._id) > -1 ? (
              item?.type == "service" ? (
                <ServiceConfigure
                  handleRefresh={handleChange}
                  item={item}
                  stripeProducts={stripeProducts}
                  workflows={workflows}
                  formCategory={formCategory}
                />
              ) : (
                // <>
                <Offer_Edit
                  handleRefresh={handleChange}
                  item={item}
                  stripeProducts={stripeProducts}
                  workflows={workflows}
                  formCategory={formCategory}
                />
              )
            ) : (
              <BuyOfferModal
                price={price}
                handleRefresh={handleChange}
                // purchased_apps={purchased_apps}
                data={item}
                // handleAlert={handleAlert}
                // stripe_data={stripe_data}
                label={button_label}
                // purchased_data={purchased_data}
                // handleMessage={handleMessage}
                handelchange={handleChange}
                // handelchange={handelchange}
                // location_data={location_data}
              />
            )
          ) : (
            <PaymentMethod
              handleRefresh={handleRefresh}
              agency_stripe={stripeData}
              paymentMethodState={{
                paymentMethods,
                setPaymentMethods,
              }}
              customerState={{ customer, setCustomer }}
            />
          )}

          <MDBox display="flex">{renderAuthors}</MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    button_label: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    item: PropTypes.array.isRequired,
    handleChange: PropTypes.func,
    handleRefresh: PropTypes.func,
    handlemessag: PropTypes.func,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
  work_flow: PropTypes.array,
};

export default DefaultProjectCard;

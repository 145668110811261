import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import env from "config";
import Multiselect from "./inputs/Multiselect";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
const Default = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [agency_id, setagency_id] = useState();
  const [processing, setProcessing] = useState(false);
  const BASE_URL = `${env.API_URL}/v1`;

  useEffect(async () => {
    const localBrand = await localforage.getItem("user");
    setagency_id(localBrand.agency_id);
    console.log(props, "props");
  }, []);
  function AddProduct({ open, onClose }) {
    const initState = {
      name: "",
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
        var axios = require("axios");

        setProcessing(true);
        const snapshots = JSON.parse(e.target.snapshots.value);
        const two_way_transaction = e.target.two_way_transaction.checked;
        const data = {
          snapshots: snapshots,
          two_way_transaction:two_way_transaction
        };
        console.log(data);
        // axios
        //   .post(
        //     BASE_URL + "/snapshot/create_snapshot/custom_load/" + agency_id,
        //     { data }
        //   )
        //   .then(async function (response) {
        //     console.log(response.data);
        //     // props.handleChange(true);
        //     setOpenAddProduct(false);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //     setOpenAddProduct(false);
        //   });
        // const { response } = await AppService.stripe.addSubscription({ payload })

        setProcessing(false);
    };
    return (
      <MDModal open={open} onClose={onClose} height={"450px"}>
        <MDBox>
          <MDTypography component="h1" mb={5} variant="h5">
            Load Snapshot For User
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid container spacing={1}>

            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                Select Snapshots
              </MDTypography>
              <MDBox mb={1} mt={1} sx={{ fontSize: "14px" }}>
                <Multiselect
                  data={props.charge_data.snapshots}
                  name="snapshots"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name="two_way_transaction"/>}
                  label={
                    <MDTypography
                      variant="button"
                      display="block"
                      gutterBottom
                    >
                      Two Way Transaction
                    </MDTypography>
                  }
                />
              </FormGroup>
            </Grid>
            </Grid>
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: "9rem", mb: 1 }}
                loading={processing}
                disabled={processing}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        sx={{ marginLeft: 1 }}
        variant="contained"
        size="small"
        color="info"
        onClick={() => setOpenAddProduct(true)}
      >
        Load Snapshots
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Default;

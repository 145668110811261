/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "./components/ProfileInfoCard";
// import ProfilesList from 'examples/Lists/ProfilesList'
import ComplexStatisticsCard from "../MarketPlace/components/ComplexStatisticsCard";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import { useUserInfo } from "context/user";

// Overview page components
import Header from "./components/Header";
import Card_details from "./components/Card_details/Card_details";
// import PlatformSettings from './components/PlatformSettings'

// Data
// import profilesListData from './data/profilesListData'
function UserInfo({ seller_data }) {
  return (
    <MDBox mt={5} mb={3}>
      <Grid container spacing={1}>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
          <ProfileInfoCard
            info={{
              fullName: seller_data?.profile?.bussiness_name,
              mobile: seller_data?.profile?.phone_number,
              email: seller_data?.profile?.email,
              companyWebiste: seller_data?.profile?.company_webiste,
              country: seller_data?.profile?.country,
              city: seller_data?.profile?.city,
              state: seller_data?.profile?.country,
              address: seller_data?.profile?.address,
              zipCode: seller_data?.profile?.zip_code,
            }}
            social={[
              {
                link: seller_data?.profile?.facebook,
                icon: <FacebookIcon />,
                color: "facebook",
              },
              {
                link: seller_data?.profile?.twitter,
                icon: <TwitterIcon />,
                color: "twitter",
              },
              {
                link: seller_data?.profile?.instagram,
                icon: <InstagramIcon />,
                color: "instagram",
              },
            ]}
            shadow={false}
          />
          <Divider orientation="vertical" sx={{ mx: 0 }} />
        </Grid>
      </Grid>
    </MDBox>
  );
}
function Overview(props) {
  // const [user] = useUserInfo();
  return (
    <MDBox mb={2}>
      <Header
        seller_data={props.seller_data}
        elements={[
          <UserInfo seller_data={props.seller_data} />,
          <Card_details handleChange={props.handleChange} />,
        ]}
      >
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
      </Header>
      <MDBox p={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {props.CategoryData.map((category, key) =>
              category.name != "business Info" ? (
                <ComplexStatisticsCard
                  color="info"
                  icon="weekend"
                  title="Category"
                  stripeData={props.stripeData}
                  formCategory={props.formCategory}
                  category_data={category}
                  refresh={props.refresh}
                  configapps={props.configapps}
                  StoreData={props.StoreData}
                  handleChange={props.handleChange}
                  handleCategoryChange={props.handleCategoryChange}
                  handlemessag={props.handlemessag}
                />
              ) : (
                <></>
              )
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Overview;

import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import React, { useRef, useState, useEffect } from 'react'


const animatedComponents = makeAnimated();
const PayableSelecte = (props) => {
  const [data, setdata] = useState([])


  useEffect(async () => {
    
    var temp_data = []
    props.data.forEach(element => {
      const temp = {
        label: element.unit_amount / 100 + '(' + element.nickname + ')-' + element.id,
        value:element.unit_amount / 100 + '(' + element.nickname + ')-' + element.id,
      }
      temp_data.push(temp)

    });
    await setdata(temp_data)
  }, [])
  
  return (
    <>
    <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                name={props.name}
                defaultValue={props.edit_data}
                options={data}
              />
    </>
  )
}
export default PayableSelecte

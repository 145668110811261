import makeAnimated from "react-select/animated";
import Select from "react-select";
import React, { useRef, useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const animatedComponents = makeAnimated();
const TagsInput = (props) => {
  const [data, setdata] = useState([]);
  const [values, setvalues] = useState(props?.edit_value || []);

  const handleAutocompleteChange = (event, newValue) => {
    setvalues(newValue);
    console.log(newValue, "newValue");
  };
  const handlechange = (e) => {
    console.log(e);
    console.log(JSON.stringify(e));
    setvalues(JSON.stringify(e));
  };
  useEffect(async () => {
    if (props.name == "category") {
      console.log(props, "categorycategorycategory");
    }
    console.log(props, "prop");
  }, []);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={[]}
        name="snapshot"
        freeSolo
        multiple
        defaultValue={values}
        onChange={handleAutocompleteChange}
        renderInput={(params) => <TextField {...params} label="Snapshots" />}
      />
      <input type="hidden" value={values} name={props.name} />
    </>
  );
};
export default TagsInput;

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// Material Dashboard 2 React components
import LanguageIcon from "@mui/icons-material/Language";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MDBox from "components/MDBox";
// import MDTypography from 'components/MDTypography'
// import MDAvatar from 'components/MDAvatar'
import localforage from "localforage";
import env from "config";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import MDTypography from "components/MDTypography";

// Images
// import burceMars from 'assets/images/bruce-mars.jpg'
// import backgroundImage from 'assets/images/bg-profile.jpeg'
// import { useUserInfo } from 'context/user'

function Header({ elements, custom_link, custom_elements }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const BASE_URL = `${env.API_URL}/v1`;
  // const [user] = useUserInfo()
  const [tabValue, setTabValue] = useState(0);
  const [customTabValue, setcustomTabValue] = useState("");

  useEffect(async () => {
    if (custom_elements.length == 0) {
      setcustomTabValue("");
      setTabValue(0);
    }
    console.log(custom_link, "custom_link");
    console.log(elements, "elements");
    console.log(custom_elements, "custom_elements");
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation, custom_elements]);

  const handleSetTabValue = (event, newValue) => {
    setcustomTabValue("");
    setTabValue(newValue);
  };
  const handleCustomTabs = (event, newValue) => {
    setTabValue("");
    setcustomTabValue(newValue);
  };

  return (
    <MDBox position="relative" mb={5} mt={5}>
      {/* <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize     : 'cover',
          backgroundPosition : '50%',
          overflow           : 'hidden',
        }}
      /> */}
      <Card
        sx={{
          position: "relative",
          mt: 0,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {
          //   <MDBox
          //   mx={2}
          //   mt={-3}
          //   py={3}
          //   px={2}
          //   variant="gradient"
          //   bgColor="info"
          //   borderRadius="lg"
          //   coloredShadow="info"
          // >
          //   <MDTypography variant="h6" color="white">
          //     Manage Navigation
          //   </MDTypography>
          // </MDBox>
        }
        <Grid container spacing={3} alignItems="center">
          {/* <Grid item>
            <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {user.username}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {user.roles?.join(', ')}
              </MDTypography>
            </MDBox>
          </Grid> */}
          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mt: 2 }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab
                  label="Everything Store"
                  icon={<SelectAllIcon fontSize="small" sx={{ mt: -0.25 }} />}
                />
                <Tab
                  label="Snapshot Store"
                  icon={<CopyAllIcon fontSize="small" sx={{ mt: -0.25 }} />}
                />
                <Tab
                  label="Service Store"
                  icon={
                    <ManageAccountsIcon fontSize="small" sx={{ mt: -0.25 }} />
                  }
                />
                <Tab
                  label="Funnel Store"
                  icon={<FilterAltIcon fontSize="small" sx={{ mt: -0.25 }} />}
                />
                <Tab
                  label="Website Store"
                  icon={<LanguageIcon fontSize="small" sx={{ mt: -0.25 }} />}
                />
                <Tab
                  label="Automation Store"
                  icon={<LanguageIcon fontSize="small" sx={{ mt: -0.25 }} />}
                />
                <Tab
                  label="Apps Store"
                  icon={<LanguageIcon fontSize="small" sx={{ mt: -0.25 }} />}
                />
                <Tab
                  label="Widget Store"
                  icon={<LanguageIcon fontSize="small" sx={{ mt: -0.25 }} />}
                />
              </Tabs>
            </AppBar>
          </Grid>
          {custom_link.length != 0 ? (
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mt: 2 }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={customTabValue}
                  onChange={handleCustomTabs}
                >
                  {custom_link.map((custom_link) => (
                    <Tab
                      label={custom_link.label}
                      icon={
                        <LanguageIcon fontSize="small" sx={{ mt: -0.25 }} />
                      }
                    />
                  ))}
                </Tabs>
              </AppBar>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        {/* {tabValue == "" ? custom_elements[customTabValue] : elements[tabValue]} */}
        {custom_elements[customTabValue]}
        {elements[tabValue]}
      </Card>
    </MDBox>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Header;

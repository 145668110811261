import React from "react";
import DashboardLayout from "./components/AgencyDashboardLayout/index";
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import SidenavRoot from "./components/SidenavRoot";
import { useMaterialUIController } from "context";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "./components/ComplexStatisticsCard";
import { IconButton } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import localforage from "localforage";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MDButton from "components/MDButton";
import Loader from "examples/Loader";
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";
import MDTypography from "components/MDTypography";
import "./components/style.css";
import "./components/ghl.css";
import { useState } from "react";
import Sidnav from "./components/Sidnav";
import Card from "./components/card";
import DashboardNavbar from "./components/DashboardNavbar";
import MainDashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import Navbar from "./Navbar";
import Body from "./components/Body";
import env from "config";
import { element } from "prop-types";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
const Demo = () => {
  const AppService=useAppServices()
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const [openCollapse, setopenCollapse] = React.useState(false);
  const [agency, Update] = useAgencyInfo();
  const [processing, setProcessing] = useState(false);
  const [agency_data, setagency_data] = useState([]);
  const [theme_data, settheme_data] = useState([]);
  const [user_data, setuser_data] = useState([]);
  const [loader, setloader] = useState(true);
  const [button, setbutton] = useState(false);
  const [ghlTheme, setghlTheme] = useState(
    agency?.Customization?.ghlTheme || false
  );
  const [refresh, setrefresh] = useState(false);
  const [handleopen, sethandleOpen] = useState({
    sidnav: false,
    cards: false,
    navbar: false,
    body: false,
    theme: false,
  });
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const BASE_URL = `${env.API_URL}/v1`;
  const [initState, setinitState] = React.useState({
    sideNavColor: "linear-gradient(195deg, #49a3f1, #1A73E8)",
    cardsboxshadow: "",
    CategriesDropdown: false,
    GridView: false,
    bodyColor: "#fff",
    navbarbg: "#fff",
    cardsBodyColor: "#fff",
    Filter: false,
    Categries: false,
    PurchaseButton: "#e91e63",
    SnapshotButton: "#1A73E8",
    cardsBodyText: "#90809A",
    cardsBodyHeading: "#7B809A",
    cardsTitle: "#334666",
    cardsFontFamily: "",
    sidnavFontFamily: "",
    sidnavBg: "linear-gradient(195deg, #42424a, #191919)",
    sideNavText: "#fff",
    sideNavCard: "linear-gradient(195deg, #49a3f1, #1A73E8)",
  });
  const handleClick = () => {
    setopenCollapse(!openCollapse);
  };
  // const [Navbar, setNavbar] = useState({
  //   Categries: false,
  //   filter: false,
  //   GridView: false,

  // });

  const [open, setOpen] = useState({
    sidnav: false,
    cards: false,
    features: false,
    navbar: false,
    defeult_settings: false,
  });
  const UpdateVaribales = () => {
    document.documentElement.style.setProperty("--rightsidebar-width", "0px");
    document.documentElement.style.setProperty(
      "--sidnavBg",
      agency?.sideNavType == "dark"
        ? "linear-gradient(195deg, #42424a, #191919)"
        : agency?.sideNavType
    );
    document.documentElement.style.setProperty(
      "--sideNavCard",
      agency?.Customization?.Cards?.background ||
        "linear-gradient(195deg, #49a3f1, #1A73E8)"
    );
    document.documentElement.style.setProperty(
      "--sideNavColor",
      agency?.sideNavColor == "info"
        ? "linear-gradient(195deg, #49a3f1, #1A73E8)"
        : agency?.sideNavColor
    );
    document.documentElement.style.setProperty(
      "--sideNavText",
      agency?.Customization?.Sidnav?.sideNavText || "#fff"
    );
    document.documentElement.style.setProperty(
      "--ThemeBackground",
      agency?.Customization?.Body?.background
    );
    document.documentElement.style.setProperty(
      "--cardsBodyText",
      agency?.Customization?.Cards?.disTextColor
    );
    document.documentElement.style.setProperty(
      "--cardsBodyHeading",
      agency?.Customization?.Cards?.disHeadingColor
    );
    document.documentElement.style.setProperty(
      "--cardsFontFamily",
      agency?.Customization?.Cards?.FontFamily
    );
    document.documentElement.style.setProperty(
      "--sidnavFontFamily",
      agency?.Customization?.Sidnav?.FontFamily
    );
    document.documentElement.style.setProperty(
      "--cardsTitle",
      agency?.Customization?.Cards?.titleColor
    );
    document.documentElement.style.setProperty(
      "--cardsBodyColor",
      agency?.Customization?.Cards?.bodyColor
    );
    document.documentElement.style.setProperty(
      "--PurchaseButton",
      agency?.Customization?.Cards?.PurchaseButton
    );
    document.documentElement.style.setProperty(
      "--SnapshotButton",
      agency?.Customization?.Cards?.SnapshotButton
    );
    document.documentElement.style.setProperty(
      "--NavbarBg",
      agency?.Customization?.navbar?.background
    );
    setinitState({
      sideNavColor:
        agency?.sideNavColor == "info"
          ? "linear-gradient(195deg, #49a3f1, #1A73E8)"
          : agency?.sideNavColor,
      cardsboxshadow: agency?.Customization?.Cards?.boxShadow || "",
      CategriesDropdown:
        agency?.Customization?.FeaturesButton?.CategriesDropdown ||
        initState.CategriesDropdown,
      GridView:
        agency?.Customization?.FeaturesButton?.GridView || initState.GridView,
      Filter: agency?.Customization?.FeaturesButton?.Filter || initState.Filter,
      Categries:
        agency?.Customization?.navbar?.Categries || initState.Categries,
      navbarbg: agency?.Customization?.navbar?.background || initState.navbarbg,
      sidnavBg:
        agency?.sideNavType == "dark"
          ? "linear-gradient(195deg, #42424a, #191919)"
          : agency?.sideNavType,
      sideNavText:
        agency?.Customization?.Sidnav?.sideNavText || initState.sideNavText,
      sideNavCard:
        agency?.Customization?.Cards?.background || initState.sideNavCard,
      bodyColor: agency?.Customization?.Body?.background || initState.bodyColor,
      PurchaseButton:
        agency?.Customization?.Cards?.PurchaseButton ||
        initState.PurchaseButton,
      SnapshotButton:
        agency?.Customization?.Cards?.SnapshotButton ||
        initState.SnapshotButton,
      cardsBodyText:
        agency?.Customization?.Cards?.disTextColor || initState.cardsBodyText,
      cardsBodyHeading:
        agency?.Customization?.Cards?.disHeadingColor ||
        initState.cardsBodyHeading,
      cardsTitle:
        agency?.Customization?.Cards?.titleColor || initState.cardsTitle,
      cardsFontFamily:
        agency?.Customization?.Cards?.FontFamily || initState.cardsFontFamily,
      sidnavFontFamily:
        agency?.Customization?.Sidnav?.FontFamily || initState.sidnavFontFamily,
      cardsBodyColor:
        agency?.Customization?.Cards?.bodyColor || initState.cardsBodyColor,
    });
  };
  React.useEffect(async () => {
    setbutton(false);
    UpdateVaribales();
    const localbrand = await localforage.getItem("agency");
    const user = await localforage.getItem("user");
    setuser_data(user);
    var axios = require("axios");

    var localagency = await axios
      .get(BASE_URL + "/agency/" + localbrand._id)
      .then(async function (response) {
        console.log(response.data);
        setagency_data(response.data.data);
        var sideNavType = "linear-gradient(195deg, #42424a, #191919)";
        var sideNavColor = "linear-gradient(195deg, #49a3f1, #1A73E8)";
        setloader(false);
      })
      .catch(function (error) {
        setloader(false);
      });
    await axios
      .get(BASE_URL + "/snapshot/theme/")
      .then(async function (response) {
        console.log(response.data.data, "res");
        settheme_data(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    //    sidnavColorValue= getComputedStyle(document.documentElement).getPropertyValue('--sideNavType')
    //    cardValue= getComputedStyle(document.documentElement).getPropertyValue('--sideNavCard')
    // alert(getComputedStyle(document.documentElement).getPropertyValue('--sideNavType'))
  }, [refresh]);
  // const formik = useFormik({
  //   initialValues: { ...initState },
  //   // onSubmit: handleSubmit
  // })
  const handleTheme = (id) => {
    for (let index = 0; index < theme_data.length; index++) {
      const element = theme_data[index];
      if (element._id == id) {
        document.documentElement.style.setProperty(
          "--sidnavBg",
          element?.Sidnav?.sideNavType
        );
        document.documentElement.style.setProperty(
          "--sideNavCard",
          element.Cards?.background ||
            "linear-gradient(195deg, #49a3f1, #1A73E8)"
        );
        document.documentElement.style.setProperty(
          "--sideNavColor",
          element?.Sidnav?.sideNavColor
        );
        document.documentElement.style.setProperty(
          "--sideNavText",
          element?.Sidnav?.sideNavText || "#fff"
        );
        document.documentElement.style.setProperty(
          "--ThemeBackground",
          element?.Body?.background
        );
        document.documentElement.style.setProperty(
          "--cardsBodyText",
          element?.Cards?.disTextColor
        );
        document.documentElement.style.setProperty(
          "--cardsBodyHeading",
          element?.Cards?.disHeadingColor
        );
        document.documentElement.style.setProperty(
          "--cardsFontFamily",
          element?.Cards?.FontFamily
        );
        document.documentElement.style.setProperty(
          "--NavbarBg",
          agency?.Customization?.navbar?.background
        );
        document.documentElement.style.setProperty(
          "--sidnavFontFamily",
          element?.Sidnav?.FontFamily
        );
        document.documentElement.style.setProperty(
          "--cardsTitle",
          element?.Cards?.titleColor
        );
        document.documentElement.style.setProperty(
          "--cardsBodyColor",
          element?.Cards?.bodyColor
        );
        document.documentElement.style.setProperty(
          "--PurchaseButton",
          element?.Cards?.PurchaseButton
        );
        document.documentElement.style.setProperty(
          "--SnapshotButton",
          element?.Cards?.SnapshotButton
        );
        setinitState({
          sideNavColor: element?.Sidnav?.sideNavColor,
          cardsboxshadow: element.Cards?.boxShadow || "",
          CategriesDropdown:
            element.FeaturesButton?.CategriesDropdown ||
            initState.CategriesDropdown,
          navbarbg:
            agency?.Customization?.navbar?.background || initState.navbarbg,
          GridView: element.FeaturesButton?.GridView || initState.GridView,
          Filter: element.FeaturesButton?.Filter || initState.Filter,
          Categries: element?.navbar?.Categries || initState.Categries,
          sidnavBg: element?.Sidnav?.sideNavType,
          sideNavText: element?.Sidnav?.sideNavText || initState.sideNavText,
          sideNavCard: element.Cards?.background || initState.sideNavCard,
          bodyColor: element?.Body?.background || initState.bodyColor,
          PurchaseButton:
            element?.Cards?.PurchaseButton || initState.PurchaseButton,
          SnapshotButton:
            element?.Cards?.SnapshotButton || initState.SnapshotButton,
          cardsBodyText:
            element?.Cards?.disTextColor || initState.cardsBodyText,
          cardsBodyHeading:
            element?.Cards?.disHeadingColor || initState.cardsBodyHeading,
          cardsTitle: element?.Cards?.titleColor || initState.cardsTitle,
          cardsFontFamily:
            element?.Cards?.FontFamily || initState.cardsFontFamily,
          sidnavFontFamily:
            element?.Sidnav?.FontFamily || initState.sidnavFontFamily,
          cardsBodyColor: element?.Cards?.bodyColor || initState.cardsBodyColor,
        });
      }
    }
    setbutton(true);
  };
  const handleChange = ({ key, value }) => {
    console.log(key, value, "change");
    var setvalue = value;
    const primary = "linear-gradient(195deg, #EC407A, #D81B60)";
    const dark = "linear-gradient(195deg, #42424a, #191919)";
    const info = "linear-gradient(195deg, #49a3f1, #1A73E8)";
    const success = "linear-gradient(195deg, #66BB6A, #43A047)";
    const warning = "linear-gradient(195deg, #FFA726, #FB8C00)";
    const error = "linear-gradient(195deg, #EF5350, #E53935)";
    if (
      key == "sideNavColor" ||
      key == "sidnavBg" ||
      key == "ThemeBackground"
    ) {
      if (value == "primary") {
        setvalue = primary;
      } else if (value == "dark") {
        setvalue = dark;
      } else if (value == "info") {
        setvalue = info;
      } else if (value == "success") {
        setvalue = success;
      } else if (value == "warning") {
        setvalue = warning;
      } else if (value == "error") {
        setvalue = error;
      } else {
        setvalue = value;
      }
    } else {
      if (value == "primary") {
        setvalue = "#D81B60";
      } else if (value == "dark") {
        setvalue = "#191919";
      } else if (value == "info") {
        setvalue = "#1A73E8";
      } else if (value == "success") {
        setvalue = "#43A047";
      } else if (value == "warning") {
        setvalue = "#FB8C00";
      } else if (value == "error") {
        setvalue = "#E53935";
      } else {
        setvalue = value;
      }
    }
    setbutton(true);
    // alert('work')
    document.documentElement.style.setProperty(`--${key}`, setvalue);

    const intial_values = {
      CategriesDropdown: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: setvalue,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      sideNavColor: () => {
        setinitState({
          sideNavColor: setvalue,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      cardsboxshadow: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: setvalue,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      sidnavBg: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: setvalue,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      sideNavText: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: setvalue,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      sideNavCard: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: setvalue,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      Categries: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: setvalue,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      Filter: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: setvalue,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      GridView: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: setvalue,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      PurchaseButton: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: setvalue,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      SnapshotButton: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: setvalue,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      cardsBodyText: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: setvalue,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      cardsBodyHeading: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: setvalue,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      cardsTitle: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: setvalue,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      sidnavFontFamily: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: setvalue,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      cardsBodyColor: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: setvalue,
          navbarbg: initState.navbarbg,
        });
      },
      ThemeBackground: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: setvalue,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: initState.navbarbg,
        });
      },
      NavbarBg: () => {
        setinitState({
          sideNavColor: initState.sideNavColor,
          cardsboxshadow: initState.cardsboxshadow,
          CategriesDropdown: initState.CategriesDropdown,
          GridView: initState.GridView,
          Filter: initState.Filter,
          Categries: initState.Categries,
          sidnavBg: initState.sidnavBg,
          sideNavText: initState.sideNavText,
          sideNavCard: initState.sideNavCard,
          bodyColor: initState.bodyColor,
          PurchaseButton: initState.PurchaseButton,
          SnapshotButton: initState.SnapshotButton,
          cardsBodyText: initState.cardsBodyText,
          cardsBodyHeading: initState.cardsBodyHeading,
          cardsTitle: initState.cardsTitle,
          cardsFontFamily: initState.cardsFontFamily,
          sidnavFontFamily: initState.sidnavFontFamily,
          cardsBodyColor: initState.cardsBodyColor,
          navbarbg: setvalue,
        });
      },
    };
    intial_values[key]();
    console.log(initState, "change");

    // update({ ...formik.values })
  };
  const handleRightbar = (type, name) => {
    var names = name;
    if (type == "open") {
      console.log(name + type);
      document.documentElement.style.setProperty(
        "--rightsidebar-width",
        "400px"
      );
      const statedata = {
        sidnav: () => {
          sethandleOpen({ sidnav: true });
        },
        cards: () => {
          sethandleOpen({ cards: true });
        },

        navbar: () => {
          sethandleOpen({ navbar: true });
        },
        theme: () => {
          sethandleOpen({ theme: true });
        },
        body: () => {
          sethandleOpen({ body: true });
        },
      };
      statedata[names]();
    } else {
      document.documentElement.style.setProperty("--rightsidebar-width", "0px");
    }
  };
  const handleSubmit = async () => {
    setProcessing(true);
    var axios = require("axios");

    const localBrand = await localforage.getItem("agency");
    // const temp_data = {
    //     _id: localBrand._id,
    //     CategriesDropdown: categriesdropdown,
    //     Filter: filter,
    //     GridView: gridview,
    //     ...form
    // }
    const Sidnav = {
      TextColor: initState.sideNavText,
      FontFamily: initState.sidnavFontFamily,
      // borderRadius    : temp_data,
      //  boxShadow      : temp_data,
      // listItemBorderRadius         : temp_data,
    };
    const Cards = {
      background: initState.sideNavCard,
      boxShadow: initState.cardsboxshadow,
      FontFamily: initState.cardsFontFamily,
      bodyColor: initState.cardsBodyColor,
      titleColor: initState.cardsTitle,
      disHeadingColor: initState.cardsBodyHeading,
      disTextColor: initState.cardsBodyText,
      PurchaseButton: initState.PurchaseButton,
      SnapshotButton: initState.SnapshotButton,

      // boxShadow      : { type: String },
    };
    const Buttons = {
      // TextColor              : { type: String },
      // background              : { type: String },
      // borderRadius    : { type: String },
      // boxShadow      : { type: String },
    };
    const navbar = {
      Categries: initState.Categries,
      background: initState.navbarbg,
    };
    const Body = {
      background: initState.bodyColor,
    };
    const FeaturesButton = {
      GridView: initState.GridView,
      Filter: initState.Filter,
      CategriesDropdown: initState.CategriesDropdown,
    };
    var Customization = {};
    var sidenavcolor = "info";
    var sidenavtype = "dark";

    // if (defeult_settings == 'defeult') {
    //     sidenavcolor = "info"
    //     sidenavtype = "dark"
    //     Customization = {
    //         Sidnav: {},
    //         Cards: {},
    //         Buttons: {},
    //         FeaturesButton: {},
    //         navbar:{},
    //     }
    // } else {
    sidenavcolor = initState.sideNavColor;
    sidenavtype = initState.sidnavBg;
    Customization = {
      ghlTheme: ghlTheme,
      Sidnav: Sidnav,
      Cards: Cards,
      Buttons: Buttons,
      FeaturesButton: FeaturesButton,
      navbar: navbar,
      Body: Body,
    };
    // }
    const data = {
      _id: localBrand._id,
      Customization: Customization,
      sideNavColor: sidenavcolor,
      sideNavType: sidenavtype,
    };
const payload={
  data:data
}
    console.log(data, "data");
    const { response, error } = await AppService.agency.update_theme({ payload });
if(response){
  setProcessing(false);
  setbutton(false);

  localforage.setItem("agency", response.data);
  Update(response.data);

  setrefresh(!refresh);
}else{
  setProcessing(false);
  setbutton(false);
}
    // axios
    //   .post(BASE_URL + "/agency/update", { data })
    //   .then(async function (response) {
    //     console.log(response.data);
    //     setProcessing(false);
    //     setbutton(false);

    //     localforage.setItem("agency", response.data.data);
    //     Update(response.data.data);

    //     setrefresh(!refresh);
    //   })
    //   .catch(function (error) {
    //     setProcessing(false);
    //     setbutton(false);
    //   });
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : agency_data.type == "free" ? (
        <MainDashboardLayout>
          <iframe
            src={
              "https://snapshotstore.io/upgrade-theme?contact_email=" +
              user_data.email
            }
            style={iframeStyles}
          ></iframe>
        </MainDashboardLayout>
      ) : (
        <DashboardLayout handleRightbar={handleRightbar}>
          {initState.Categries == false ? (
            <>
              <MDBox>
                <SidenavRoot
                  variant="permanent"
                  ownerState={{
                    transparentSidenav,
                    whiteSidenav,
                    miniSidenav,
                    darkMode,
                  }}
                  ghlTheme={ghlTheme}
                  className="demo-sidnav"
                >
                  <BorderColorIcon
                    className="hover-icon-sidnav"
                    onClick={() => handleRightbar("open", "sidnav")}
                  />

                  <NavLink key={"category"} to={"#"}>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2.2rem",
                      }}
                    >
                      <ListItem
                        component="li"
                        mt={1}
                        sx={{
                          width: ghlTheme ? "100%" : "90%",
                          borderRadius: "0.375rem",
                          padding: "0.5rem 0.625rem",
                        }}
                        className="demo-sidnav-active"
                      >
                        <MDBox mt={1}>
                          <ListItemText
                            sx={{ marginLeft: "3.2rem" }}
                            primary={
                              <div
                                className="demo-sidnav-text"
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#fff",
                                  textAlign: "center",
                                }}
                              >
                                Everything
                              </div>
                            }
                          />
                        </MDBox>
                      </ListItem>
                    </MDBox>
                  </NavLink>
                  {initState.CategriesDropdown == false ? (
                    <>
                      <NavLink key={"category"} to={"#"}>
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "0.2rem",
                          }}
                        >
                          <ListItem
                            component="li"
                            mt={1}
                            sx={{
                              width: ghlTheme ? "100%" : "90%",
                              borderRadius: "0.375rem",
                              padding: "0.5rem 0.625rem",
                            }}
                            className="demo-sidnav"
                          >
                            <MDBox mt={1}>
                              <ListItemText
                                sx={{ marginLeft: "3.2rem" }}
                                primary={
                                  <div
                                    className="demo-sidnav-text"
                                    style={{
                                      fontSize: "0.9rem",
                                      fontWeight: "370",
                                      color: "#fff",
                                      textAlign: "center",
                                    }}
                                  >
                                    FB Adds
                                  </div>
                                }
                              />
                            </MDBox>
                          </ListItem>
                        </MDBox>
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <MDBox
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.3rem",
                        }}
                      >
                        <ListItemButton onClick={handleClick}>
                          <ListItemText
                            primary="Categories"
                            sx={{ marginLeft: "2.5rem" }}
                            className="demo-sidnav-text"
                            primaryTypographyProps={{ variant: "body2" }}
                          />
                          {openCollapse ? (
                            <ExpandLess
                              style={{
                                color: "white",
                                fontWeight: "200",
                                fontSize: "15px",
                              }}
                              className="demo-sidnav-text"
                            />
                          ) : (
                            <ExpandMore
                              style={{
                                color: "white",
                                fontWeight: "200",
                                fontSize: "15px",
                              }}
                              className="demo-sidnav-text"
                            />
                          )}
                        </ListItemButton>
                      </MDBox>

                      <Collapse
                        in={openCollapse}
                        timeout="auto"
                        unmountOnExit
                        style={{ width: "100%" }}
                      >
                        <NavLink key={"category"} to={"#"}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "0.2rem",
                            }}
                          >
                            <ListItem
                              component="li"
                              mt={1}
                              sx={{
                                width: "90%",
                                borderRadius: "0.375rem",
                                padding: "0.5rem 0.625rem",
                              }}
                              className="demo-sidnav"
                            >
                              <MDBox mt={1}>
                                <ListItemText
                                  sx={{ marginLeft: "3.2rem" }}
                                  primary={
                                    <div
                                      className="demo-sidnav-text"
                                      style={{
                                        fontSize: "0.9rem",
                                        fontWeight: "370",
                                        color: "#fff",
                                        textAlign: "center",
                                      }}
                                    >
                                      FB Adds
                                    </div>
                                  }
                                />
                              </MDBox>
                            </ListItem>
                          </MDBox>
                        </NavLink>
                      </Collapse>
                    </>
                  )}

                  <NavLink
                    key={"category"}
                    to={"#"}
                    style={{
                      marginTop: "auto",
                      width: "100%",
                      marginBottom: "15px",
                    }}
                  >
                    <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                      <ListItem
                        component="li"
                        mt={1}
                        sx={{
                          width: ghlTheme ? "100%" : "90%",
                          borderRadius: "0.375rem",
                          padding: "0.5rem 0.625rem",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="demo-sidnav-active"
                      >
                        <MDBox mt={1}>
                          <ListItemText
                            primary={
                              <div
                                className="demo-sidnav-text"
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#fff",
                                  textAlign: "center",
                                }}
                              >
                                Settings
                              </div>
                            }
                          />
                        </MDBox>
                      </ListItem>
                    </MDBox>
                  </NavLink>
                </SidenavRoot>
              </MDBox>
            </>
          ) : (
            <></>
          )}
          <MDBox
            sx={{ marginLeft: initState.Categries == false ? "270px" : "0px" }}
          >
            <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MDButton
                variant="gradient"
                color="info"
                sx={{ mt: 1, mb: 2 }}
                onClick={() => setghlTheme(!ghlTheme)}
              >
                {ghlTheme ? "use Custom Theme" : "use Ghl Theme"}
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                sx={{ mt: 1, mb: 2, ml: 2 }}
                onClick={() => handleRightbar("open", "theme")}
              >
                Select Theme
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                className={button ? "button" : ""}
                type="submit"
                sx={{ mt: 1, mb: 2, ml: 2 }}
                loading={processing}
                disabled={processing}
                onClick={handleSubmit}
              >
                Click to save
              </MDButton>
            </MDBox>
            <DashboardNavbar
              ghlTheme={ghlTheme}
              handleOpen={() => handleRightbar("open", "navbar")}
              initState={initState}
              handleChange={handleChange}
            />

            <Grid container>
              <Grid item xs={12} className="card-hover">
                <BorderColorIcon
                  className="hover-icon-card"
                  onClick={() => handleRightbar("open", "cards")}
                />
                <MDBox mb={3} className="demo-cards">
                  <ComplexStatisticsCard
                    color="info"
                    icon="weekend"
                    Category="test"
                  />
                </MDBox>
              </Grid>
            </Grid>
            <div className="right-sidebar ">
              <div className="toogle_btn">
                <CloseIcon
                  className="close-icon"
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleRightbar("close")}
                />
              </div>
              <MDBox sx={{ padding: 4 }}>
                {handleopen.cards == true ? (
                  <Card handleChange={handleChange} />
                ) : (
                  <></>
                )}
                {handleopen.body == true ? (
                  <Body handleChange={handleChange} />
                ) : (
                  <></>
                )}
                {handleopen.sidnav == true ? (
                  <Sidnav
                    handleChange={handleChange}
                    agency_data={agency_data}
                  />
                ) : (
                  <></>
                )}
                {handleopen.navbar == true ? (
                  <Navbar
                    handleChange={handleChange}
                    agency_data={agency_data}
                  />
                ) : (
                  <></>
                )}
                {handleopen.theme == true ? (
                  <>
                    {theme_data.map((item, key) => (
                      <MDBox
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0.7rem",
                          border: "1px solid #d3d3d3",
                          background: "ghostwhite",
                          cursor: "pointer",
                          marginBottom: 3,
                        }}
                        onClick={() => handleTheme(item._id)}
                      >
                        <MDBox>{item.name}</MDBox>
                        <MDBox>
                          <IconButton
                            sx={{
                              width: "24px",
                              height: "24px",
                              padding: 0,
                              background: item.Sidnav.sideNavType,
                              "&:hover, &:focus, &:active": {
                                background: item.Sidnav.sideNavType,
                              },
                            }}
                          />
                          <IconButton
                            sx={{
                              width: "24px",
                              marginLeft: 2,
                              height: "24px",
                              padding: 0,
                              background: item.Cards.background,
                              "&:hover, &:focus, &:active": {
                                background: item.Cards.background,
                              },
                            }}
                          />
                          <IconButton
                            sx={{
                              width: "24px",
                              marginLeft: 2,
                              height: "24px",
                              padding: 0,
                              background: item.Body.background,
                              "&:hover, &:focus, &:active": {
                                background: item.Body.background,
                              },
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </MDBox>
            </div>
          </MDBox>
        </DashboardLayout>
      )}
    </>
  );
};

export default Demo;

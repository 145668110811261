// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import env from "config";
// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import rank1 from "assets/ranks/rank1.jpeg";
import rank2 from "assets/ranks/rank2.jpeg";
import rank3 from "assets/ranks/rank3.jpeg";
import rank4 from "assets/ranks/rank4.jpeg";
import rank5 from "assets/ranks/rank5.jpeg";
import localforage from "localforage";
import React from "react";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
const Data = () => {
  const AppService = useAppServices();
  const location_settings = useAgencyInfo()[4];
  const [agency] = useAgencyInfo();
  const [categories, setcategories] = React.useState([]);
  const [category_name, setcategory_name] = React.useState([]);
  const [amount_spend, setamount_spend] = React.useState([]);
  var axios = require("axios");
  const BASE_URL = `${env.API_URL}/v1`;

  const getcategories = async (e) => {
    const { response } = await AppService.snapshots.getcategories({
      query: `id=${agency._id}`,
    });
    if (response) {
      var temp_categories = Object.entries(response.data)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      setcategory_name(Object.keys(temp_categories));
      setamount_spend(Object.values(temp_categories));
    } else {
    }
  };
  // var axios = require('axios');
  const onLoad = () => {
    getcategories();
  };
  React.useEffect(async () => {
    onLoad();
  }, []);
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "no", accessor: "no", width: "10%", align: "left" },
      { Header: "name", accessor: "name", width: "25%", align: "left" },
      {
        Header: "amount spent",
        accessor: "amount_spent",
        width: "10%",
        align: "left",
      },
    ],

    rows: [
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank1}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {category_name[0]}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {amount_spend[0] != undefined
              ? location_settings.currency_sign + amount_spend[0]
              : ""}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank2}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {category_name[1]}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {amount_spend[1] != undefined
              ? location_settings.currency_sign + amount_spend[1]
              : ""}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank3}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {category_name[2]}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {amount_spend[2] != undefined
              ? location_settings.currency_sign + amount_spend[2]
              : ""}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank4}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {category_name[3]}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {amount_spend[3] != undefined
              ? location_settings.currency_sign + amount_spend[3]
              : ""}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank5}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {category_name[4]}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {amount_spend[4] != undefined
              ? location_settings.currency_sign + amount_spend[4]
              : ""}
          </MDTypography>
        ),
      },
    ],
  };
};
export default Data;

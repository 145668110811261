
import MDButton from 'components/MDButton'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import { useFormik } from 'formik'
import MDInput from 'components/MDInput'
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import localforage from 'localforage'
import env from 'config'
import Multiselect from './inputs/Multiselect'
const CreateUsers = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [agency_id, setagency_id] = useState()
  const [users, setusers] = useState([])
  const BASE_URL = `${env.API_URL}/v1`
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  
  useEffect(async () => {
  var axios = require('axios');

    const localBrand = await localforage.getItem('user');
    setagency_id(localBrand.agency_id)
    axios.get(BASE_URL+'/snapshot/agency/stripe/customers/' + localBrand.agency_id).then((response) => {
      var newArray = response.data.data.data.filter(function (el)
      {
        return el.default_source !=null  ;
      }
      );
      console.log(newArray, 'newArraynewArraynewArray');
      var customer_temp=[]
      newArray.forEach(element => {
        console.log(element)
        customer_temp.push({
          label:element.name+' ('+element.email+')',
          value:element.id+'-'+element.name+'-'+element.email+'-'+element.default_source+"-"+element.invoice_settings.default_payment_method+"-"+element.phone,
        })
      });
      setusers(customer_temp)
  })
    .catch(function (error) {
      // console.log(error);
  
    });
  }, [])
  function AddProduct({ open, onClose ,data}) {

    const initState = {
      name: '',
    }
    const [processing, setProcessing] = useState(false)


    const handleSubmit = async (e) => {
      e.preventDefault();
    const localBrand = await localforage.getItem('user');

      if (e.target.user.value != '') {
        var axios = require('axios')

        setProcessing(true)
        const temp_data =JSON.parse( e.target.user.value);
        const name=temp_data.label.split('(')
        const info=temp_data.value.split('-')
        var default_payment_method=null
        if(info[4]==undefined){
          default_payment_method=null
        }else{
          default_payment_method=info[4]
        }
        const data = {
          name:name[0] ,
          customer_id:info[0] ,
          email:info[2] ,
          defeult_source:info[3] ,
          phone:info[5] ,
          default_payment_method:default_payment_method,
          password:e.target.password.value
        }
        console.log(data)
        axios.post(BASE_URL+'/auth/stripeuser/'+ localBrand.agency_id, { data }).then(async function (response) {
          console.log(response.data);
          props.handleChange(true)
          setOpenAddProduct(false)
          props.handlemessag('user create successfully','success')
        })
          .catch(function (error) {
            console.log(error);
          setOpenAddProduct(false)
          props.handlemessag('user not create successfully','false')


          });
        // const { response } = await AppService.stripe.addSubscription({ payload })

        // setProcessing(false)
        // onClose(response?.data)
      }else{
        alert('please fill the requried field!')

      }
    }

      const formik = useFormik({
        initialValues: { ...initState },
        onSubmit: handleSubmit,
      })
      return (
        <MDModal open={open} onClose={onClose}>
          <MDBox>
            <MDTypography component="h1" mb={3} variant="h5">
              Create User
            </MDTypography>

            <MDBox component="form" onSubmit={handleSubmit} role="form" sx={{ width: 335 }}>
            <Grid item md={12}>
            <MDBox mb={1} mt={2} sx={{ fontSize: '14px' }}>
            <MDTypography variant="button" display="block" gutterBottom>
                  Select Stripe Customer
                </MDTypography>
            <Multiselect data={data} name="user" selectType="single"/>

            </MDBox>
          </Grid>
          <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Password
                </MDTypography>
                <BootstrapInput type="password" defaultValue="" id="bootstrap-input" fullWidth placeholder="name" name='password' />
              </MDBox>
          </Grid>

              <MDBox>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  sx={{ mt: 4, mb: 1 }}
                  fullWidth
                >
                  Add
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDModal>
      )
       }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id) 
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton
        sx={{ marginLeft: 'auto' }}
        variant="outlined"
        color="info"
        startIcon={<AddBoxIcon />}
        onClick={() => setOpenAddProduct(true)}
      >
        Create User
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} data={users} />

    </>
  )
}

export default CreateUsers
import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid, Icon } from "@mui/material";
import localforage from "localforage";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  Card,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../../Users/style.css";
import Loader from "examples/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import env from "config";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "../components/DefaultProjectCard";
import { useAppServices } from "hook/services";
import Sidenav from "../MarketPlace/components/Sidenav";
import { useMaterialUIController, setMiniSidenav } from "context";
import { useLocation, useParams } from "react-router-dom";
// import ComplexStatisticsCard from "../components/ComplexStatisticsCard";
import { useAgencyInfo } from "context/agency";
import ComplexStatisticsCard from "./components/ComplexStatisticsCard";

function MarketPurchases() {
  const AppService = useAppServices();
  const [controller, dispatch] = useMaterialUIController();
  const [purchases, setpurchases] = useState([]);
  const [Users, setUsers] = useState([]);
  const [stripeData, setstripeData] = useState("");
  const [configapps, setconfigapps] = useState([]);
  const [formCategory, setformCategory] = useState([]);
  const params = useParams();
  const { pathname } = useLocation();

  const [processing, setProcessing] = useState(true);
  const [agency] = useAgencyInfo();
  const [main_data, setmain_data] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [Purchasedapps, setPurchasedapps] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [error, seterror] = useState("");

  const {
    miniSidenav,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    // darkMode,
  } = controller;
  const color = "info";
  const settings = [
    {
      type: "collapse",
      name: "Purchases",
      key: "purchases",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/agency/market/settings/purchases",
    },
    {
      type: "collapse",
      name: "Subscriptions",
      key: "subscription",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/agency/market/settings/subscription",
    },
  ];
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const epoch = moment.unix(1581292800);
  const BASE_URL = `${env.API_URL}/v1`;
  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };
  const getApps = async (e) => {
    const { response } = await AppService.app.get();
    var temp = [];
    if (response) {
      var newArray = response.data.filter(function (item) {
        return item.status.value != "comming soon";
      });
      for (let index = 0; index < newArray.length; index++) {
        const element = newArray[index];
        var price = element.basic.value.substr(
          0,
          element.basic.value.indexOf(" ")
        );
        temp.push({
          label: element.name,
          value: `${price} (${element.app_id}) ${element.name}`,
        });
      }
      setPurchasedapps(temp);
    }
  };
  const getPurchasedApp = async (apps_data) => {
    const { response } = await AppService.purchased_app.filter({
      query: `agency_id=${agency._id}`,
    });
    if (response) {
      setconfigapps(response.data);
    }
  };
  const getAgency_data = async () => {
    const { response } = await AppService.agency.getmain_agency({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "getAgency_data");
    if (response) {
      const catgories = response.data?.category;

      const snapshots = response.data?.sanpshots;
      var stripeProducts = [];
      if (
        response.data?.prices != "" &&
        response.data?.location_settings.currency != ""
      ) {
        var newArray = response.data?.prices.data.filter(function (item) {
          return (
            item.currency == response.data?.location_settings.currency &&
            item.type == "recurring"
          );
        });
        stripeProducts = newArray;
        console.log(
          newArray,
          "stripeProductsstripeProductsstripeProductsstripeProducts"
        );
      }
      const workflows = response.data?.workflows;
      var catgories_data = [];
      var snapshot_catgories = [];
      var service_catgories = [];
      var snapshots_data = [];
      var workflows_data = [];
      var stripeProducts_data = [];
      var custom_data = [];
      catgories.forEach((element) => {
        element.type.forEach((el) => {
          if (el.type != "service") {
            const temp = {
              label: el.name,
              value: el._id,
            };
            snapshot_catgories.push(element);
          }
        });
        if (element.type == "service" || element.type == "both") {
          const temp = {
            label: element.name,
            value: element._id,
          };
          service_catgories.push(temp);
        }
      });
      for (let index = 0; index < catgories.length; index++) {
        const element = catgories[index];
        const temp = {
          label: element.name,
          value: element._id,
        };
        catgories_data.push(temp);
      }
      for (let index = 0; index < stripeProducts.length; index++) {
        const element = stripeProducts[index];
        var temp_prices = {};
        if (element.type == "recurring") {
          temp_prices = {
            label:
              element.unit_amount / 100 +
              "(" +
              element.recurring.interval +
              ")-" +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            value:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            type: element.type,
            currency: element.currency,
          };
        } else {
          temp_prices = {
            label:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            value:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            type: element.type,
            currency: element.currency,
          };
        }

        stripeProducts_data.push(temp_prices);
      }

      for (let index = 0; index < snapshots.length; index++) {
        const element = snapshots[index];
        const temp = {
          label: element.name,
          value: element.id,
        };
        snapshots_data.push(temp);
      }

      for (let index = 0; index < workflows.workflows.length; index++) {
        const element = workflows.workflows[index];
        const temp = {
          label: element.name,
          value: element.id,
        };
        workflows_data.push(temp);
      }
      const data_d = {
        snapshots: snapshots_data,
        stripeProducts: stripeProducts_data,
        workflows: workflows_data,
        Categories: catgories_data,
        snapshot_catgories: snapshot_catgories,
        service_catgories: service_catgories,
      };
      console.log(data_d, "data_ddata_d");

      setmain_data(data_d);
      setProcessing(false);
    } else {
      const data_d = {
        snapshots: [],
        stripeProducts: [],
        workflows: [],
        Categories: [],
        snapshot_catgories: [],
        service_catgories: [],
      };
      setmain_data(data_d);
      setProcessing(false);
    }
  };

  const getCatgories = async () => {
    // alert('kjsh')
    const localBrand = await localforage.getItem("user");
    var axios = require("axios");
    const Category = await axios
      .get(BASE_URL + "/v1/snapshot/category/" + localBrand.agency_id)
      .then(async function (response) {
        console.log(response.data, "catefgory");
        setCategoryData(response.data.data);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(Category,'Category')
    // return {
    //   success: true,
    //   message: "true",
    //   data: Category.data,
    // };
  };

  const onLoad = async () => {
    console.log(agency?.agency_ghl);
    console.log(agency);
    setProcessing(true);
    if (agency?.ghl != undefined) {
      getPurchasedApp();
      await getApps();
      getAgency_data();
      getCatgories();
    } else {
      seterror("Ghl is not integrated correctly !");
      setProcessing(false);
    }
    // setrefresh(!refresh);
    // alert(refresh)
  };
  const time = moment.unix(epoch);

  var axios = require("axios");
  useEffect(async () => {
    onLoad();
  }, [refresh, pathname]);
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  return (
    <>
      <DashboardLayout>
        <Sidenav
          color={sidenavColor}
          // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          level={"settings"}
          settings={settings}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
        {processing ? (
          <Loader />
        ) : (
          <MDBox style={{ marginLeft: 250 }}>
            <Grid container mt={3}>
              <Grid item xs={12}>
                <MDBox mb={3}>
                  <ComplexStatisticsCard
                    color="info"
                    icon="weekend"
                    title="Purchases"
                    stripeData={stripeData}
                    formCategory={formCategory}
                    refresh={refresh}
                    configapps={configapps}
                    data={main_data}
                    Purchasedapps={Purchasedapps}
                    handleChange={onLoad}
                    handleCategoryChange={getCatgories}
                    handlemessag={""}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </DashboardLayout>
    </>
  );
}

export default MarketPurchases;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Tooltip from '@mui/material/Tooltip'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDAvatar from 'components/MDAvatar'
import Snapshot_Edit from 'layouts/agency/components/Snapshot_modal/Edit'
import env from 'config'
function DefaultProjectCard({ image, label, title, description, action, authors,button_label,button_url,handleChange ,data,item}) {
  const handledelete=(id)=>()=>{
    const BASE_URL = `${env.API_URL}/v1`

    // console.log)
    var axios = require('axios')

  axios.post(BASE_URL+'/snapshot/create_snapshot/delete/'+id).then(async function (response) {
      console.log(response.data);
      handleChange(true)
    })
      .catch(function (error) {
        console.log(error);
      });
  }
  const renderAuthors = authors.map(({ image: media, name }) => (
    
    <Tooltip key={name} title={name} placement="bottom">
      <MDAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border   : `${borderWidth[2]} solid ${white.main}`,
          cursor   : 'pointer',
          position : 'relative',
          ml       : -1.25,

          '&:hover, &:focus': {
            zIndex: '10',
          },
        })}
        
      />
    </Tooltip>
    
  ))

  return (
    <Card
      sx={{
        display         : 'flex',
        flexDirection   : 'column',
        backgroundColor : 'transparent',
        boxShadow       : 'none',
        overflow        : 'visible',
      }}
    >
      <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            maxWidth       : '100%',
            height         : '230px',
            margin         : 0,
            boxShadow      : ({ boxShadows: { md } }) => md,
            objectFit      : 'cover',
            objectPosition : 'center',
          }}
        />
      </MDBox>
      <MDBox mt={1} mx={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {label}
        </MDTypography>
        <MDBox mb={1}>
          {action.type === 'internal' ? (
            <MDTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          ) : (
            <MDTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mb={3} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <Snapshot_Edit data={data} item={item} handleChange={handleChange} />
        <MDButton onClick={handledelete(item._id)}
         variant="outlined"
         size="small"
         color='primary'
       >
         {button_label}
          
        </MDButton>
          
          <MDBox display="flex">{renderAuthors}</MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
}

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image       : PropTypes.string.isRequired,
  label       : PropTypes.string.isRequired,
  title       : PropTypes.string.isRequired,
  description : PropTypes.string.isRequired,
  action      : PropTypes.shape({
    type  : PropTypes.oneOf(['external', 'internal']),
    route : PropTypes.string.isRequired,
    color : PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'light',
      'dark',
      'white',
    ]).isRequired,
    button_label: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    item: PropTypes.array.isRequired,
    handleChange:PropTypes.func,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
  work_flow: PropTypes.array,
}

export default DefaultProjectCard

import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid } from "@mui/material";
import localforage from "localforage";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../Users/style.css";
import Loader from "examples/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import env from "config";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import TasksDetailsModal from "./Components/TasksDetailsModal";
import MDButton from "components/MDButton";

function Tasks() {
  const Service = useAppServices();
  const [agency] = useAgencyInfo();
  const [user, setuser] = useState([]);
  const [Conjo, setConjo] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [errormsg, seterrormsg] = useState("");
  const [snapshot, setsnapshot] = useState([]);
  const [snapshot_Select_data, setsnapshot_Select_data] = useState([]);
  const [locations_data, setlocations_data] = useState([]);
  const [charge_data, setcharge_data] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const location_settings = useAgencyInfo()[4];

  const [dateFilterChecked, setDateFilterChecked] = useState(true);

  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };

  const handleStatus = async (id) => {
    // setrefresh(!refresh);
    const payload = {
      _id: id,
      status: "completed",
    };
    console.log(payload);
    const { response } = await Service.Tasks.update({ payload });
    console.log(response);
    if (response) {
      onLoad();
    }
  };
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Location ID",
      options: {
        filter: false,
      },
    },
    {
      name: "Location Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Address",
      options: {
        filter: false,
      },
    },
    {
      name: "Created At",
      options: {
        filter: false,
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getPurchases = async () => {
    console.log(location_settings, "location_settingslocation_settings");
    const { response } = await Service.Tasks.filter({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "Tasksresponse");
    if (response) {
      var tabel_data = [];
      response.data?.forEach((element_p) => {
        const temp = [
          element_p.name,
          element_p.loc_id,
          element_p.loc_name,
          element_p.loc_add,
          element_p?.createdAt,
          element_p?.status,
          <div>
            <MDButton
              color="primary"
              onClick={() => handleStatus(element_p._id)}
              variant="gradient"
              fullWidth
            >
              Make Complete
            </MDButton>
            <a
              href={
                location_settings.ghl_domain
                  ? `https://${location_settings.ghl_domain}/accounts/detail/${element_p.loc_id}`
                  : `https://app.gohighlevel.com/accounts/detail/${element_p.loc_id}`
              }
              target="_blank"
            >
              <MDButton color="secondary" variant="gradient" fullWidth>
                View
              </MDButton>
            </a>
          </div>,
        ];
        if (element_p.status == "pending") {
          tabel_data.push(temp);
        }
      });
      setConjo(tabel_data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  const onLoad = () => {
    getPurchases();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <DashboardLayout>
          <DashboardNavbar
            type="tasks"
            charge_data={charge_data}
            handleChange={onLoad}
          />
          <Grid container>
            {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}
            {errormsg != "" ? (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{errormsg}</Alert>
              </Stack>
            ) : (
              <Grid item xs={12}>
                <MUIDataTable
                  title={"Purchases"}
                  data={Conjo}
                  columns={columns}
                  options={options}
                />
              </Grid>
            )}
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
}

export default Tasks;

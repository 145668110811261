import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import env from "config";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid } from "@mui/material";
import localforage from "localforage";
import Loader from "examples/Loader";
import ChargeCustomer from "./Components/ChargeCustomer";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "./style.css";

import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import UserDetailsModal from "./Components/UserDetailsModal";
function Users() {
  const [agency] = useAgencyInfo();
  const Service = useAppServices();
  const [Conjo, setConjo] = useState([]);
  const [users, setusers] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const [snapshotfalse, setsnapshotfalse] = useState("");
  const [snapshotsuccess, setsnapshotsuccess] = useState("");
  const location_settings = useAgencyInfo()[4];

  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const epoch = moment.unix(1581292800);
  const time = moment.unix(epoch);
  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };
  const columns = [
    {
      label: "name",
      name: "Title",
      options: {
        filter: false,
      },
    },

    {
      name: "email",
      options: {
        filter: false,
      },
    },
    {
      name: "phone",
      options: {
        filter: false,
      },
    },
    {
      name: "No of Locations ",
      options: {
        filter: false,
      },
    },
    {
      name: "No of Purchased Products ",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount Spent",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getUser = async () => {
    const { response } = await Service.user.getuserpurchases({
      query: `id=${agency._id}`,
    });
    if (response) {
      console.log(response, "userresponse");
      var tabel_data = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];

        const temp = [
          element.username,
          element.email,
          element.phone,
          element.purchased_locatons,
          element.products,
          location_settings.currency_sign + element.amount_spend,
          <UserDetailsModal data={element} />,
        ];
        tabel_data.push(temp);
      }
      setConjo(tabel_data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  const onLoad = () => {
    getUser();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  const handlemessage = (e, type) => {
    if (type == "false") {
      setsnapshotfalse(e);
    } else {
      setsnapshotsuccess(e);
    }
    setTimeout(() => {
      setsnapshotsuccess("");
      setsnapshotfalse("");
    }, 3000);
  };
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <DashboardLayout>
          <DashboardNavbar
            type="users"
            handleChange={handlerefresh}
            handlemessag={handlemessage}
          />

          {snapshotfalse != "" ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{snapshotfalse}</Alert>
            </Stack>
          ) : (
            <></>
          )}
          {snapshotsuccess != "" ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="success">{snapshotsuccess}</Alert>
            </Stack>
          ) : (
            <></>
          )}
          <Grid container mt={1}>
            {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}

            <Grid item xs={12}>
              <MUIDataTable
                title={"Users"}
                data={Conjo}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
}

export default Users;

import makeAnimated from "react-select/animated";
import Select from "react-select";
import React, { useRef, useState, useEffect } from "react";

const animatedComponents = makeAnimated();
const Multiselect = (props) => {
  const [data, setdata] = useState([]);
  const [values, setvalues] = useState(JSON.stringify(props.edit_data));

  const handlechange = (e) => {
    console.log(JSON.stringify(e));
    setvalues(JSON.stringify(e));
  };
  useEffect(async () => {
    console.log(props, "props");
  }, []);

  return (
    <>
      {props?.disabled ? (
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={props.edit_data}
          onChange={handlechange}
          isMulti
          isDisabled
          options={props.data}
        />
      ) : (
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={props.edit_data}
          onChange={handlechange}
          isMulti
          options={props.data}
        />
      )}

      <input type="hidden" value={values} name={props.name} />
    </>
  );
};
export default Multiselect;

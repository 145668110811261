/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import MDModal from "components/MDModal";
import { useState } from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import env from "config";
// import MDInput from 'components/MDInput'
// import { TextField } from '@mui/material'
import MDInput from "components/MDInput";
import { CircularProgress, Switch } from "@mui/material";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import { useUserInfo } from "context/user";
import { useFormik } from "formik";
import localforage from "localforage";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import React, { useEffect } from "react";
import StripeInput from "layouts/agency/setting/components/StripeInput";
import { useParams } from "react-router-dom";
import axios from "axios";

function AddPaymentMethod({
  open,
  onClose,
  handlemessage,
  handleRefresh,
  agency_stripe,
}) {
  const AppService = useAppServices();
  const [agency] = useAgencyInfo();
  const [user] = useUserInfo();
  const element = useElements();
  const stripe = useStripe();
  const params = useParams();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const BASE_URL = `${env.API_URL}/v1`;

  const initState = {
    name: "",
    make_default: false,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setProcessing(true);

    // var make_default = event.target.switch.checked;
    var name = event.target.name.value;
    // console.log(data)

    if (element == null) {
      return;
    }
    let paymentMethod = {};
    console.log(element.getElement(CardElement));
    const source = await stripe.createSource(element.getElement(CardElement));
    paymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card: element.getElement(CardElement),
    });
    console.log(paymentMethod, "paymentMethod");

    // 4848 7150 5203 7927

    // console.log(source);
    // console.log(error)
    // console.log(paymentMethod)
    const data = {
      name: name,
      email: user.email,
      phone: user.phone,
      make_default: true,
      paymentMethod: paymentMethod.paymentMethod,
      source_id: source.source.id,
      agency_id: agency._id,
      seller_agency_id: params.agency_id,
    };
    console.log(data);

    // return 0;
    if (paymentMethod.error) {
      // seterror(paymentMethod.error.message)
      // handleClose();
    } else {
      if (agency_stripe == "") {
        axios
          .post(BASE_URL + "/marketplace/card", { data })
          .then(async function (response) {
            console.log(response.data);
            // setmessage(response.data.message)
            // // seterror('')
            // alert('kjdfh')
            setProcessing(false);

            handlemessage(response.data.message, "success");
            handleRefresh();
            onClose();
            // handleClose();
          })
          .catch(function (error) {
            // handleClose();
            // alert('kjdfh')
            setProcessing(false);

            handlemessage(error.response.data.message, "error");
            onClose();
            console.log(error.response);
            // setmessage('')
            // seterror(error.response.data.message)
          });
      } else {
        axios
          .post(BASE_URL + "/marketplace/card/update/" + agency_stripe._id, {
            data,
          })
          .then(async function (response) {
            console.log(response.data);
            // setmessage(response.data.message)
            // seterror('')
            setProcessing(false);

            handlemessage(response.data.message, "success");
            handleRefresh();
            onClose();
            // handleClose();
          })
          .catch(function (error) {
            // handleClose();
            setProcessing(false);
            handlemessage(error.response.data.message, "error");
            onClose();
            console.log(error.response);
            // setmessage('')
            // seterror(error.response.data.message)
          });
      }
    }
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
  });

  return (
    <MDModal open={open} onClose={onClose} width={500} height={500}>
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDInput
          fullWidth
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardElement,
            },
          }}
        />
        <MDBox mt={2}>
          <MDInput
            label="Name"
            name="name"
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
            helperText={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ""
            }
            success={formik.touched.name && !formik.errors.name}
            fullWidth
          />
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          lineHeight={1}
        >
          <MDTypography variant="h6">Make Primary</MDTypography>

          <Switch
            name="make_default"
            checked={formik.values.make_default}
            onChange={() =>
              formik.setValues({
                ...formik.values,
                make_default: !formik.values.make_default,
              })
            }
          />
        </MDBox>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          loading={processing}
          disabled={processing}
          sx={{ mt: 4, mb: 1 }}
          fullWidth
        >
          Submit
        </MDButton>
        <MDTypography variant="button" color="error">
          {error}
        </MDTypography>
      </MDBox>
    </MDModal>
  );
}

function RemoveCardConfirmation({
  open,
  onClose,
  paymentMethod,
  updateCardList,
}) {
  const AppService = useAppServices();

  const [processing, setProcessing] = useState(false);

  const removeCard = async () => {
    setProcessing(true);
    const { response } = await AppService.agency.payment_method_remove({
      toaster: true,
      payload: { payment_method_id: paymentMethod.id },
    });
    setProcessing(false);
    response && updateCardList();
  };

  return (
    <MDModal open={open} onClose={onClose}>
      <MDBox>
        <MDTypography>
          Are you sure you want to delete this payment method
        </MDTypography>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDButton
            variant="gradient"
            color="info"
            loading={processing}
            disabled={processing}
            sx={{ mt: 4, mb: 1, mr: 2 }}
            onClick={removeCard}
            fullWidth
          >
            Yes
          </MDButton>
          <MDButton
            variant="outlined"
            color="info"
            sx={{ mt: 4, mb: 1, ml: 2 }}
            onClick={onClose}
            fullWidth
          >
            Cancel
          </MDButton>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}

function PaymentMethod({
  paymentMethodState,
  stripe_key,
  customerState,
  agency_stripe,
  handleChange,
  handlemessage,
  handleRefresh,
}) {
  const { paymentMethods, setPaymentMethods } = paymentMethodState;
  const { customer, setCustomer } = customerState;

  const [agency] = useAgencyInfo();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const params = useParams();
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [removeCard, setRemoveCard] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refresh, setrefresh] = useState(false);
  // const [agency_stripe, setagency_stripe] = useState('')
  // const [stripe_key, setstripe_key] = useState("");
  const stripePromise = loadStripe(stripe_key);

  const AppService = useAppServices();
  // const getStripe = async (e) => {
  //   const { response } = await AppService.agency.get({
  //     query: `_id=${params.agency_id}`,
  //   });
  //   console.log(response.data, "publish_key");
  //   if (response) {
  //     setstripe_key(response.data.stripe.publish_key);
  //     console.log(response.data.stripe.publish_key);
  //   }
  // };
  useEffect(async () => {
    // if (params.agency_id) {
    //   getStripe();
    // }
  }, [refresh]);
  const getPaymentMethods = async () => {
    const { response } = await AppService.agency.get_payment_methods({
      query: `customer_id=${agency.stripe?.customer_id}`,
    });
    if (response) {
      setPaymentMethods(response.data);
    }
  };

  const handleClose = () => {
    setAddPaymentMethod(false);
    handleRefresh();
    getPaymentMethods();
  };

  const updateCardList = (idx) => {
    paymentMethods.splice(idx, 1);
    setPaymentMethods([...paymentMethods]);
    setRemoveCard(false);
  };

  const MakePrimary = async (paymentMethod) => {
    setProcessing(true);
    const payload = {
      customer_id: agency.stripe?.customer_id,
      payment_method_id: paymentMethod.id,
    };

    const { response } = await AppService.agency.update_payment_methods({
      toaster: true,
      payload,
    });

    setProcessing(false);

    setCustomer(response.data);
  };

  return (
    <>
      {agency_stripe == "" ? (
        <MDButton
          onClick={() => setAddPaymentMethod(true)}
          variant="gradient"
          color="dark"
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;add new card
        </MDButton>
      ) : (
        <MDButton
          onClick={() => setAddPaymentMethod(true)}
          variant="gradient"
          color="success"
        >
          &nbsp;update your card
        </MDButton>
      )}
      <Elements stripe={stripePromise}>
        <AddPaymentMethod
          agency_stripe={agency_stripe}
          open={addPaymentMethod}
          onClose={handleClose}
          handlemessage={handlemessage}
          handleRefresh={handleChange}
        />
      </Elements>
    </>
  );
}

export default PaymentMethod;

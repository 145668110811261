import Item from "antd/lib/list/Item";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
const Data = () => {
  const [agency] = useAgencyInfo();
  const Service = useAppServices();
  const [data, setdata] = useState([]);
  const [processing, setprocessing] = useState(true);
  const [error, seterror] = useState("");

  const getmain_agency = async () => {
    const { response } = await Service.agency.getmain_agency({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "response");
    if (response) {
      const catgories = response.data?.category;

      const snapshots = response.data?.sanpshots;
      var stripeProducts = [];
      if (response.data?.prices != "") {
        stripeProducts = response.data?.prices.data;
      }
      console.log(stripeProducts, "stripeProducts");
      const workflows = response.data?.workflows;
      var catgories_data = [];
      var snapshot_catgories = [];
      var service_catgories = [];
      var snapshots_data = [];
      var workflows_data = [];
      var stripeProducts_data = [];
      var custom_data = [];
      catgories.forEach((element) => {
        element.type.forEach((el) => {
          if (el.type != "service") {
            const temp = {
              label: el.name,
              value: el._id,
            };
            snapshot_catgories.push(element);
          }
        });
        if (element.type == "service" || element.type == "both") {
          const temp = {
            label: element.name,
            value: element._id,
          };
          service_catgories.push(temp);
        }
      });
      for (let index = 0; index < catgories.length; index++) {
        const element = catgories[index];
        const temp = {
          label: element.name,
          value: element._id,
        };
        catgories_data.push(temp);
      }
      for (let index = 0; index < stripeProducts.length; index++) {
        const element = stripeProducts[index];
        var temp_prices = {};
        if (
          element.type == "recurring" &&
          element.currency == response.data?.location_settings.currency
        ) {
          temp_prices = {
            label:
              element.unit_amount / 100 +
              "(" +
              element.recurring.interval +
              ")-" +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            value:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            type: element.type,
            currency: element.currency,
          };
        }

        stripeProducts_data.push(temp_prices);
      }

      for (let index = 0; index < snapshots.length; index++) {
        const element = snapshots[index];
        const temp = {
          label: element.name,
          value: element.id,
        };
        snapshots_data.push(temp);
      }

      for (let index = 0; index < workflows.workflows.length; index++) {
        const element = workflows.workflows[index];
        const temp = {
          label: element.name,
          value: element.id,
        };
        workflows_data.push(temp);
      }
      const data_d = {
        snapshots: snapshots_data,
        stripeProducts: stripeProducts_data,
        workflows: workflows_data,
        Categories: catgories_data,
        snapshot_catgories: snapshot_catgories,
        service_catgories: service_catgories,
      };
      console.log(data_d, "data_ddata_d");

      setdata(data_d);
      setprocessing(false);
    } else {
      const data_d = {
        snapshots: [],
        stripeProducts: [],
        workflows: [],
        Categories: [],
        snapshot_catgories: [],
        service_catgories: [],
      };
      setdata(data_d);
      setprocessing(false);
    }
  };
  const onLoad = () => {
    if (agency?.agency_ghl != undefined && agency?.ghl != undefined) {
      getmain_agency();
    } else {
      seterror("Ghl is not integrated correctly !");
      setprocessing(false);
    }
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return {
    data: data,
    error: error,
    loader: processing,
    handleRefreshData: { onLoad },
  };
};
export default Data;

import { Card, Divider, Grid, IconButton, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Header from "./components/Header";
import DefulatSettings from "./components/DefulatSettings";
import Integration from "./components/Integration";
import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Billing from "./components/Billing";
import Profile from "./components/Profile";
import Card_details from "./components/Card_details/Card_details";
import Feature_Request from "./components/Feature_Request";
function Settings() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container mt={10}>
        <Grid item xs={12}>
          <Header elements={[<Integration />, <Card_details />, <Profile />]} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Settings;

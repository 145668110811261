import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid, Icon } from "@mui/material";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../../Users/style.css";
import Loader from "examples/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import env from "config";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import { RemoveRedEye } from "@material-ui/icons";
import { useMaterialUIController, setMiniSidenav } from "context";
import Sidenav from "../MarketPlace/components/Sidenav";
import MDBox from "components/MDBox";

function Default() {
  const [agency] = useAgencyInfo();
  const [controller, dispatch] = useMaterialUIController();
  const Service = useAppServices();
  const [locations, setlocations] = useState([]);
  const [Conjo, setConjo] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [ghl_locations, setghl_locations] = useState([]);
  const location_settings = useAgencyInfo()[4];
  const [errormsg, seterrormsg] = useState("");
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const {
    miniSidenav,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    // darkMode,
  } = controller;
  const color = "info";
  const settings = [
    {
      type: "collapse",
      name: "Purchases",
      key: "purchases",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/agency/market/settings/purchases",
    },
    {
      type: "collapse",
      name: "Subscriptions",
      key: "subscription",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/agency/market/settings/subscription",
    },
  ];
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Subscription Id",
      options: {
        filter: false,
      },
    },
    {
      name: "Type",
      options: {
        filter: false,
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
      },
    },
    // {
    //   name: "Action",
    //   options: {
    //     filter: false,
    //   },
    // },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  var axios = require("axios");
  const getSubscriptions = async () => {
    const { response } = await Service.bundles.getsubscriptions({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "getSubscriptions");
    if (response) {
      var tabel_data = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        if (element?.purchase_data[0]?.management_sub_data) {
          const temp_data = [
            element?._id?.name,
            element.purchase_data[0]?.management_sub_data?.sub_id,
            element.purchase_data[0]?.type,
            element.purchase_data[0]?.status,
            // location_settings.currency_sign + element.total_amount,
            //   <LocationDetailsModal data={element} />,
          ];
          tabel_data.push(temp_data);
        }
      }
      setConjo(tabel_data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  const onLoad = () => {
    getSubscriptions();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      <DashboardLayout>
        <Sidenav
          color={sidenavColor}
          // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          level={"settings"}
          settings={settings}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
        {processing ? (
          <Loader />
        ) : (
          <MDBox style={{ marginLeft: 250 }}>
            <Grid container>
              {errormsg != "" ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errormsg}</Alert>
                </Stack>
              ) : (
                <Grid item xs={12}>
                  <MUIDataTable
                    title={"Subscriptions"}
                    data={Conjo}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              )}
              {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}
            </Grid>
          </MDBox>
        )}
      </DashboardLayout>
    </>
  );
}

export default Default;

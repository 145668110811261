import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid } from "@mui/material";
import { Card } from "@material-ui/core";
import moment from "moment";
import "../Users/style.css";
import Loader from "examples/Loader";
import { useEffect, useState } from "react";
import env from "config";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "./components/DefaultProjectCard";
import { useAppServices } from "hook/services";
import RequestModal from "./components/RequestModal";
import { useAgencyInfo } from "context/agency";

function Purchases() {
  const location_settings = useAgencyInfo()[4];
  const [agency] = useAgencyInfo();
  const AppService = useAppServices();
  const [Prices, setPrices] = useState([]);
  const [Apps, setApps] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [snapshotcategories, setsnapshotcategories] = useState([]);
  const [purchased_app, setpurchased_app] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [processing, setprocessing] = useState(true);
  const color = "info";

  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const epoch = moment.unix(1581292800);
  const BASE_URL = `${env.API_URL}/v1`;
  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };
  const getApps = async (e) => {
    const { response } = await AppService.app.get();
    if (response) {
      getConfigureApps(response.data);
    } else {
      setApps([]);
    }
  };
  const getConfigureApps = async (apps) => {
    const { response } = await AppService.purchased_app.filter({
      query: `agency_id=${agency._id}`,
    });
    var temp_apps = apps;
    if (response) {
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        var newArray = temp_apps.filter(function (item) {
          return element.app_id == item.app_id;
        });
        const indexToRemove = temp_apps.findIndex(
          (item) => element.app_id === item.app_id
        );
        if (indexToRemove !== -1) {
          temp_apps.splice(indexToRemove, 1);
        }
        if (newArray[0]) {
          temp_apps.push({
            ...newArray[0],
            price_id: element.price_id,
            configure: true,
            selling_price: element.selling_price || null,
          });
        } else {
          temp_apps.push({
            ...element,
            price_id: element.price_id,
            configure: false,
            selling_price: element.selling_price || null,
          });
        }
      }
      setApps(temp_apps);
    } else {
      setApps(temp_apps);
    }
  };
  const getCategories = async (e) => {
    const { response } = await AppService.categories.get({
      query: "created_by=superadmin",
    });
    if (response) {
      setCategories(response.data);
    } else {
      setCategories([]);
    }
  };
  const getPrices = async () => {
    const { response } = await AppService.agency.getprices({
      query: `_id=${agency._id}`,
      toaster: false,
    });
    if (response) {
      var newArray = response.data.data.filter(function (item) {
        return item.active == true;
      });
      const temp = [];
      newArray.forEach((product) => {
        temp.push({
          label:
            (product.unit_amount / 100).toFixed(2) +
            (product.nickname ? " ( " + product.nickname + " ) " : "") +
            " - " +
            product.id,
          value:
            (product.unit_amount / 100).toFixed(2) +
            (product.nickname ? " ( " + product.nickname + " ) " : "") +
            " - " +
            product.id,
        });
      });
      setPrices(temp);
    }
    setprocessing(false);
  };
  const getPurchased_app = async (e) => {
    const { response } = await AppService.purchased_app.filter({
      query: `agency_id=${agency._id}`,
    });
    if (response) {
      setpurchased_app(response.data);
    } else {
      setpurchased_app([]);
    }
  };
  const getSnapshotsCategory = async (e) => {
    const BASE_URL = `${env.API_URL}/v1`;
    axios
      .get(BASE_URL + "/snapshot/category/" + agency._id)
      .then((response) => {
        const temp = [];
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index];
          temp.push({
            label: element.name,
            value: element._id,
          });
        }
        setsnapshotcategories(temp);
      })
      .catch(function (error) {});
  };
  const onLoad = () => {
    getPurchased_app();
    getSnapshotsCategory();
    getApps();
    getCategories();
    getPrices();
  };
  const time = moment.unix(epoch);

  var axios = require("axios");
  useEffect(async () => {
    onLoad();
  }, [refresh]);
  return (
    <>
      <DashboardLayout>
        {processing ? (
          <Loader />
        ) : (
          <MDBox mt={3}>
            <MDBox mb={3} display="flex" justifyContent="flex-end">
              <RequestModal />
            </MDBox>
            {Categories.map((category, key) => (
              <Card className="apps-card" style={{ marginBottom: "50px" }}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  pt={1}
                  px={2}
                >
                  <MDBox
                    variant="gradient"
                    bgColor={color}
                    color={color === "light" ? "dark" : "white"}
                    coloredShadow={color}
                    borderRadius="xl"
                    display="flex"
                    alignItems="center"
                    width="100%"
                    height="4rem"
                    mt={-3}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <MDTypography
                          variant="button"
                          ml={3}
                          display="flex"
                          justifyContent="start"
                          fontWeight="bold"
                          color="white"
                        >
                          {category.name}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography
                          variant="h4"
                          mr={5}
                          display="flex"
                          justifyContent="end"
                          fontWeight="bold"
                          color="white"
                        ></MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
                <MDBox pb={2} mt={5} px={2}>
                  <Grid container spacing={4}>
                    {Apps.map((item, key) =>
                      item?.categories?.findIndex(
                        (e) => e.value === category._id
                      ) > -1 ? (
                        <Grid item xs={12} md={3}>
                          <MDBox mb={3}>
                            <DefaultProjectCard
                              Prices={Prices}
                              image={item.image}
                              purchased_app={purchased_app}
                              snapshotcategories={snapshotcategories}
                              title={item.name}
                              handleChange={"getSnapshots"}
                              handlemessag={"handlemessag"}
                              handleRefresh={onLoad}
                              item={item}
                              data={"data"}
                              description={
                                (item?.status.value == "comming soon" ? (
                                  <>
                                    <div>
                                      <b>Estimate :</b> {item.timeline.label}
                                    </div>
                                    <div>
                                      <b>Cost Price:</b>{" "}
                                      {"$" +
                                        parseFloat(
                                          item.basic.value.substr(
                                            0,
                                            item.basic.value.indexOf(" ")
                                          )
                                        )}
                                    </div>
                                    <div>
                                      <b>Selling Price:</b>{" "}
                                      {location_settings.currency_sign +
                                        parseFloat(
                                          item.basic.value.substr(
                                            0,
                                            item.basic.value.indexOf(" ")
                                          )
                                        ) *
                                          3}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <b>Description:</b> {item.description}
                                    </div>
                                    {item.configure ? (
                                      <div>
                                        <b>Selling Price:</b>
                                        {location_settings.currency_sign}
                                        {item.selling_price.value.substr(
                                          0,
                                          item.selling_price.value.indexOf(" ")
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        <b>Default Scale:</b> {"3x"}
                                      </div>
                                    )}

                                    <div>
                                      {/* <b>Price:</b> {item.payable=="no"?'Free':'$'+item.purchase_listener.substr(0, item.purchase_listener.indexOf('('))} */}
                                    </div>
                                  </>
                                )) ||
                                "As Uber works through a huge amount of internal management turmoil."
                              }
                              action={{
                                type: "internal",
                                route: `/agency/App/${item._id}/AppDetail`,
                                color: "info",
                                label: "Preview",
                              }}
                              button_label="Delete"
                            />
                          </MDBox>
                        </Grid>
                      ) : (
                        <></>
                      )
                    )}
                  </Grid>
                </MDBox>
              </Card>
            ))}
          </MDBox>
        )}
      </DashboardLayout>
    </>
  );
}

export default Purchases;

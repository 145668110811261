import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Singleselect from "../../Single_Selecte";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";

const Payable = (props) => {
  const [processpayable, setprocesspayable] = useState(false);

  return (
    <>
      <Grid item md={12}>
        <MDBox mb={1}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <MDTypography
                variant="h6"
                sx={{ fontSize: "17px", fontWeight: "400" }}
                display="block"
                gutterBottom
              >
                {props.label}
              </MDTypography>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name={props.payablename}
              sx={{ marginTop: "-6px" }}
            >
              <FormControlLabel
                value="yes"
                onClick={() => setprocesspayable(true)}
                control={<Radio />}
                label="Yes"
              />
              {processpayable == false ? (
                <FormControlLabel
                  value="no"
                  onClick={() => setprocesspayable(false)}
                  control={<Radio />}
                  checked
                  label="No"
                />
              ) : (
                <FormControlLabel
                  value="no"
                  onClick={() => setprocesspayable(false)}
                  control={<Radio />}
                  label="No"
                />
              )}
            </RadioGroup>
          </FormControl>
        </MDBox>
      </Grid>
      {processpayable == true ? (
        <Grid item md={12}>
          <MDTypography mb={1} variant="button" display="block" gutterBottom>
            How much is for this?
          </MDTypography>
          <MDBox mb={1}>
            <input
              defaultValue={props.edit_data}
              type="number"
              name={props.name}
              className="form-control"
              step="0.01"
              style={{
                width: "97%",
                height: "40px",
              }}
            />
          </MDBox>
        </Grid>
      ) : (
        <input type="hidden" name={props.name} />
      )}
    </>
  );
};

export default Payable;

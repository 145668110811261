import makeAnimated from 'react-select/animated';
import React, { useRef, useState, useEffect } from 'react'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const animatedComponents = makeAnimated();

const TypeModal = (props) => {
    const [value, setvalue] = useState(props.type)
    const handleChange = (event) => {
      setvalue(event.target.value);
      console.log(event.target.value)
    };

  useEffect(async () => {
    console.log(props.data, 'working');
  }, [])

  return (
                
    <FormControl sx={{ m: 0, minWidth: '100%' }}>
    <InputLabel id="demo-simple-select-autowidth-label">{'type'}</InputLabel>
    <Select
      labelId="demo-simple-select-autowidth-label"
      id="demo-simple-select-autowidth"
      autoWidth
      value={value}
      onChange={handleChange}
      name={'type'}
      label="type"
    >
        <MenuItem value={'snapshot'} key="">{'snapshot'}</MenuItem>
        <MenuItem value={'service'} key="">{'service'}</MenuItem>
        <MenuItem value={'both'} key="">{'both'}</MenuItem>
    </Select>
  </FormControl>

  )
}

export default TypeModal

import makeAnimated from "react-select/animated";
import React, { useRef, useState, useEffect } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
const animatedComponents = makeAnimated();

const Singleselect = (props) => {
  const [value, setvalue] = useState(props.edit_data);
  const handleChange = (event) => {
    setvalue(event.target.value);
    console.log(event.target.value);
  };

  useEffect(async () => {
    console.log(props.data, "working");
  }, []);

  return (
    <FormControl sx={{ m: 0, minWidth: "100%" }}>
      <InputLabel id="demo-simple-select-autowidth-label">
        {props.label}
      </InputLabel>
      <Select
        disabled={props.disabled || false}
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        autoWidth
        value={value}
        name={props.name}
        onChange={handleChange}
        label="workflows"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {props.data.map((data) => (
          <MenuItem value={data.id} key="">
            {data.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Singleselect;

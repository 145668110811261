import FormField from "components/FormField";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// import Multiselect from "./components/Multiselect";
import { useAppServices, useUploadImage } from "hook/services";
import { Button, Grid, Select, Stack, TextareaAutosize } from "@mui/material";
import MDInput from "components/MDInput";
import { CheckBox, FormatLineSpacingRounded } from "@mui/icons-material";
import Multiselect from "./components/Multiselect";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAgencyInfo } from "context/agency";
import Loader from "examples/Loader";
import axios from "axios";
import env from "config";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
function ChargeModal({
  open,
  onClose,
  update,
  selectedoffer,
  purchased_app,
  handleRefresh,
}) {
  const AppService = useAppServices();
  const [agency] = useAgencyInfo();
  const stripe = useStripe();
  const location_settings = useAgencyInfo()[4];
  const handleClose = async (confirm) => {
    if (confirm) {
      const payload = {
        _id: purchased_app?._id,
        update: update,
        agency_id: agency._id,
        ...selectedoffer,
      };
      const { response } = await AppService.purchased_app.create({ payload });
      if (response) {
        if (response.status == "completed") {
          handleRefresh();
          onClose({ response: true });
        } else {
          stripe
            .confirmCardPayment(response.data.client_secret, {})
            .then(async function (result) {
              if (result.paymentIntent.status == "succeeded") {
                const payload = {
                  _id: purchased_app?._id,
                  ...selectedoffer,
                  status: "succeeded",
                };
                const { response } = await AppService.purchased_app.update({
                  payload,
                });
                if (response) {
                  onClose({ response: true });
                  handleRefresh();
                }
              } else {
                onClose({ response: true });
                handleRefresh();
              }
              // response
              //   {
              //     "paymentIntent": {
              //         "id": "pi_3NgD2IIFQJPuAPur04syzDjW",
              //         "object": "payment_intent",
              //         "amount": 100,
              //         "amount_details": {
              //             "tip": {}
              //         },
              //         "automatic_payment_methods": null,
              //         "canceled_at": null,
              //         "cancellation_reason": null,
              //         "capture_method": "automatic",
              //         "client_secret": "pi_3NgD2IIFQJPuAPur04syzDjW_secret_Rqn0VM8J2MNkaLhJN5bK3IiqG",
              //         "confirmation_method": "automatic",
              //         "created": 1692304998,
              //         "currency": "gbp",
              //         "description": "Subscription creation",
              //         "last_payment_error": null,
              //         "livemode": true,
              //         "next_action": null,
              //         "payment_method": "pm_1NgBqmIFQJPuAPure9V3ySeO",
              //         "payment_method_types": [
              //             "card"
              //         ],
              //         "processing": null,
              //         "receipt_email": "jhbjhjgbjh@gmail.com",
              //         "setup_future_usage": "off_session",
              //         "shipping": null,
              //         "source": null,
              //         "status": "succeeded"
              //     }
              // }
              if (result.error) {
              } else {
                onClose({ response: true });
                handleRefresh();
              }
            });
        }
      }
    } else {
      onClose({ response: false });
      handleRefresh();
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textFormat={"capitalized"}>
          Unlimited {selectedoffer.type} Plan
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Enjoy effortless app reselling with our Monthly Subscription Plan!
            For just ${selectedoffer.price} a month, users gain exclusive access
            to resell our premium apps without any additional per-sale charges.
            Maximize your profits while we handle the rest. It's that simple!
            Join us today and elevate your reselling game
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Disagree</Button>
          <Button onClick={() => handleClose(true)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
ChargeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  update: PropTypes.bool.isRequired,
  selectedoffer: PropTypes.object,
  purchased_app: PropTypes.object,
};
function ModalComponent({
  open,
  onClose,
  Prices,
  offer,
  handleRefresh,
  purchased_app_data,
  snapshotcategories,
}) {
  const AppService = useAppServices();
  const uploadImage = useUploadImage();
  const [agency] = useAgencyInfo();
  const stripePromise = loadStripe(
    "pk_live_51Lyvl5IFQJPuAPurroNLnypochHdFrbCoy73ual8nIuGF3RtwlgGHUiPZDubxc4FpC7gvb3LT0XmmIDAT4gNCiHY00vn2KVEQw"
  );
  const location_settings = useAgencyInfo()[4];

  const [purchase_data, setpurchase_data] = useState({});
  const [purchased_app, setpurchased_app] = useState("");
  const [processing, setprocessing] = useState(false);
  const [loader, setloader] = useState(true);
  const [openDialog, setopenDialog] = useState(false);
  const [selected_plan, setselected_plan] = useState("basic");
  const [selectedoffer, setselectedoffer] = useState({
    price_id: offer.basic.value,
    app_id: offer.app_id,
    type: "basic",
  });
  const [plan, setplan] = useState("basic");
  const [price_id, setprice_id] = useState("");
  const getPurchased_app = async (e) => {
    const filteredapp = purchased_app_data.filter(
      (app) => app.app_id == offer.app_id
    );
    if (filteredapp[0]) {
      setpurchased_app(filteredapp[0]);
      setselected_plan(filteredapp[0]?.type);
      setpurchase_data(filteredapp[0]);
    }
    setloader(false);
  };
  const onLoad = () => {
    setloader(true);
    getPurchased_app();
  };
  useEffect(async () => {
    onLoad();
  }, [purchased_app_data]);
  const handleDialog = ({ type, price_id, open, response, price }) => {
    setplan(type);
    setselectedoffer({
      price_id: price_id,
      price: price,
      app_id: offer.app_id,
      type: type,
    });
    if (response) {
      setselected_plan(plan);
    }
    setopenDialog(open);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setprocessing(true);
    if (e.target.selling_price.value == "") {
      const selling_price =
        parseInt(
          selectedoffer.price_id?.substr(0, selectedoffer.price_id.indexOf(" "))
        ) * 3;
      var payload = {
        agency_id: agency._id,
        update: purchased_app != "" ? true : false,
        _id: purchased_app?._id,
        selling_price: selling_price,
        category: JSON.parse(e.target.category.value),
        preview_link: e.target.preview_link.value,
        ...selectedoffer,
      };
    } else {
      var payload = {
        agency_id: agency._id,
        update: purchased_app != "" ? true : false,
        _id: purchased_app?._id,
        selling_price: e.target.selling_price.value,
        category: JSON.parse(e.target.category.value),
        preview_link: e.target.preview_link.value,
        ...selectedoffer,
      };
    }

    const { response } = await AppService.purchased_app.create({ payload });
    if (response) {
      setprocessing(false);

      handleRefresh();
      onClose();
    } else {
      setprocessing(false);
    }
  };
  return (
    <MDModal open={open} onClose={onClose} width={"70vw"}>
      {loader ? (
        <Loader />
      ) : (
        <MDBox component="form" onSubmit={handleSubmit} role="form">
          <MDBox
            mb={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5">Configure App</MDTypography>
          </MDBox>
          <Grid container spacing={2} rowSpacing={5}>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <img
                  src={offer?.image}
                  alt="image"
                  width={"90px"}
                  height={"80px"}
                />
                <MDBox ml={2}>
                  <MDTypography variant="h5">{offer?.name}</MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            {purchased_app?.offer_id ? (
              <>
                <Grid item xs={12}>
                  <MDBox
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ fontSize: "16px" }}
                  >
                    <b>Offer Id: </b>
                    <MDTypography variant="h6">
                      {purchased_app?.offer_id}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4}>
                  <MDBox
                    width="100%"
                    pt={1.5}
                    border="1px solid #d1d5db"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ minHeight: "170px" }}
                  >
                    <MDTypography variant="h6">Pay As You Go</MDTypography>
                    <MDTypography variant="h6">
                      {
                        <MDTypography variant="h6">
                          ${" "}
                          {offer.basic.value.substr(
                            0,
                            offer.basic.value.indexOf(" ")
                          )}
                        </MDTypography>
                      }
                    </MDTypography>
                    <MDButton
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={() =>
                        handleDialog({
                          open: true,
                          type: "basic",
                          price_id: offer.basic.value,
                        })
                      }
                      disabled={selected_plan == "basic" ? true : false}
                      sx={{ borderRadius: "0px !important" }}
                      fullWidth
                    >
                      {selected_plan == "basic" ? "Selected" : "Buy"}
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid item xs={4}>
                  <MDBox
                    width="100%"
                    pt={1.5}
                    border="1px solid #d1d5db"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ minHeight: "170px" }}
                  >
                    <MDTypography variant="h6">
                      Unlimited Monthly Plan
                    </MDTypography>
                    <MDTypography variant="h6">
                      ${" "}
                      {offer?.monthly?.value.substr(
                        0,
                        offer?.monthly?.value.indexOf(" ")
                      )}
                      /Month
                    </MDTypography>
                    <MDButton
                      variant="contained"
                      size="small"
                      color="success"
                      disabled={selected_plan == "monthly" ? true : false}
                      onClick={() =>
                        handleDialog({
                          open: true,
                          type: "monthly",
                          price_id: offer.monthly.value,
                          price: offer?.monthly?.value.substr(
                            0,
                            offer?.monthly?.value.indexOf(" ")
                          ),
                        })
                      }
                      sx={{ borderRadius: "0px !important" }}
                      fullWidth
                    >
                      {selected_plan == "monthly" ? "Selected" : "Buy"}
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid item xs={4}>
                  <MDBox
                    width="100%"
                    pt={1.5}
                    border="1px solid #d1d5db"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ minHeight: "170px" }}
                  >
                    <MDTypography variant="h6">
                      Unlimited Yearly Plan
                    </MDTypography>
                    <MDTypography variant="h6">
                      ${" "}
                      {offer?.yearly?.value.substr(
                        0,
                        offer?.yearly?.value.indexOf(" ")
                      )}
                      /Year
                    </MDTypography>
                    <MDButton
                      variant="contained"
                      size="small"
                      color="success"
                      sx={{ borderRadius: "0px !important" }}
                      disabled={selected_plan == "yearly" ? true : false}
                      onClick={() =>
                        handleDialog({
                          open: true,
                          type: "yearly",
                          price_id: offer.yearly.value,
                          price: offer?.yearly?.value.substr(
                            0,
                            offer?.yearly?.value.indexOf(" ")
                          ),
                        })
                      }
                      fullWidth
                    >
                      {selected_plan == "yearly" ? "Selected" : "Buy"}
                    </MDButton>
                  </MDBox>
                </Grid>
              </>
            )}
            <Grid xs={12}>
              <Elements stripe={stripePromise}>
                <ChargeModal
                  offer={offer}
                  handleRefresh={handleRefresh}
                  update={purchased_app != "" ? true : false}
                  open={openDialog}
                  purchased_app={purchased_app}
                  selectedoffer={selectedoffer}
                  onClose={({ response }) =>
                    handleDialog({ open: false, response: response })
                  }
                />
              </Elements>
              {console.log("testing stripe")}
            </Grid>

            <Grid item xs={12}>
              <MDBox
                width="90%"
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <MDTypography variant="h6">
                  Build Your Up-sell Offer
                </MDTypography>

                <MDBox display="flex" justifyContent="space-between" mt={1}>
                  <MDTypography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "400",
                    }}
                  >
                    Your Price
                  </MDTypography>
                  <MDBox
                    mb={1}
                    sx={{ fontSize: "14px" }}
                    width="50%"
                    display={"flex"}
                  >
                    <MDTypography
                      variant="h6"
                      sx={{
                        marginTop: "5px",
                        marginRight: "5px",
                      }}
                    >
                      {location_settings.currency_sign}
                    </MDTypography>
                    <input
                      defaultValue={purchase_data?.selling_price?.value.substr(
                        0,
                        purchase_data?.selling_price?.value.indexOf(" ")
                      )}
                      type="number"
                      name="selling_price"
                      className="form-control"
                      step="0.01"
                      style={{
                        width: "97%",
                        height: "40px",
                      }}
                    />
                  </MDBox>
                </MDBox>
                <MDBox display="flex" justifyContent="space-between" mt={1}>
                  <MDTypography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "400",
                    }}
                  >
                    Preview Link
                  </MDTypography>
                  <MDBox display="flex" alignItems="center" width="50%">
                    <input
                      defaultValue={purchase_data?.preview_link}
                      type="text"
                      name="preview_link"
                      className="form-control"
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </MDBox>
                </MDBox>
                <MDBox display="flex" justifyContent="space-between" mt={1}>
                  <MDTypography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "400",
                    }}
                  >
                    What categories does your app belong to?
                  </MDTypography>
                  <MDBox mb={1} sx={{ fontSize: "14px" }} width="50%">
                    {
                      <Multiselect
                        isMulti={true}
                        data={snapshotcategories}
                        edit_data={purchase_data?.category || []}
                        name="category"
                      />
                    }
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            {/* <Grid item xs={6}>
            <MDBox
              sx={{ borderLeft: "2px solid #d1d5db" }}
              width="100%"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <MDBox>
                <MDTypography variant="h6">
                  Build Your SaaS Bundle Offer
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                flexDirection="column"
                mt={1}
              >
                <CheckBox />
                <MDTypography
                  variant="button"
                  sx={{
                    fontWeight: "400",
                    fontSize: "13px",
                  }}
                >
                  Offer Wordpress as part of a SaaS Plan
                </MDTypography>
              </MDBox>
              <MDBox mt={2} sx={{ fontSize: "13px" }} width="65%">
                <Multiselect data={[]} edit_data={[]} isMulti={true} />
              </MDBox>
            </MDBox>
          </Grid> */}
            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
              <MDButton
                loading={processing}
                disabled={processing}
                sx={{ marginLeft: "1rem" }}
                variant="contained"
                color="success"
                type="submit"
              >
                Save
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDModal>
  );
}
ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func,
  Prices: PropTypes.array,
  offer: PropTypes.object,
};
function Modal({
  Prices,
  offer,
  handleRefresh,
  purchased_app,
  snapshotcategories,
}) {
  const [openAddMember, setOpenAddMember] = useState(false);

  return (
    <>
      <MDButton
        variant="outlined"
        size="small"
        color="info"
        onClick={() => setOpenAddMember(true)}
        disabled={offer?.status.value == "comming soon" ? true : false}
      >
        {offer?.status.value == "comming soon"
          ? "Comming Soon"
          : " Configure App"}
      </MDButton>
      {openAddMember ? (
        <ModalComponent
          purchased_app_data={purchased_app}
          snapshotcategories={snapshotcategories}
          open={openAddMember}
          onClose={() => setOpenAddMember(false)}
          Prices={Prices}
          offer={offer}
          handleRefresh={handleRefresh}
        />
      ) : (
        <MDBox />
      )}
    </>
  );
}
Modal.propTypes = {
  Prices: PropTypes.array,
  handleRefresh: PropTypes.func,
  offer: PropTypes.object,
};
export default Modal;

import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import env from "config";
import MDButton from "components/MDButton";
import BuyOfferModal from "./BuyOfferModal ";
import MDBox from "components/MDBox";
const Default = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const iframeStyles = {
    width: "100%",
    height: "100%",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const BASE_URL = `${env.API_URL}/v1`;
  useEffect(async () => {
    // inputRef.current.value = props.data.name
  }, []);
  function AddProduct({ open, onClose }) {
    return (
      <MDModal open={open} width={"90vw"} height={"80vh"} onClose={onClose}>
        <MDBox p={2}>
          <BuyOfferModal
            label={"Monthly"}
            price={"300"}
            duration={"month"}
            handelchange={onClose}
          />
          <BuyOfferModal
            label={"Yearly"}
            price={"3000"}
            duration={"year"}
            handelchange={onClose}
          />
        </MDBox>
        <iframe
          src="https://levelupmarketplace.io/upgrade-to-pro"
          style={iframeStyles}
        ></iframe>
      </MDModal>
    );
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        onClick={() => setOpenAddProduct(true)}
        variant="gradient"
        color={"info"}
        fullWidth
      >
        Upgrade To pro
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Default;

import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import { Select } from "antd";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import env from "config";
import MUISelect, { SelectChangeEvent } from "@mui/material/Select";
import localforage from "localforage";
import { TextareaAutosize } from "@mui/material";
const Category_model = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [agency_id, setagency_id] = useState();
  const BASE_URL = `${env.API_URL}/v1`;
  var type = props.type;
  if (type == "both") {
    type = [
      {
        label: "Snapshot",
        value: "snapshot",
      },
      {
        label: "Service",
        value: "service",
      },
      {
        label: "Funnels",
        value: "funnels",
      },
      {
        label: "Websites",
        value: "websites",
      },
    ];
  }
  useEffect(async () => {
    const localBrand = await localforage.getItem("user");
    setagency_id(localBrand.agency_id);
  }, []);
  function AddProduct({ open, onClose }) {
    const initState = {
      name: "",
    };
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();

      var axios = require("axios");
      // console.log(JSON(type))
      setProcessing(true);
      const name = e.target.name.value;
      const description = e.target.description.value;
      var data = "";

      if (props.type == "both") {
        var index = {};
        props.custom_link.forEach((element) => {
          if (element.type == "custom") {
            index[element.label] = 1;
          }
        });
        data = {
          name: name,
          description: description,
          agency_id: agency_id,
          custom: index,
          type: props.custom_link,
          created_by: "agency",
        };
      } else if (type == "custom") {
        var index = {};
        index[name] = 1;
        data = {
          description: description,
          name: name,
          agency_id: agency_id,
          custom: index,
          created_by: "agency",
          type: [
            {
              label: props.name,
              value: props.name,
            },
          ],
        };
      } else {
        data = {
          name: name,
          description: description,
          agency_id: agency_id,
          created_by: "agency",
          type: [
            {
              label: props.type,
              value: props.type,
            },
          ],
        };
      }
      console.log(data, "sub");
      axios
        .post(BASE_URL + "/snapshot/category/submit", { data })
        .then(async function (response) {
          console.log(response.data);
          props.handleChange(true);
          setOpenAddProduct(false);
          props.handleMessage("Category Added Successfully", "true");
        })
        .catch(function (error) {
          console.log(error);
          setOpenAddProduct(false);
          props.handleMessage(error.response.data.message, "false");
        });
      // const { response } = await AppService.stripe.addSubscription({ payload })

      // setProcessing(false)
      // onClose(response?.data)
    };
    const handleSelectChange = (e, { value }, name) => {
      formik.setValues({ ...formik.values, [name]: value });
    };

    const formik = useFormik({
      initialValues: { ...initState },
      onSubmit: handleSubmit,
    });
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Snapshot Category
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDInput type="name" label="Name" name="name" fullWidth />
              </MDBox>
              <input type="hidden" name="type" value={type} />
              <TextareaAutosize
                aria-label="minimum height"
                minRows={1}
                className="form-control"
                name="description"
                placeholder="description"
                style={{ width: "100%", paddingLeft: "13px" }}
              />
            </Grid>

            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <Button
        mr={2}
        sx={{ mr: 2 }}
        variant="contained"
        type="submit"
        color="primary"
        className="text-light btn-success"
        onClick={() => setOpenAddProduct(true)}
      >
        ADD Category
      </Button>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Category_model;

import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import { useNavigate, useLocation } from "react-router-dom";
import env from "config";
// import ConfirmAlert from "./ConfirmAlert";
import "./style.css";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const BuyModal = (props) => {
  const [agency, Update] = useAgencyInfo();
  const stripePromise = loadStripe(
    "pk_live_51Lyvl5IFQJPuAPurroNLnypochHdFrbCoy73ual8nIuGF3RtwlgGHUiPZDubxc4FpC7gvb3LT0XmmIDAT4gNCiHY00vn2KVEQw"
  );
  const AppService = useAppServices();
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [brand, setbrand] = useState([]);
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const location_settings = useAgencyInfo()[5];
  const inputRef = useRef();
  var axios = require("axios");
  let navigate = useNavigate();

  const varifyApp = (id) => {
    if (props.purchased_data.length > 0) {
      let obj = props.purchased_data.find((o) => o.app_id === id);
      if (
        obj?.purchased ||
        obj?.status == "succeeded" ||
        obj?.status == "active"
      ) {
        return "(Purchased)";
      }
    }
  };
  // const MakePrice = (item) => {
  //   var totalprice = 0;
  //   if (item.setup_fee != "" && item.setup_fee != undefined) {
  //     totalprice += parseFloat(item.setup_fee);
  //   }
  //   if (item.management_sub != "") {
  //     totalprice += parseFloat(
  //       item.management_sub?.substr(0, item.management_sub?.indexOf("("))
  //     );
  //   }
  //   for (let index = 0; index < item.apps.length; index++) {
  //     const element = item.apps[index];
  //     var newArray = props.purchased_apps?.filter(function (e) {
  //       return (
  //         e.app_id ===
  //         element.value?.substring(
  //           element.value?.indexOf("(") + 1,
  //           element.value?.indexOf(")", element.value?.indexOf("("))
  //         )
  //       );
  //     });
  //     if (newArray?.length) {
  //       var varify = varifyApp(newArray[0].app_id);
  //       if (varify != "(Purchased)") {
  //         var temp_price = newArray[0].selling_price?.value?.substr(
  //           0,
  //           newArray[0].selling_price?.value?.indexOf(" ")
  //         );
  //         totalprice += parseFloat(temp_price);
  //       }
  //     } else {
  //       totalprice +=
  //         parseFloat(element.value?.substr(0, element.value?.indexOf(" "))) * 3;
  //     }
  //   }
  //   return totalprice.toFixed(2);
  // };
  const handlemodal = async (newState) => {
    // const brand_data = await localforage.getItem("brand");
    // if (brand_data == null) {
    //   const localBrand = await localforage.getItem("loc_data");
    //   // props.handleAlert(
    //   //   "for purchase a Bundle make sure you must be login!",
    //   //   "auth"
    //   // );
    // } else {
    // }
    setOpenAddProduct(true);
  };
  useEffect(async () => {
    const brand_data = await localforage.getItem("brand");
    // alert(brand_data)
    setbrand(brand_data);
  }, []);
  function AddProduct({ open, onClose, data }) {
    const [processing, setProcessing] = useState(false);
    const stripe = useStripe();

    const handleSubmit = async (newState) => {
      // e.preventDefault();

      setProcessing(true);
      const user = await localforage.getItem("brand");
      const payload = {
        type: data.duration,
        agency_id: agency._id,
      };
      var { response } = await AppService.agency.upgrade_agency({ payload });
      if (response) {
        if (response.status == "completed") {
          setProcessing(false);
          Update(response.data);
          props.handelchange();
          props.handleMessage(response.message);
          setOpenAddProduct(false);
        } else {
          stripe
            .confirmCardPayment(response.data.client_secret, {})
            .then(async function (result) {
              const status = "succeeded";
              // result?.paymentIntent?.status
              if (result?.paymentIntent?.status == "succeeded") {
                const payload = {
                  update: true,
                  sub_data: response.sub_data,
                  type: data.duration,
                  agency_id: agency._id,
                };
                var update_result = await AppService.agency.upgrade_agency({
                  payload,
                });
                if (update_result.response) {
                  Update(update_result.response.data);
                  setProcessing(false);
                  props.handelchange();
                  setOpenAddProduct(false);
                }
              } else {
                setProcessing(false);
                props.handelchange();
                setOpenAddProduct(false);
              }
            });
        }
      } else {
        setProcessing(false);
        props.handleMessage("something went wrong");
        props.handelchange();
        setOpenAddProduct(false);
      }
    };
    return (
      <MDModal open={open} onClose={onClose} className={"buy-modal"}>
        <div>
          <form className="form">
            <div className="banner"></div>
            <label className="title">Get Your Agency Upgraded</label>
            {/* <label className="title-main">{props.data.name}</label> */}

            <div className="benefits">
              <span>Description</span>
              <ul>
                <li>
                  <span>{data.description}</span>
                </li>
              </ul>
            </div>

            <div className="modal--footer">
              <label className="price">
                <sup>$</sup>
                {data.price}
                <sup>/{data.duration}</sup>
              </label>
              <MDButton
                loading={processing}
                disabled={processing}
                className="upgrade-btn"
                type="button"
                onClick={handleSubmit}
              >
                Confirm to Buy
              </MDButton>
            </div>
          </form>
        </div>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      {props?.type == "iframe_page" ? (
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          m={1}
          onClick={handlemodal}
          sx={{ px: 2, py: 1, width: "60%" }}
          fullwidth
        >
          Get ({props.data.name})
        </MDButton>
      ) : (
        <MDButton
          variant="outlined"
          size="small"
          m={1}
          color="primary"
          className="purchase-btn"
          sx={{ marginRight: 2 }}
          onClick={handlemodal}
        >
          {props.label}
        </MDButton>
      )}
      <Elements stripe={stripePromise}>
        <AddProduct
          open={openAddProduct}
          data={props}
          onClose={closeAddProduct}
        />
      </Elements>
    </>
  );
};

export default BuyModal;

import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import { Select } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Multiselect from "./components/Multiselect";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import MUISelect, { SelectChangeEvent } from "@mui/material/Select";
import localforage from "localforage";
import env from "config";
const CustomlinkModal = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [agency_id, setagency_id] = useState();
  const BASE_URL = `${env.API_URL}/v1`;

  const multi_categroies = [
    {
      label: "Snapshot",
      value: "snapshot",
    },
    {
      label: "Service",
      value: "service",
    },
    {
      label: "Websites",
      value: "websites",
    },
    {
      label: "Funnels",
      value: "funnels",
    },
  ];
  useEffect(async () => {
    const localBrand = await localforage.getItem("user");
    setagency_id(localBrand.agency_id);
  }, []);
  function AddProduct({ open, onClose }) {
    const initState = {
      name: "",
    };
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (e.target.name.value != "") {
        var axios = require("axios");

        setProcessing(true);
        const name = e.target.name.value.replace(/\s/g, "");
        var index = [];
        const data = {
          name: name,
          agency_id: agency_id,
        };
        console.log(data);
        axios
          .post(BASE_URL + "/snapshot/custom_link/submit", { data })
          .then(async function (response) {
            console.log(response.data);
            props.handleChange(true);
            setOpenAddProduct(false);
            props.handlemessag("customlink create successfully", "success");
          })
          .catch(function (error) {
            console.log(error);
            props.handlemessag("customlink not create successfully", "false");
          });
        // const { response } = await AppService.stripe.addSubscription({ payload })

        // setProcessing(false)
        // onClose(response?.data)
      } else {
        alert("please fill the requried field!");
      }
    };
    const handleSelectChange = (e, { value }, name) => {
      formik.setValues({ ...formik.values, [name]: value });
    };

    const formik = useFormik({
      initialValues: { ...initState },
      onSubmit: handleSubmit,
    });
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Custom Links
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDInput type="name" label="Name" name="name" fullWidth />
              </MDBox>
            </Grid>

            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      {props.custom_elements > 6 ? (
        <MDButton
          sx={{ marginLeft: "auto" }}
          variant="outlined"
          color="info"
          startIcon={<AddBoxIcon />}
          onClick={() => alert("maximum limit is 7")}
        >
          Custom Link
        </MDButton>
      ) : (
        <MDButton
          sx={{ marginLeft: "auto" }}
          variant="outlined"
          color="info"
          startIcon={<AddBoxIcon />}
          onClick={() => setOpenAddProduct(true)}
        >
          Custom Link
        </MDButton>
      )}

      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default CustomlinkModal;

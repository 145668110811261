import EditIcon from '@mui/icons-material/Edit';
import MDButton from 'components/MDButton'
import Button from '@mui/material/Button'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import { useRef } from 'react';
import MDInput from 'components/MDInput'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import localforage from 'localforage'
import { loadStripe } from '@stripe/stripe-js'


const BasicModal = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [account_type, setaccount_type] = useState('')


  // const stripePromise = loadStripe('pk_live_51Lyvl5IFQJPuAPurroNLnypochHdFrbCoy73ual8nIuGF3RtwlgGHUiPZDubxc4FpC7gvb3LT0XmmIDAT4gNCiHY00vn2KVEQw')
  const inputRef = useRef();

  var axios = require('axios')

  const handlemodal = () => {
    setOpenAddProduct(true)

  }
  useEffect(async () => {
    const localBrand = await localforage.getItem('user');
    axios.get('https://api.snapshopstore.com/api/v1/snapshot/agency/location_settings/' + localBrand.agency_id).then(async function (response) {
      console.log(response.data);
      setaccount_type(response.data.data.agency.type)
    })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  function AddProduct({ open, onClose }) {

    const [processing, setProcessing] = useState(false)

    // const handleSubmit = async (e) => {
    //   e.preventDefault();

    //   setProcessing(true)

    //   const data = {

    //   }
    //   // console.log(data)
    //   const localBrand = await localforage.getItem('user');

    //   // props.handlemessage('done');
    //   axios.post('https://api.snapshopstore.com/api/v1/snapshot/agency/location_settings/update/account/' + localBrand.agency_id + '/' + props.price_id, { data }).then(async function (response) {
    //     if (response.data.status=="completed") {
    //       props.handlemessage(response.data.message,'success')
    //       console.log(response.data)
          
    //     }else{
    //       console.log(response.data)

    //       stripe.confirmCardPayment(response.data.data.client_secret, {
  
    //       }).then(function (result) {
    //         console.log(result)
    //         if(result.error){

    //         }else{
              
    //           axios.post('https://api.snapshopstore.com/api/v1/snapshot/agency/location_settings/UpdateAccountVald/'+ localBrand.agency_id +'/'+ props.price_id , { result }).then(async function (response) {
    //             console.log(response.data);
    //           })
    //             .catch(function (error) {
    //               console.log(error);
    //             });
              
    //           }
    //         });
    //     }

    //   })
    //     .catch(function (error) {
    //       // seterrormsg(error.response.data.message)
    //       props.handlemessage(error.response.data.message,'error')

    //       console.log(error);
    //     });
    //   setOpenAddProduct(false)
    //   // const { response } = await AppService.stripe.addSubscription({ payload })

    //   // setProcessing(false)
    //   // onClose(response?.data)
    // }
    return (
      <MDModal open={open} onClose={onClose} >
        <MDBox sx={{ width: 805,height:650 }}>
          <iframe src={props.url} frameborder="0" width={'100%'} height={'100%'}></iframe>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id) 
    setOpenAddProduct(false)

  }
  return (
    <>
    <MDButton
    variant="gradient"
    color="info"
    type="submit"
    onClick={handlemodal}
  >
    {props.label}
  </MDButton>
      

      <AddProduct open={openAddProduct} onClose={closeAddProduct} />

    </>
  )
}

export default BasicModal
import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid, Icon } from "@mui/material";
import localforage from "localforage";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  Card,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "../../Users/style.css";
import Loader from "examples/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import env from "config";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "./components/DefaultProjectCard";
import { useAppServices } from "hook/services";
import Sidenav from "./components/Sidenav";
import { useMaterialUIController, setMiniSidenav } from "context";
import { useLocation, useParams } from "react-router-dom";
import ComplexStatisticsCard from "./components/ComplexStatisticsCard";
import { useAgencyInfo } from "context/agency";
import Profile from "../profile";

function Purchases() {
  const AppService = useAppServices();
  const [agency] = useAgencyInfo();
  const [controller, dispatch] = useMaterialUIController();
  const [purchases, setpurchases] = useState([]);
  const [Users, setUsers] = useState([]);
  const [StoreData, setStoreData] = useState([]);
  const [stripeData, setstripeData] = useState("");
  const [seller_data, setseller_data] = useState({});
  const [configapps, setconfigapps] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [formCategory, setformCategory] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [processing, setprocessing] = useState(true);
  const params = useParams();
  const { pathname } = useLocation();

  const {
    miniSidenav,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    // darkMode,
  } = controller;
  const color = "info";
  const settings = [
    {
      type: "collapse",
      name: "Purchases",
      key: "purchases",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/agency/market/settings/purchases",
    },
  ];
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const epoch = moment.unix(1581292800);
  const BASE_URL = `${env.API_URL}/v1`;
  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };
  const getUsers = async (e) => {
    const { response } = await AppService.user.selleruser({
      query: "roles=seller",
    });
    console.log(response, "getUsers");
    if (response) {
      setUsers(response.data);
    } else {
      setUsers([]);
    }
    setprocessing(false);
  };
  const getStripe = async (e) => {
    const { response } = await AppService.marketplace_card.get({
      query: `seller_agency_id=${params.agency_id}&agency_id=${agency._id}`,
    });
    console.log(response, "getStripe");
    if (response) {
      setstripeData(response.data);
    }
  };
  const getCatgories = async () => {
    // alert('kjsh')
    if (params.agency_id) {
      var data = {
        agency_id: params.agency_id,
        loc_id: agency._id,
        type: "seller_profile",
      };
      var axios = require("axios");

      axios
        .post(BASE_URL + "/storeveiws/submit", { data })
        .then(async function (response) {
          // console.log(response.data);
          // alert("done");
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
    const localBrand = await localforage.getItem("user");
    var axios = require("axios");
    const Category = await axios
      .get(BASE_URL + "/snapshot/category/" + params.agency_id)
      .then(async function (response) {
        console.log(response.data, "catefgory");
        var busness = [
          {
            name: "business Info",
            _id: "business Info",
          },
        ];
        const temp = busness.concat(response.data.data);

        // setformCategory(temp);
        setCategoryData(temp);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(Category,'Category')
    // return {
    //   success: true,
    //   message: "true",
    //   data: Category.data,
    // };
  };
  const getAgencyCatgories = async () => {
    // getAgencyCatgories('kjsh')
    const localBrand = await localforage.getItem("user");
    var axios = require("axios");
    const Category = await axios
      .get(BASE_URL + "/snapshot/category/" + agency._id)
      .then(async function (response) {
        const temp = [];
        console.log(response.data, "catefgory");
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index];
          temp.push({
            label: element.name,
            value: element._id,
          });
        }
        console.log(temp, "temptemp");
        setformCategory(temp);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(Category,'Category')
    return {
      success: true,
      message: "true",
      data: Category.data,
    };
  };
  const getPurchasedApp = async (apps_data) => {
    // alert('called')
    const { response } = await AppService.snapshots.filter({
      query: `agency_id=${agency._id}&seller_id=${params.agency_id}`,
    });
    if (response) {
      console.log(
        response.data,
        "getPurchasedAppgetPurchasedAppgetPurchasedApp"
      );
      setconfigapps(response.data);
    }
  };
  const getSnapshots = async () => {
    // setprocessing(true);
    var axios = require("axios");
    const localBrand = await localforage.getItem("user");
    // alert(localBrand.agency_id)
    const { response } = await AppService.bundles.stordata({
      query: `seller_agency_id=${params.agency_id}&agency_id=${agency._id}`,
    });
    if (response) {
      const temp_store_data = response.data.filter((store) => {
        return !response.config_apps.some(
          (configapp) => configapp.pkg_id === store._id
        );
      });

      console.log(
        response,
        "responseresponseresponseresponseresponse",
        configapps,
        "temp_store_data",
        temp_store_data
      );
      var stripeProducts = [];
      var stripeProducts_data = [];
      if (
        response.data?.prices != ""
        // response.data?.location_settings.currency != ""
      ) {
        var newArray = response?.prices?.data.filter(function (item) {
          return (
            // item.currency == response.data?.location_settings.currency &&
            item.type == "recurring"
          );
        });
        stripeProducts = newArray;
        console.log(
          newArray,
          "stripeProductsstripeProductsstripeProductsstripeProducts"
        );
      }
      for (let index = 0; index < stripeProducts.length; index++) {
        const element = stripeProducts[index];
        var temp_prices = {};
        if (element.type == "recurring") {
          temp_prices = {
            label:
              element.unit_amount / 100 +
              "(" +
              element.recurring.interval +
              ")-" +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            value:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            type: element.type,
            currency: element.currency,
          };
        } else {
          temp_prices = {
            label:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            value:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            type: element.type,
            currency: element.currency,
          };
        }

        stripeProducts_data.push(temp_prices);
      }
      const data = {
        stripeProducts: stripeProducts_data,
        purchases: response.purchases,
        workflows: response.workflows.workflows,
        snapshots: temp_store_data,
      };
      setStoreData(data);
      setprocessing(false);
    } else {
      const data = {
        stripeProducts: [],
        purchases: [],
        workflows: [],
        snapshots: [],
      };
      setStoreData(data);
      setprocessing(false);
    }
    console.log(response, "getSnapshots");
  };
  const getSellerData = async () => {
    const { response } = await AppService.agency.get({
      query: `_id=${params.agency_id}`,
    });
    if (response) {
      setseller_data(response.data);
    }
  };
  const onLoad = () => {
    if (params.agency_id) {
      getSellerData();
      getSnapshots();
      // var newArray = Users.filter(function (item) {
      //   return item.agency_id == params.agency_id;
      // });
      // if (newArray[0]) {
      //   setseller_data(newArray[0]);
      // }
      getStripe();
      getCatgories();
      getAgencyCatgories();
    }
    getUsers();
  };
  const time = moment.unix(epoch);

  var axios = require("axios");
  useEffect(async () => {
    onLoad();
  }, [pathname]);
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  return (
    <>
      <DashboardLayout>
        {processing ? (
          <Loader />
        ) : (
          <Sidenav
            color={sidenavColor}
            // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            Users={Users}
            settings={settings}
            CategoryData={CategoryData}
            level={"agency"}
          />
        )}
        <MDBox style={{ marginLeft: 250 }}>
          <Grid container mt={3}>
            {params.cat_id ? (
              <Grid item xs={12}>
                <MDBox mb={3}>
                  {params.cat_id != "business Info" ? (
                    CategoryData.map((category, key) =>
                      category._id == params.cat_id ? (
                        <ComplexStatisticsCard
                          color="info"
                          icon="weekend"
                          title="Category"
                          stripeData={stripeData}
                          StoreData={StoreData}
                          formCategory={formCategory}
                          category_data={category}
                          refresh={refresh}
                          configapps={configapps}
                          handleChange={onLoad}
                          handleCategoryChange={getCatgories}
                          handlemessag={""}
                        />
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <Profile
                      seller_data={seller_data}
                      color="info"
                      icon="weekend"
                      title="Category"
                      stripeData={stripeData}
                      formCategory={formCategory}
                      CategoryData={CategoryData}
                      refresh={refresh}
                      StoreData={StoreData}
                      configapps={configapps}
                      handleChange={onLoad}
                      handleCategoryChange={getCatgories}
                      handlemessag={""}
                    />
                  )}
                </MDBox>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Purchases;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React base styles
import borders from "assets/theme/base/borders";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";

function PaymentDetails({ data }) {
  const { borderWidth, borderColor } = borders;

  return (
    <>
      {data.app_data?.app_type ? (
        <MDTypography variant="h6" fontWeight="medium">
          {`${data.app_data?.name} (${data.app_data?.app_type?.label})`}
        </MDTypography>
      ) : (
        <MDTypography variant="h6" fontWeight="medium">
          {`${data.app_data?.name} (${data.prod_data?.type})`}
        </MDTypography>
      )}

      <MDTypography
        variant="button"
        fontWeight="regular"
        textAlign="center"
        color="text"
      >
        {data.app_data?.description}
      </MDTypography>
      <MDBox
        border={`${borderWidth[1]} solid ${borderColor}`}
        borderRadius="lg"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        mt={1}
      >
        <MDBox
          component="img"
          src={masterCardLogo}
          alt="master card"
          width="10%"
          mr={2}
        />
        <MDTypography variant="h6" fontWeight="medium">
          ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
          {data.stripe_data.card[0].last4}
        </MDTypography>
        <MDBox ml="auto" lineHeight={0}>
          <Tooltip title="We do not store card details" placement="bottom">
            <MDButton
              variant="outlined"
              color="secondary"
              size="small"
              iconOnly
              circular
            >
              <Icon sx={{ cursor: "pointer" }}>priority_high</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
      </MDBox>
      {data.app_data?.app_type ?
      <MDBox display="flex" justifyContent="space-between" mt={1.5}>
        <MDButton variant="gradient" size="small" color="dark">
          Customer invoice
        </MDButton>
        {data.prod_data?.agency_sub?.type == "basic" ? (
          <MDButton
            variant="gradient"
            size="small"
            color="dark"
            onClick={() =>
              window.open(
                `${data.prod_data?.agency_sub?.latest_invoice?.payment_intent?.charges?.data[0]?.receipt_url}`,
                "_blank"
              )
            }
          >
            Agency invoice
          </MDButton>
        ) : (
          <></>
        )}
      </MDBox>
      :
      <></>
        }
    </>
  );
}

export default PaymentDetails;

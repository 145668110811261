import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import { Select } from "antd";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import env from "config";
import MUISelect, { SelectChangeEvent } from "@mui/material/Select";
import localforage from "localforage";
import { TextareaAutosize } from "@mui/material";
import { useAppServices } from "hook/services";
const ActivateModal = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const AppService = useAppServices();
console.log(props.item,'propspropspropspropspropspropspropsprops')
  var type = props.type;
  if (type == "both") {
    type = [
      {
        label: "Snapshot",
        value: "snapshot",
      },
      {
        label: "Service",
        value: "service",
      },
      {
        label: "Funnels",
        value: "funnels",
      },
      {
        label: "Websites",
        value: "websites",
      },
    ];
  }
  useEffect(async () => {
    
  }, []);
  function AddProduct({ open, onClose }) {
    const initState = {
      name: "",
    };
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();

      var axios = require("axios");
      // console.log(JSON(type))
      setProcessing(true);
      var data = "";

    const payload = {
      bundle_id:props.item._id,
    };
    console.log(payload, "payload");
    const { response } = await AppService.snapshots.activate_bundle({ payload });
    };
    // const handleSelectChange = (e, { value }, name) => {
    //   formik.setValues({ ...formik.values, [name]: value });
    // };

    const formik = useFormik({
      initialValues: { ...initState },
      onSubmit: handleSubmit,
    });
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Activate Bundle
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
              <MDTypography component="body2" mb={3} variant="body2">
            Make Sure You You imported the snapshot via invite sent to you in email By Seller
          </MDTypography>
              </MDBox>
              
            </Grid>

            <MDBox>
              <MDButton
                color="Warning"
                type="submit"
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Activate
              </MDButton>
              
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
       <MDButton
        onClick={() => setOpenAddProduct(true)}
            variant="outlined"
            size="small"
            color="warning"
          >
            Activate Bundle
          </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default ActivateModal;

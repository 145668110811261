/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useAgencyInfo } from "context/agency";

function OrderSummary({ data }) {
  const location_settings = useAgencyInfo()[4];

  var profit = 0;
  const selling_price = data.prod_data?.agency_sub?.selling_price?.value;
  if (data.prod_data?.agency_sub?.type == "basic") {
    profit =
      parseFloat(selling_price?.substr(0, selling_price?.indexOf(" "))) -
      parseFloat(
        data.app_data?.basic?.value.substr(
          0,
          data.app_data?.basic?.value.indexOf(" ")
        )
      );
  } else {
    profit = parseFloat(selling_price?.substr(0, selling_price?.indexOf(" ")));
  }
  return (
    <>
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Summary
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Customer Subscription:
          <br />
          <span style={{ fontSize: "13px" }}>({data?.prod_data?.sub_id})</span>
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            {location_settings.currency_sign}
            {selling_price?.substr(0, selling_price?.indexOf(" "))}
          </MDTypography>
        </MDBox>
      </MDBox>
      {data.prod_data?.agency_sub?.type == "basic" ? (
        <MDBox display="flex" justifyContent="space-between" mt={1} mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Pay as You Go Subscription:
            <br />
            <span style={{ fontSize: "13px" }}>
              ({data.prod_data?.agency_sub?.sub_id})
            </span>
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              {location_settings.currency_sign + " "}
              {data.app_data?.basic?.value.substr(
                0,
                data.app_data?.basic?.value.indexOf(" ")
              )}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <></>
      )}
      <MDBox display="flex" justifyContent="space-between" mt={2}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Profit:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            {location_settings.currency_sign + profit?.toFixed(2)}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );
}

export default OrderSummary;

import React, { useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import { Divider, Icon, Switch } from "@mui/material";
import Tabs from "../Tabs";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import Loader from "examples/Loader";
import { useParams } from "react-router-dom";
import axios from "axios";
import env from "config";
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@mui/icons-material";
const Default = () => {
  const BASE_URL = `${env.API_URL}/v1`;
  const Service = useAppServices();
  const [agency] = useAgencyInfo();
  const params = useParams();
  const [slides, setSlides] = useState([{ index: 0, fields: [] }]);
  const [refresh, setrefresh] = useState(false);
  // const [customselect, setcustomselect] = useState(false);
  const [processing, setprocessing] = useState(true);
  const [customFields, setcustomFields] = useState([]);
  const [activeSlide, setactiveSlide] = useState(0);
  const [formSettings, setformSettings] = useState({});
  const [logo_type, setlogo_type] = useState("");
  const [logo_value, setlogo_value] = useState("");
  const [logo_image, setlogo_image] = useState(
    "https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D"
  );
  const [handleFieldHoverClass, sethandleFieldHoverClass] =
    useState("survey-form-fields");
  const [ActiveFieldIndex, setActiveFieldIndex] = useState(0);
  const [activeForm, setactiveForm] = useState("");
  const handleSubmit = async () => {
    const payload = {
      _id: params.id,
      customization: slides,
      form_settings: formSettings,
      logo_type: logo_type,
      logo_value: {
        text: logo_value,
        image: logo_image,
      },
    };
    const { response } = await Service.servey.update({ payload });
  };
  const handleSlides = () => {
    // alert("trigger");
    // console.log(slides);
    const slide_data = slides;
    slide_data.push({ index: slides.length, fields: [] });
    // console.log(slide_data);
    setSlides(slide_data);
    setrefresh(!refresh);
  };
  const handleRightbar = (type, index, form) => {
    // console.log(form, index, type);
    if (type == "open") {
      if (index == null) {
        document.documentElement.style.setProperty(
          "--right-sidebar-width",
          "400px"
        );
        setactiveForm("logo");
      } else if (form == "main") {
        setActiveFieldIndex(null);
        setactiveForm("none");
        setactiveSlide(index);
        document.documentElement.style.setProperty(
          "--right-sidebar-width",
          "400px"
        );
      } else {
        setactiveForm(form);
        setactiveSlide(index);
        document.documentElement.style.setProperty(
          "--right-sidebar-width",
          "400px"
        );
      }
    } else {
      document.documentElement.style.setProperty(
        "--right-sidebar-width",
        "0px"
      );
    }
  };
  const FileField = ({ type }) => {
    const [urltype, seturltype] = useState(false);
    const handleFileField = async (e) => {
      e.preventDefault();
      const settings = {
        width: e.target.width.value,
        height: e.target.height.value,
      };
      if (urltype) {
        var file = e.target.url.value;
      } else {
        const file_data = e.target.image.files[0];
        console.log(file_data, "file_data");
        const form_data = new FormData();
        form_data.append("image", file_data, file_data.name);
        console.log(form_data.get("image"));

        var file = await axios
          .post(`${BASE_URL}/utils/upload/image`, form_data)
          .then((response) => {
            return response.data.data;
          });
      }
      const field = {
        file: file,
        type: type,
        settings: settings,
      };
      const slide_data = slides;
      if (slides[activeSlide]?.fields[ActiveFieldIndex]) {
        slide_data[activeSlide].fields[ActiveFieldIndex] = field;
      } else {
        slide_data[activeSlide].fields.push(field);
      }
      // console.log(slide_data);
      setSlides(slide_data);
      e.target.width.value = "";
      e.target.height.value = "";
      setactiveForm("");
      setActiveFieldIndex(null);
      handleRightbar("close");
      setrefresh(!refresh);
    };
    return (
      <MDBox py={3} pt={2}>
        <form onSubmit={handleFileField}>
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" mr={2} display="block" gutterBottom>
              <b> Use Custom Url</b>
            </MDTypography>
            <Switch onClick={(e) => seturltype(!urltype)} />
          </MDBox>
          {urltype == true ? (
            <div class="form-group mb-2">
              <MDTypography variant="button" display="block" gutterBottom>
                <b>
                  {" "}
                  {type == "image" ? "Enter Image Url" : "Enter Video Url"}
                </b>
              </MDTypography>
              <input type="text" class="form-control" name="url" />
            </div>
          ) : (
            <div class="form-group mb-2">
              <MDTypography variant="button" display="block" gutterBottom>
                <b> {type == "image" ? "Image" : "Video"}</b>
              </MDTypography>
              <input type="file" class="form-control" name="image" />
            </div>
          )}

          {/* <div class="form-group mb-2">
            <MDTypography
              variant="h6"
              textAlign="center"
              display="block"
              gutterBottom
            >
              Customization
            </MDTypography>
          </div> */}
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Width</b>
            </MDTypography>
            <input
              type="number"
              max={450}
              class="form-control"
              name="width"
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.settings?.width
              }
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Height</b>
            </MDTypography>
            <input
              type="number"
              max={450}
              class="form-control"
              name="height"
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.settings?.height
              }
            />
          </div>
          <div
            class="form-group mb-2 mt-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <MDButton
              type="submit"
              variant="contained"
              color="info"
              size="small"
            >
              Save
            </MDButton>
          </div>
        </form>
      </MDBox>
    );
  };
  const TextField = () => {
    const handleTextField = (e) => {
      e.preventDefault();
      const settings = {
        font_size: e.target.font_size.value,
        font_color: e.target.font_color.value,
      };
      const field = {
        text: e.target.text.value,
        type: "simple_text",
        settings: settings,
      };
      const slide_data = slides;
      if (slides[activeSlide]?.fields[ActiveFieldIndex]) {
        slide_data[activeSlide].fields[ActiveFieldIndex] = field;
      } else {
        slide_data[activeSlide].fields.push(field);
      }
      // console.log(slide_data);
      setSlides(slide_data);
      e.target.font_size.value = "";
      e.target.font_color.value = "";
      e.target.text.value = "";
      setactiveForm("");
      setActiveFieldIndex(null);
      handleRightbar("close");
      setrefresh(!refresh);
    };
    return (
      <MDBox py={3} pt={2}>
        <form onSubmit={handleTextField}>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Enter Your Text</b>
            </MDTypography>
            <input
              type="text"
              class="form-control"
              name="text"
              defaultValue={slides[activeSlide]?.fields[ActiveFieldIndex]?.text}
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Font Color</b>
            </MDTypography>
            <input
              type="color"
              class="form-control color-input-2"
              name="font_color"
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.settings
                  ?.font_color
              }
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Font Size</b>
            </MDTypography>
            <input
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.settings
                  ?.font_size
              }
              type="number"
              max={30}
              class="form-control"
              name="font_size"
            />
          </div>
          <div
            class="form-group mb-2 mt-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <MDButton
              type="submit"
              variant="contained"
              color="info"
              size="small"
            >
              Save
            </MDButton>
          </div>
        </form>
      </MDBox>
    );
  };
  const NumberField = () => {
    const handleNumberField = (e) => {
      e.preventDefault();
      const settings = {
        font_size: e.target.font_size.value,
        label_color: e.target.label_color.value,
      };
      const field = {
        label: e.target.label.value,
        placeholder: e.target.placeholder.value,
        short_label: e.target.short_label.value,
        type: "number",
        settings: settings,
      };
      const slide_data = slides;
      if (slides[activeSlide]?.fields[ActiveFieldIndex]) {
        slide_data[activeSlide].fields[ActiveFieldIndex] = field;
      } else {
        slide_data[activeSlide].fields.push(field);
      }
      // console.log(slide_data);
      setSlides(slide_data);
      e.target.font_size.value = "";
      e.target.label_color.value = "";
      e.target.label.value = "";
      e.target.placeholder.value = "";
      e.target.short_label.value = "";
      setactiveForm("");
      setActiveFieldIndex(null);
      handleRightbar("close");
      setrefresh(!refresh);
    };
    return (
      <MDBox py={3} pt={2}>
        <form onSubmit={handleNumberField}>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Label</b>
            </MDTypography>
            <input
              type="text"
              class="form-control"
              name="label"
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.label
              }
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Placeholder</b>
            </MDTypography>
            <input
              type="text"
              class="form-control"
              name="placeholder"
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.placeholder
              }
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Short Label</b>
            </MDTypography>
            <input
              type="text"
              class="form-control"
              name="short_label"
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.short_label
              }
            />
          </div>
          {/* <div class="form-group mb-2">
            <MDTypography
              variant="h6"
              textAlign="center"
              display="block"
              gutterBottom
            >
              Customization
            </MDTypography>
          </div> */}
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Label Color</b>
            </MDTypography>
            <input
              type="color"
              class="form-control color-input-2"
              name="label_color"
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.settings
                  ?.label_color
              }
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b> Font Size</b>
            </MDTypography>
            <input
              defaultValue={
                slides[activeSlide]?.fields[ActiveFieldIndex]?.settings
                  ?.font_size
              }
              type="number"
              max={30}
              class="form-control"
              name="font_size"
            />
          </div>
          <div
            class="form-group mb-2 mt-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <MDButton
              type="submit"
              variant="contained"
              color="info"
              size="small"
            >
              Save
            </MDButton>
          </div>
        </form>
      </MDBox>
    );
  };

  const LogoFields = () => {
    const handleLogoFields = async (e) => {
      e.preventDefault();
      var img_data = "";
      if (e.target.logo_image.files[0]) {
        const image_file = e.target.logo_image.files[0];
        const form_data = new FormData();
        form_data.append("image", image_file, image_file.name);
        // console.log(form_data.get("image"));

        img_data = await axios
          .post(`${BASE_URL}/utils/upload/image`, form_data)
          .then((response) => {
            return response.data.data;
          });
      }
      const form_settings = {
        page_bg: e.target.page_bg.value,
        default_url: e.target.default_url.value,
        custom_link: e.target.custom_link.value,
        form_bg: e.target.form_bg.value,
        logo_text: e.target.logo.value,
        logo_image: img_data,
        label_color: e.target.label_color.value,
        image_height: e.target?.image_height?.value || null,
        image_width: e.target?.image_width?.value || null,
        logo_font_size: e.target?.logo_font_size?.value || null,
      };
      // console.log(form_settings, "form_settings");
      setformSettings(form_settings);
    };
    // const handleLogoType = (value) => {
    //   setlogo_image("");
    //   setlogo_value("");
    //   setlogo_type(value);
    // };
    return (
      <MDBox py={3} pt={2}>
        <form onSubmit={handleLogoFields}>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b>Default Url</b>
            </MDTypography>
            <input
              type="text"
              class="form-control"
              name="default_url"
              placeholder="URL"
              defaultValue={formSettings?.default_url}
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b>custom link Name</b>
            </MDTypography>
            <MDTypography variant="caption" display="block" gutterBottom>
              (Make Sure Its Unique)
            </MDTypography>
            <input
              type="text"
              class="form-control"
              name="custom_link"
              placeholder="custom link Name"
              defaultValue={formSettings?.custom_link}
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b>Header Text</b>
            </MDTypography>
            <input
              type="text"
              class="form-control"
              name="logo"
              defaultValue={formSettings?.logo_text}
            />
          </div>
          <div class="form-group mb-2">
            <MDTypography variant="button" display="block" gutterBottom>
              <b>Header Image</b>
            </MDTypography>
            <input type="file" class="form-control" name="logo_image" />
          </div>
          <MDBox mt={2}>
            <MDTypography
              variant="h6"
              textAlign="center"
              display="block"
              gutterBottom
            >
              <b> Form Customization</b>
            </MDTypography>
            <div class="form-group mb-2">
              <MDTypography variant="button" display="block" gutterBottom>
                <b> Page Background</b>
              </MDTypography>
              <input
                defaultValue={formSettings?.page_bg}
                type="color"
                class="form-control color-input-2"
                name="page_bg"
              />
            </div>
            <div class="form-group mb-2">
              <MDTypography variant="button" display="block" gutterBottom>
                <b> Form Background</b>
              </MDTypography>
              <input
                defaultValue={formSettings?.form_bg}
                type="color"
                class="form-control color-input-2"
                name="form_bg"
              />
            </div>
            <div class="form-group mb-2">
              <MDTypography variant="button" display="block" gutterBottom>
                <b> Labels Color</b>
              </MDTypography>
              <input
                defaultValue={formSettings?.label_color}
                type="color"
                class="form-control color-input-2"
                name="label_color"
              />
            </div>
            {logo_type == "file" ? (
              <>
                <div class="form-group mb-2">
                  <MDTypography variant="button" display="block" gutterBottom>
                    <b> Logo Image Height</b>
                  </MDTypography>
                  <input
                    type="number"
                    defaultValue={formSettings?.number}
                    max={200}
                    class="form-control"
                    name="image_height"
                  />
                </div>
                <div class="form-group mb-2">
                  <MDTypography variant="button" display="block" gutterBottom>
                    <b> Logo Image Width</b>
                  </MDTypography>
                  <input
                    defaultValue={formSettings?.image_width}
                    type="number"
                    max={400}
                    class="form-control "
                    name="image_width"
                  />
                </div>
              </>
            ) : (
              <div class="form-group mb-2">
                <MDTypography variant="button" display="block" gutterBottom>
                  <b>Logo Font Size</b>
                </MDTypography>
                <input
                  defaultValue={formSettings?.logo_font_size}
                  type="number"
                  max={30}
                  class="form-control "
                  name="logo_font_size"
                />
              </div>
            )}
          </MDBox>
          <div
            class="form-group mb-2 mt-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <MDButton
              type="submit"
              variant="contained"
              color="info"
              size="small"
            >
              Save
            </MDButton>
          </div>
        </form>
      </MDBox>
    );
  };
  const CustomFields = () => {
    const [selected_field, setselected_field] = useState(
      slides[activeSlide]?.fields[ActiveFieldIndex]?.custom_value_field || ""
    );
    const [options, setOptions] = useState(
      slides[activeSlide]?.fields[ActiveFieldIndex]?.options || []
    );
    const [newLabel, setNewLabel] = useState("");
    const [newValue, setNewValue] = useState("");
    const [customselect, setcustomselect] = useState(
      slides[activeSlide]?.fields[ActiveFieldIndex]?.type || ""
    );
    const handleAddOption = () => {
      if (newLabel !== "" && newValue !== "") {
        const newOption = { label: newLabel, value: newValue };
        setOptions([...options, newOption]);
        setNewLabel("");
        setNewValue("");
      } else {
        // Handle empty label or value
        alert("Label and Value cannot be empty");
      }
    };
    const handle_fieldtype = (e) => {
      setcustomselect(e.target.value);
      // alert(e.target.value);
    };
    const handleCustomField = (e) => {
      const settings = {
        font_size: e.target.font_size.value,
        label_color: e.target.label_color.value,
      };
      const dotIndex = selected_field.fieldKey.indexOf(".");
      const closingBracketIndex = selected_field.fieldKey.indexOf("}}");

      let fieldKey = "";

      // if (
      //   dotIndex !== -1 &&
      //   closingBracketIndex !== -1 &&
      //   dotIndex < closingBracketIndex
      // ) {
      // }
      fieldKey = selected_field.fieldKey
        .substring(dotIndex + 1, closingBracketIndex)
        .trim();

      const field = {
        label: e.target.label.value,
        placeholder: e.target.placeholder.value,
        short_label: e.target.short_label.value,
        fieldKey: fieldKey,
        type: customselect,
        custom_value: true,
        settings: settings,
        options: options,
        custom_value_field: selected_field,
      };
      const slide_data = slides;
      if (slide_data[activeSlide].fields[ActiveFieldIndex]) {
        slide_data[activeSlide].fields[ActiveFieldIndex] = field;
      } else {
        slide_data[activeSlide].fields.push(field);
      }
      console.log(slide_data);
      setactiveForm("");
      setActiveFieldIndex(null);
      handleRightbar("close");
      setrefresh(!refresh);
    };
    const handleDeleteOption = (index) => {
      const updatedOptions = options.filter((_, i) => i !== index);
      setOptions(updatedOptions);
    };
    const handleLabelChange = (index, value) => {
      const updatedOptions = options.map((option, i) => {
        if (index === i) {
          return { ...option, label: value };
        }
        return option;
      });
      setOptions(updatedOptions);
    };

    const handleValueChange = (index, value) => {
      const updatedOptions = options.map((option, i) => {
        if (index === i) {
          return { ...option, value: value };
        }
        return option;
      });
      setOptions(updatedOptions);
    };

    return (
      <MDBox py={3} pt={2}>
        <form onSubmit={handleCustomField}>
          {selected_field == "" ? (
            <>
              <MDTypography variant="button" display="block" gutterBottom>
                <b>Custom Values</b>
              </MDTypography>
              {customFields.map((field) => (
                <div
                  class="form-control mb-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setselected_field(field)}
                >
                  {field.name}
                </div>
              ))}
            </>
          ) : (
            <>
              <MDTypography
                variant="button"
                mb={3}
                display="block"
                gutterBottom
              >
                Selected Custom Value:
                <b>{selected_field?.fieldKey}</b>
              </MDTypography>

              <div class="form-group mb-2">
                <MDTypography variant="button" display="block" gutterBottom>
                  <b> Type</b>
                </MDTypography>
                <select
                  name="type"
                  id=""
                  className="form-control"
                  defaultValue={customselect}
                  onChange={handle_fieldtype}
                >
                  <option value="">none</option>
                  <option value="text">Text</option>
                  <option value="date">Date</option>
                  <option value="text-area">Long text </option>
                  <option value="datetime-local">date and time</option>
                  <option value="color">color</option>
                  <option value="select">Single select drop down</option>
                  <option value="file">File</option>
                  <option value="number">Number</option>
                </select>
              </div>
              <div class="form-group mb-2">
                <MDTypography variant="button" display="block" gutterBottom>
                  <b> Label</b>
                </MDTypography>
                <input
                  type="text"
                  class="form-control"
                  name="label"
                  defaultValue={
                    slides[activeSlide]?.fields[ActiveFieldIndex]?.label
                  }
                />
              </div>
              {customselect == "select" ? (
                <div class="form-group mb-2">
                  <MDTypography variant="button" display="block" gutterBottom>
                    <b> add options</b>
                  </MDTypography>
                  {options.map((option, index) => (
                    <MDBox
                      sx={{ display: "flex", flexDirection: "row" }}
                      mb={1}
                    >
                      <input
                        type="text"
                        class="form-control"
                        name="placeholder"
                        placeholder="Label"
                        value={option.label}
                        onChange={(e) =>
                          handleLabelChange(index, e.target.value)
                        }
                      />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Value"
                        name="placeholder"
                        value={option.value}
                        onChange={(e) =>
                          handleValueChange(index, e.target.value)
                        }
                      />
                      <button
                        className="btn"
                        type="button"
                        onClick={() => handleDeleteOption(index)}
                      >
                        <Icon> delete</Icon>
                      </button>
                    </MDBox>
                  ))}
                  <MDBox sx={{ display: "flex", flexDirection: "row" }}>
                    <input
                      type="text"
                      class="form-control"
                      name="placeholder"
                      placeholder="Label"
                      value={newLabel}
                      onChange={(e) => setNewLabel(e.target.value)}
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Value"
                      name="placeholder"
                      value={newValue}
                      onChange={(e) => setNewValue(e.target.value)}
                    />
                    <button
                      className="btn"
                      type="button"
                      onClick={handleAddOption}
                    >
                      <Icon> add</Icon>
                    </button>
                  </MDBox>
                </div>
              ) : (
                <></>
              )}
              <div class="form-group mb-2">
                <MDTypography variant="button" display="block" gutterBottom>
                  <b> Placeholder</b>
                </MDTypography>
                <input
                  type="text"
                  class="form-control"
                  name="placeholder"
                  defaultValue={
                    slides[activeSlide]?.fields[ActiveFieldIndex]?.placeholder
                  }
                />
              </div>
              <div class="form-group mb-2">
                <MDTypography variant="button" display="block" gutterBottom>
                  <b> Short Label</b>
                </MDTypography>
                <input
                  type="text"
                  class="form-control"
                  name="short_label"
                  defaultValue={
                    slides[activeSlide]?.fields[ActiveFieldIndex]?.short_label
                  }
                />
              </div>
              {/* <div class="form-group mb-2">
            <MDTypography
              variant="h6"
              textAlign="center"
              display="block"
              gutterBottom
            >
              Customization
            </MDTypography>
          </div> */}
              <div class="form-group mb-2">
                <MDTypography variant="button" display="block" gutterBottom>
                  <b> Label Color</b>
                </MDTypography>
                <input
                  type="color"
                  class="form-control color-input-2"
                  name="label_color"
                  defaultValue={
                    slides[activeSlide]?.fields[ActiveFieldIndex]?.settings
                      ?.label_color
                  }
                />
              </div>
              <div class="form-group mb-2">
                <MDTypography variant="button" display="block" gutterBottom>
                  <b> Font Size</b>
                </MDTypography>
                <input
                  defaultValue={
                    slides[activeSlide]?.fields[ActiveFieldIndex]?.settings
                      ?.font_size
                  }
                  type="number"
                  max={30}
                  class="form-control"
                  name="font_size"
                />
              </div>
              <div
                class="form-group mb-2 mt-5"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <MDButton
                  type="submit"
                  variant="contained"
                  color="info"
                  size="small"
                >
                  Save
                </MDButton>
              </div>
            </>
          )}
        </form>
      </MDBox>
    );
  };
  const getCustomFields = async (locationId) => {
    const { response } = await Service.servey.config_data({
      query: `locationId=${locationId}&agency_id=${agency._id}`,
    });
    console.log(response, "getCustomFields");
    if (response) {
      setcustomFields(response.data);
    }
  };
  const handleUpwardSwape = (event, index, fieldindex) => {
    event.stopPropagation(); // Prevent event from reaching the parent
    if (fieldindex > 0) {
      const temp_slides = slides[index]?.fields;
      const newObjects = [...temp_slides];
      const temp = newObjects[fieldindex]; // Store the current object
      newObjects.splice(fieldindex, 1); // Remove the object from the array
      newObjects.splice(fieldindex - 1, 0, temp);
      const slide_data = slides;
      slide_data[index].fields = newObjects;
      setSlides(slide_data);
      setrefresh(!refresh);
    }
  };
  const handleDownwardSwape = (event, index, fieldindex) => {
    event.stopPropagation(); // Prevent event from reaching the parent
    var temp_slides = slides[index]?.fields;
    var newObjects = [...temp_slides];
    if (index < newObjects.length - 1) {
      const temp = newObjects[fieldindex]; // Store the current object
      newObjects.splice(fieldindex, 1);
      newObjects.splice(fieldindex + 1, 0, temp);
      const slide_data = slides;
      slide_data[index].fields = newObjects;
      setSlides(slide_data);
      setrefresh(!refresh);
    }
  };
  const handleDeleteField = (event, index, fieldindex) => {
    event.stopPropagation();
    var temp_slides = slides[index]?.fields;
    const newObjects = [...temp_slides];
    newObjects.splice(index, 1);
    const slide_data = slides;
    slide_data[index].fields = newObjects;
    setSlides(slide_data);
    setrefresh(!refresh);
  };
  const handleHoverField = (index, fieldindex, type) => {
    // console.log(index, fieldindex);
    setactiveForm(type);
    setActiveFieldIndex(fieldindex);
    handleRightbar("open", index, type);
  };
  const handleLogo = () => {
    handleRightbar("open", null);
  };
  const getSurvey = async () => {
    const { response } = await Service.servey.get({
      query: `_id=${params.id}`,
    });
    if (response) {
      if (response.data?.customization.length) {
        setSlides(response.data?.customization);
        setlogo_value(response.data?.logo_value?.text);
        setlogo_image(response.data?.logo_value?.image);
        setformSettings(response.data?.form_settings);
        setlogo_type(response.data?.logo_type);
      }
      getCustomFields(response.data.location.value);
      setprocessing(false);
    } else {
      setprocessing(false);
    }
  };
  useEffect(async () => {
    getSurvey();
  }, []);
  return (
    <DashboardLayout>
      {processing ? (
        <Loader />
      ) : (
        <>
          {/* <MDBox mt={8}>
            <MDBox
              width="90%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <MDBox
                sx={{
                  width: 550,
                  minHeight: 500,
                  height: "100%",
                  overflow: "auto",
                  bgcolor: "background.paper",
                  boxShadow: 1,
                  p: 4,
                  mb: 2,
                }}
              >
                <MDTypography
                  variant="h5"
                  textAlign="center"
                  display="block"
                  gutterBottom
                  mb={2}
                >
                  Survey Form Logo
                </MDTypography>
                <MDBox
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <div class={`form-group mb-4 w-100 `}>
                    <MDTypography variant="button" display="block" gutterBottom>
                      <b> {"field.label"}</b>
                    </MDTypography>
                    <input
                      type="text"
                      class="form-control"
                      placeholder={"field.placeholder"}
                    />
                  </div>

                  <MDButton
                    variant="outlined"
                    color="info"
                    fullWidth
                    onClick={() => handleRightbar("open", 0, "custom_value")}
                    startIcon={<AddBoxIcon />}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox> */}
          <MDBox display="flex" justifyContent="flex-end">
            <MDButton variant="contained" color="info" onClick={handleLogo}>
              Customization
            </MDButton>
            <MDButton
              variant="contained"
              color="info"
              onClick={handleSubmit}
              sx={{ marginLeft: 2 }}
            >
              Submit
            </MDButton>
          </MDBox>
          <MDBox mt={8}>
            <MDBox
              width="90%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {slides.map((slide, index) => (
                <MDBox
                  sx={{
                    width: 550,
                    minHeight: 500,
                    height: "100%",
                    overflow: "auto",
                    bgcolor: formSettings?.form_bg
                      ? formSettings.form_bg
                      : "background.paper",
                    boxShadow: 1,
                    p: 4,
                    mb: 2,
                  }}
                >
                  {index == 0 ? (
                    <div
                      onClick={handleLogo}
                      className=" survey-form-fields"
                      style={{
                        marginBottom: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <MDBox className="survey-form-fields-menu">
                        <ArrowDownward
                          sx={{
                            width: "0.9rem",
                            height: "0.9rem",
                            cursor: "pointer",
                          }}
                        />
                        <ArrowUpward
                          sx={{
                            width: "0.9rem",
                            height: "0.9rem",
                            cursor: "pointer",
                          }}
                        />
                        <Delete
                          sx={{
                            width: "0.9rem",
                            height: "0.9rem",
                            cursor: "pointer",
                          }}
                        />
                      </MDBox>
                      {formSettings?.logo_text != "" &&
                      formSettings?.logo_text != undefined ? (
                        <MDTypography
                          variant="h5"
                          textAlign="center"
                          display="block"
                          gutterBottom
                          mb={2}
                        >
                          <span
                            style={{
                              fontSize: ` ${formSettings?.logo_font_size}px`,
                            }}
                          >
                            {formSettings?.logo_text}
                          </span>
                        </MDTypography>
                      ) : (
                        <></>
                      )}
                      {formSettings?.logo_image != "" &&
                      formSettings?.logo_image != undefined ? (
                        <img
                          src={formSettings?.logo_image}
                          width={formSettings?.image_width || 200}
                          height={formSettings?.image_height || 150}
                          alt="logo"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  <MDBox
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    {slide.fields.map((field, fieldindex) => (
                      <div
                        class={`form-group mb-4 w-100 survey-form-fields`}
                        onClick={() =>
                          handleHoverField(
                            index,
                            fieldindex,
                            field?.custom_value ? "custom_value" : field?.type
                          )
                        }
                      >
                        <MDBox className="survey-form-fields-menu">
                          <ArrowDownward
                            onClick={(e) =>
                              handleDownwardSwape(e, index, fieldindex)
                            }
                            sx={{
                              width: "0.9rem",
                              height: "0.9rem",
                              cursor: "pointer",
                            }}
                          />
                          <ArrowUpward
                            onClick={(e) =>
                              handleUpwardSwape(e, index, fieldindex)
                            }
                            sx={{
                              width: "0.9rem",
                              height: "0.9rem",
                              cursor: "pointer",
                            }}
                          />
                          <Delete
                            onClick={(e) =>
                              handleDeleteField(e, index, fieldindex)
                            }
                            sx={{
                              width: "0.9rem",
                              height: "0.9rem",
                              cursor: "pointer",
                            }}
                          />
                        </MDBox>
                        {field?.type == "image" ? (
                          <MDBox
                            width="100%"
                            display="flex"
                            justifyContent="center"
                          >
                            <img
                              src={field?.file}
                              width={field?.settings?.width || 250}
                              height={field?.settings?.height || 200}
                            />
                          </MDBox>
                        ) : field?.type == "video" ? (
                          <MDBox
                            width="100%"
                            display="flex"
                            justifyContent="center"
                          >
                            <video
                              controls={true}
                              autoPlay={true}
                              src={field?.file}
                              width={field?.settings?.width || 250}
                              height={field?.settings?.height || 200}
                            ></video>
                          </MDBox>
                        ) : field?.type == "simple_text" ? (
                          <MDTypography
                            textAlign="center"
                            display="block"
                            gutterBottom
                            sx={{
                              fontSize: field?.settings?.font_size,
                              color: field?.settings?.font_color,
                            }}
                          >
                            {field?.text}
                          </MDTypography>
                        ) : field?.type == "text-area" ? (
                          <>
                            <MDTypography
                              variant="button"
                              display="block"
                              gutterBottom
                              sx={{
                                color: field?.settings?.label_color
                                  ? field?.settings.label_color
                                  : "inheriat",
                                fontSize: field?.settings?.font_size
                                  ? `${field?.settings.font_size}px`
                                  : "inheriat",
                              }}
                            >
                              <b> {field?.label}</b>
                            </MDTypography>
                            <textarea
                              name=""
                              id=""
                              placeholder={field?.placeholder}
                              class="form-control"
                              cols="30"
                              rows="10"
                            ></textarea>
                          </>
                        ) : field?.type == "select" ? (
                          <>
                            <MDTypography
                              variant="button"
                              display="block"
                              gutterBottom
                              sx={{
                                color: field?.settings?.label_color
                                  ? field?.settings.label_color
                                  : "inheriat",
                                fontSize: field?.settings?.font_size
                                  ? `${field?.settings.font_size}px`
                                  : "inheriat",
                              }}
                            >
                              <b> {field?.label}</b>
                            </MDTypography>
                            <select className="form-control" name="" id="">
                              {field?.options.map((option) => (
                                <option value={option?.value}>
                                  {option?.label}
                                </option>
                              ))}
                            </select>
                            {/* <input
                            value={""}
                            type={field?.type}
                            class="form-control"
                            placeholder={field?.placeholder}
                          /> */}
                          </>
                        ) : (
                          <>
                            <MDTypography
                              variant="button"
                              display="block"
                              gutterBottom
                              sx={{
                                color: field?.settings?.label_color
                                  ? field?.settings.label_color
                                  : "inheriat",
                                fontSize: field?.settings?.font_size
                                  ? `${field?.settings.font_size}px`
                                  : "inheriat",
                              }}
                            >
                              <b> {field?.label}</b>
                            </MDTypography>
                            <input
                              value={""}
                              type={field?.type}
                              class="form-control"
                              placeholder={field?.placeholder}
                            />
                          </>
                        )}
                      </div>
                    ))}
                    <MDButton
                      variant="outlined"
                      color="info"
                      fullWidth
                      onClick={() => handleRightbar("open", index, "main")}
                      startIcon={<AddBoxIcon />}
                    />
                  </MDBox>
                  {/* {index == slides.length - 1 ? (
                    <MDButton
                      variant="contained"
                      color="info"
                      fullWidth
                      sx={{ marginTop: 3 }}
                    >
                      Submit
                    </MDButton>
                  ) : (
                    <MDButton
                      variant="contained"
                      color="info"
                      fullWidth
                      sx={{ marginTop: 3 }}
                    >
                      Next
                    </MDButton>
                  )} */}
                </MDBox>
              ))}
              <AddBoxIcon sx={{ cursor: "pointer" }} onClick={handleSlides} />
            </MDBox>
          </MDBox>
          <div className="right-sidebar-menu">
            <div className="toogle_btn">
              <CloseIcon
                className="close-icon"
                sx={{ cursor: "pointer" }}
                onClick={() => handleRightbar("close")}
              />
            </div>
            <MDBox px={3} mt={3}>
              {activeForm == "logo" ? (
                <></>
              ) : (
                <>
                  <div class="form-group mb-2">
                    <MDTypography variant="button" display="block" gutterBottom>
                      <b> Select Element Type</b>
                    </MDTypography>
                    <select
                      name="type"
                      id=""
                      className="form-control"
                      value={activeForm}
                      onChange={(e) => setactiveForm(e.target.value)}
                    >
                      <option value="">none</option>
                      <option value="simple_text">Text</option>
                      <option value="image">Image</option>
                      <option value="video">Video</option>
                      <option value="custom_value">Custom Value</option>
                    </select>
                  </div>
                  <hr />
                </>
              )}
              {activeForm == "custom_value" ? (
                <CustomFields />
              ) : activeForm == "logo" ? (
                <LogoFields />
              ) : activeForm == "simple_text" ? (
                <TextField />
              ) : activeForm == "image" ? (
                <FileField type={"image"} />
              ) : activeForm == "video" ? (
                <FileField type={"video"} />
              ) : (
                <></>
              )}
              {/* <Tabs elements={[<FormFields />, <CustomFields />]} /> */}
            </MDBox>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default Default;

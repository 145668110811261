import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "./components/ComplexStatisticsCard";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import axios from "axios";
import Loader from "examples/Loader";
import React, { useEffect, useState } from "react";
import "./snapshots.css";
import localforage from "localforage";
import env from "config";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
function Snapshots() {
  const [processing, setProcessing] = useState(true);
  const Service = useAppServices();
  const [agency] = useAgencyInfo();
  const [main_data, setmain_data] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [Purchasedapps, setPurchasedapps] = useState([]);
  const [configapps, setconfigapps] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [error, seterror] = useState("");
  const [snapshotfalse, setsnapshotfalse] = useState("");
  const [snapshotsuccess, setsnapshotsuccess] = useState("");
  const BASE_URL = `${env.API_URL}`;
  const getApps = async (e) => {
    const { response } = await Service.app.get();
    var temp = [];
    if (response) {
      var newArray = response.data.filter(function (item) {
        return item.status.value != "comming soon";
      });
      for (let index = 0; index < newArray.length; index++) {
        const element = newArray[index];
        var price = element.basic.value.substr(
          0,
          element.basic.value.indexOf(" ")
        );
        temp.push({
          label: element.name,
          value: `${price} (${element.app_id}) ${element.name}`,
        });
      }
      setPurchasedapps(temp);
    }
  };
  const getPurchasedApp = async (apps_data) => {
    const { response } = await Service.purchased_app.filter({
      query: `agency_id=${agency._id}`,
    });
    if (response) {
      setconfigapps(response.data);
    }
  };
  const getAgency_data = async () => {
    const { response } = await Service.agency.getmain_agency({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "getAgency_data");
    if (response) {
      const catgories = response.data?.category;

      const snapshots = response.data?.sanpshots;
      var stripeProducts = [];
      if (
        response.data?.prices != "" &&
        response.data?.location_settings.currency != ""
      ) {
        var newArray = response.data?.prices.data.filter(function (item) {
          return (
            item.currency == response.data?.location_settings.currency &&
            item.type == "recurring"
          );
        });
        stripeProducts = newArray;
        console.log(
          newArray,
          "stripeProductsstripeProductsstripeProductsstripeProducts"
        );
      }
      const workflows = response.data?.workflows;
      var catgories_data = [];
      var snapshot_catgories = [];
      var service_catgories = [];
      var snapshots_data = [];
      var workflows_data = [];
      var stripeProducts_data = [];
      var custom_data = [];
      catgories.forEach((element) => {
        element.type.forEach((el) => {
          if (el.type != "service") {
            const temp = {
              label: el.name,
              value: el._id,
            };
            snapshot_catgories.push(element);
          }
        });
        if (element.type == "service" || element.type == "both") {
          const temp = {
            label: element.name,
            value: element._id,
          };
          service_catgories.push(temp);
        }
      });
      for (let index = 0; index < catgories.length; index++) {
        const element = catgories[index];
        const temp = {
          label: element.name,
          value: element._id,
        };
        catgories_data.push(temp);
      }
      for (let index = 0; index < stripeProducts.length; index++) {
        const element = stripeProducts[index];
        var temp_prices = {};
        if (element.type == "recurring") {
          temp_prices = {
            label:
              element.unit_amount / 100 +
              "(" +
              element.recurring.interval +
              ")-" +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            value:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            type: element.type,
            currency: element.currency,
          };
        } else {
          temp_prices = {
            label:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            value:
              element.unit_amount / 100 +
              "(" +
              element.nickname +
              ")-" +
              element.id,
            type: element.type,
            currency: element.currency,
          };
        }

        stripeProducts_data.push(temp_prices);
      }

      for (let index = 0; index < snapshots.length; index++) {
        const element = snapshots[index];
        const temp = {
          label: element.name,
          value: element.id,
        };
        snapshots_data.push(temp);
      }

      for (let index = 0; index < workflows.workflows.length; index++) {
        const element = workflows.workflows[index];
        const temp = {
          label: element.name,
          value: element.id,
        };
        workflows_data.push(temp);
      }
      const data_d = {
        snapshots: snapshots_data,
        stripeProducts: stripeProducts_data,
        workflows: workflows_data,
        Categories: catgories_data,
        snapshot_catgories: snapshot_catgories,
        service_catgories: service_catgories,
      };
      console.log(data_d, "data_ddata_d");

      setmain_data(data_d);
      setProcessing(false);
    } else {
      const data_d = {
        snapshots: [],
        stripeProducts: [],
        workflows: [],
        Categories: [],
        snapshot_catgories: [],
        service_catgories: [],
      };
      setmain_data(data_d);
      setProcessing(false);
    }
  };

  const getCatgories = async () => {
    // alert('kjsh')
    const localBrand = await localforage.getItem("user");
    var axios = require("axios");
    const Category = await axios
      .get(BASE_URL + "/v1/snapshot/category/" + localBrand.agency_id)
      .then(async function (response) {
        console.log(response.data, "catefgory");
        setCategoryData(response.data.data);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(Category,'Category')
    return {
      success: true,
      message: "true",
      data: Category.data,
    };
  };

  const onLoad = async () => {
    console.log(agency?.agency_ghl);
    console.log(agency);
    setProcessing(true);
    if (agency?.ghl != undefined) {
      getPurchasedApp();
      await getApps();
      getAgency_data();
      getCatgories();
    } else {
      seterror("Ghl is not integrated correctly !");
      setProcessing(false);
    }
    // setrefresh(!refresh);
    // alert(refresh)
  };
  const handlemessage = (e, type) => {
    if (type == "false") {
      setsnapshotfalse(e);
    } else {
      setsnapshotsuccess(e);
    }
    setTimeout(() => {
      setsnapshotsuccess("");
      setsnapshotfalse("");
    }, 3000);
  };
  useEffect(
    onLoad,

    []
  );
  return (
    <DashboardLayout>
      {error != "" ? (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="warning">{error}</Alert>
        </Stack>
      ) : (
        <>
          {processing ? (
            <Loader />
          ) : (
            <>
              <DashboardNavbar
                type="snapshot"
                data={main_data}
                Purchasedapps={Purchasedapps}
                handleChange={onLoad}
                handlemessag={handlemessage}
              />
              {snapshotfalse != "" ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{snapshotfalse}</Alert>
                </Stack>
              ) : (
                <></>
              )}
              {snapshotsuccess != "" ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="success">{snapshotsuccess}</Alert>
                </Stack>
              ) : (
                <></>
              )}
              <Grid container mt={3}>
                <Grid item xs={12}>
                  <MDBox mb={3}>
                    {CategoryData.map((category, key) => (
                      <ComplexStatisticsCard
                        color="info"
                        icon="weekend"
                        title="Category"
                        category_data={category}
                        configapps={configapps}
                        data={main_data}
                        Purchasedapps={Purchasedapps}
                        refresh={refresh}
                        handleChange={onLoad}
                        handleCategoryChange={getCatgories}
                        handlemessag={handlemessage}
                      />
                    ))}
                  </MDBox>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default Snapshots;

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Multiselect from "./inputs/Multiselect";
import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";

const ChargeWrapper = (props) => {
  const [Actual_amount, setActual_amount] = useState("0");
  const [handelamount, sethandelamount] = useState(false);
  var price = 0;
  const handleAmount = (e) => {
    console.log(e);
    price = 0;
    e.forEach((element) => {
      price += element?.price;
    });
    setActual_amount(price);
  };

  return (
    <>
      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          Select user
        </MDTypography>
        <MDBox mb={1} mt={1} sx={{ fontSize: "14px" }}>
          <Multiselect
            data={props.charge_data.user_data}
            name="user"
            selectType="single"
          />
        </MDBox>
      </Grid>
      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          Select Locations
        </MDTypography>
        <MDBox mb={1} mt={1} sx={{ fontSize: "14px" }}>
          <Multiselect
            data={props.charge_data.locations_data}
            name="locations"
          />
        </MDBox>
      </Grid>
      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          Select snapshots
        </MDTypography>
        <MDBox mb={1} mt={1} sx={{ fontSize: "14px" }}>
          <Multiselect
            data={props.charge_data.snapshot_data}
            name="snapshots"
            handleAmount={handleAmount}
            type="snapshot"
          />
        </MDBox>
      </Grid>

      <input type="hidden" name={"price"} value={Actual_amount} />
      <Grid item md={12} mt={2} mb={1}>
        <MDTypography variant="h6" mt={1}>
          make custom amount
          <Switch onClick={(e) => sethandelamount(!handelamount)} />
        </MDTypography>
        {handelamount ? (
          <>
            <MDTypography mb={1} variant="button" display="block" gutterBottom>
              How Much You want to charge the Customer?
            </MDTypography>
            <MDBox mb={1}>
              <input type="number" name="charge" className="form-control" />
            </MDBox>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item md={12}>
        <MDBox mb={1} sx={{ display: "flex" }} a>
          <p>Actual Price:</p>
          <h3> ${Actual_amount}</h3>
        </MDBox>
      </Grid>
    </>
  );
};

export default ChargeWrapper;

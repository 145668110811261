import Button from "@mui/material/Button";
import localforage from "localforage";
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Switch,
  TextareaAutosize,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Singleselect from "./inputs/Singleselect";
import MDTypography from "components/MDTypography";
import "../components/inputs/input.css";
import Alert from "@mui/material/Alert";
import { useBrandInfo } from "context/brand";
import Stack from "@mui/material/Stack";
import ReactDOM from "react-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useState, useEffect } from "react";
import StripeLogo from "../../../../assets/images/logos/Stripe_Logosvg.png";
import ghlLogo from "../../../../assets/images/ghl-logo.avif";
import input from "assets/theme/components/form/input";
import Billing from "./Billing";
import env from "config";
import { useAgencyInfo } from "context/agency";
import Loader from "examples/Loader";
const Integration = () => {
  const [brand] = useBrandInfo();
  const [agency] = useAgencyInfo();
  const [processing, setProcessing] = useState(false);
  const [saved_data, setsaved_data] = useState("");
  const [agency_id, setagency_id] = useState("");
  const [domain, setdomain] = useState("");
  const [loc_id, setloc_id] = useState("");
  const [copy, setcopy] = useState(false);
  const [copyurl, setcopyurl] = useState("");
  const updateAgency = useAgencyInfo()[3];
  const [errormsg, seterrormsg] = useState("");
  const [response, setresponse] = useState("");
  const [app_setup, setapp_setup] = useState({});
  const [refresh, setrefresh] = useState(true);
  const [loader, setloader] = useState(true);
  const [domainUpdate, setdomainUpdate] = useState(false);
  const [workflows_data, setworkflows_data] = useState([]);
  const [locations, setlocations] = useState([]);
  const [defeult_password, setdefeult_password] = useState([]);

  const BASE_URL_V1 = `${env.API_URL}/v1`;
  const BASE_URL_V2 = `${env.API_URL}/v2`;
  const currency_data = [
    {
      id: "usd",
      name: "USD",
    },
    {
      id: "gbp",
      name: "GBP",
    },
    {
      id: "eur",
      name: "EUR",
    },
    {
      id: "cad",
      name: "CAD",
    },
    {
      id: "aud",
      name: "AUD",
    },
  ];
  var axios = require("axios");
  React.useEffect(async () => {
    seterrormsg("");
    setdomain("");
    // setresponse('');
    const localBrand = await localforage.getItem("user");
    setagency_id(localBrand.agency_id);
    // alert(localBrand.stripe.customer_id)
    const response = await axios
      .get(
        BASE_URL_V2 +
          "/snapshot/agency/location_settings/filter/" +
          localBrand.agency_id
      )
      .then((response) => {
        setsaved_data(response.data);
        // setloc_api_key(response.data.data.loc_api_key);
        setapp_setup(response.data.data);
        setloc_id(response.data.data?.loc_id || "");
        var domain = "https://app.levelupmarketplace.io";
        if (response.data?.data?.domain) {
          domain = "https://" + response.data?.data?.domain;
        }
        if (response.data.data?.loc_id) {
          setcopyurl(
            `${domain}/agency/app/${response.data.data?.loc_id}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}/apps?sessionkey={{user.sessionKey}}`
          );
        }
        setdomain(response.data.data.domain);
        console.log(response.data.data.domain);

        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    if (agency.ghl != undefined) {
      await axios
        .get(
          BASE_URL_V2 +
            "/snapshot/agency/location_settings/getworkflows/" +
            localBrand.agency_id
        )
        .then((response) => {
          console.log(response.data, "getworkflows");
          setworkflows_data(response.data.data.workflows.workflows || []);
          setlocations(response.data.data.locations || []);
          var temp_dpass = response.data.data.custom_fields.customFields.filter(
            function (item) {
              return item.dataType == "TEXT";
            }
          );
          console.log(temp_dpass, "custom_fieldscustom_fieldscustom_fields");
          temp_dpass.sort((a, b) => a.name.localeCompare(b.name));

          setdefeult_password(temp_dpass);
          setloader(false);
        })
        .catch(function (error) {
          console.log("error");
          setworkflows_data([]);
          setdefeult_password([]);
          setloader(false);
          setTimeout(() => {
            seterrormsg(
              "Your Auth Token Is Expired Please Refresh Your Token!"
            );
          }, 2009);
        });
    } else {
      setloader(false);
      seterrormsg("Your Auth Token Is Expired Please Refresh Your Token!");
    }
  }, [refresh]);

  var data = "";
  const handlecopy = (e) => {
    setcopy(true);
    setTimeout(() => {
      setcopy(false);
    }, 2000);
  };
  const domain_manage = (e) => {
    setdomain(e);
    setdomainUpdate(true);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    // setProcessing(true);
    updateAgency();

    // 4848 7150 5203 7927
    var currency_sign = "";
    if (e.target.currency.value == "gbp") {
      currency_sign = "£";
    } else if (e.target.currency.value == "eur") {
      currency_sign = "€";
    } else {
      currency_sign = "$";
    }
    var defeult_password = "";
    var workflows = "";
    var forget_password = "";
    var user_role = "";
    var LocationId = "";
    var logged_in = "";
    defeult_password = e.target.defeult_password.value;
    workflows = e.target.workflows.value;
    forget_password = e.target.forget_password.value;
    user_role = e.target.user_role.value;
    LocationId = e.target.LocationId.value;
    logged_in = e.target.logged_in.value;

    data = {
      domainUpdate: domainUpdate,
      domain: e.target.domain.value,
      currency: e.target.currency.value,
      app_purchase: e.target.app_purchase.value,
      defeult_password: defeult_password,
      workflows: workflows,
      user_role: user_role,
      LocationId: LocationId,
      logged_in: logged_in,
      currency_sign: currency_sign,
      loc_id: e.target.loc_id.value,
      ghl_domain: e.target.ghl_domain.value,
      domain: e.target.domain.value,
      agency_id: agency_id,
      forget_password: forget_password,
      task_completed: e.target.task_completed.value,
      purchase_message: e.target.purchase_message.value,
    };
    console.log(data, "data");
    // return 0;
    if (saved_data === "") {
      axios
        .post(BASE_URL_V2 + "/snapshot/agency/location_settings/submit", {
          data,
        })
        .then(async function (response) {
          setProcessing(false);
          console.log(response.data);
          setsaved_data(response.data);
          setrefresh(!refresh);
          setresponse("Setting Updated");
          setTimeout(() => {
            setresponse("");
          }, 2000);
        })
        .catch(function (error) {
          setProcessing(false);

          console.log(error.response.data);
          seterrormsg(error.response.data.message);
        });
    } else {
      axios
        .post(
          BASE_URL_V2 +
            "/snapshot/agency/location_settings/update/" +
            saved_data.data._id,
          { data }
        )
        .then(async function (response) {
          console.log(response.data);
          setProcessing(false);

          setrefresh(!refresh);
          await setresponse(response.data.message);
          setTimeout(() => {
            setresponse("");
          }, 2200);
          setsaved_data(response.data);
        })
        .catch(function (error) {
          setProcessing(false);
          console.log(error.response.data);
          seterrormsg(error.response.data.message);
        });
    }
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <MDBox pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container mt={5} mb={2} justifyContent="center">
                <Stack sx={{ width: "90%" }} spacing={2} mb={2}>
                  {errormsg != "" ? (
                    <Alert severity="error">{errormsg}</Alert>
                  ) : (
                    <></>
                  )}
                  {response != "" ? (
                    <Alert severity="success">{response}</Alert>
                  ) : (
                    <></>
                  )}
                </Stack>
                <Grid item xs={11}>
                  <MDBox display="flex">
                    <Grid item xs={3}>
                      <img src={StripeLogo} alt="logo" width={140} />
                      <Billing />
                    </Grid>
                    {agency.type != "free" ? (
                      <Grid item xs={3}>
                        <MDBox
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <img src={ghlLogo} alt="logo" width={100} />

                          {agency?.agency_ghl != undefined ? (
                            <MDButton
                              variant="contained"
                              color="success"
                              size="small"
                              sx={{ width: "100px" }}
                            >
                              <a
                                href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.AGENCY}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.APPSCOPE}&state=${brand.ghl?.location_id}`}
                                style={{ color: "white" }}
                              >
                                Agency Refresh
                              </a>
                            </MDButton>
                          ) : (
                            <MDButton
                              variant="contained"
                              color="info"
                              size="small"
                              sx={{ width: "100px" }}
                            >
                              <a
                                href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.AGENCY}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.APPSCOPE}&state=${brand.ghl?.location_id}`}
                                style={{ color: "white" }}
                              >
                                Agency connect
                              </a>
                            </MDButton>
                          )}
                        </MDBox>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                      <img src={ghlLogo} alt="logo" width={100} />

                      {agency?.ghl != undefined ? (
                        <MDButton
                          variant="contained"
                          color="success"
                          size="small"
                          sx={{ width: "100px" }}
                        >
                          <a
                            href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.Location_ASSOCIATE}&client_id=${env.GHL.Location_CLIENT_ID}&scope=${env.GHL.Location_SCOPE}&state=${brand.ghl?.location_id}`}
                            style={{ color: "white" }}
                          >
                            Location Refresh
                          </a>
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="contained"
                          color="info"
                          size="small"
                          sx={{ width: "100px" }}
                        >
                          <a
                            href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.Location_ASSOCIATE}&client_id=${env.GHL.Location_CLIENT_ID}&scope=${env.GHL.Location_SCOPE}&state=${brand.ghl?.location_id}`}
                            style={{ color: "white" }}
                          >
                            Location connect
                          </a>
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>

                  <MDBox pt={4} px={3}>
                    <form onSubmit={handlesubmit}>
                      <MDTypography
                        id="modal-modal-title"
                        sx={{ mb: 1 }}
                        variant="h6"
                        component="h2"
                      >
                        White Label Domain
                      </MDTypography>
                      <div className="row">
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className=" form-control"
                            value={domain}
                            onChange={(e) => domain_manage(e.target.value)}
                            name="domain"
                            placeholder="Add a white label domain."
                            autoFocus="off"
                            autocomplete={"off"}
                          />
                        </div>
                      </div>
                      <MDTypography
                        id="modal-modal-title"
                        sx={{ mb: 1, mt: 4 }}
                        variant="h6"
                        component="h2"
                      >
                        GHL Domain
                      </MDTypography>
                      <div className="row">
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className=" form-control"
                            defaultValue={app_setup.ghl_domain}
                            name="ghl_domain"
                            placeholder="Add a white label domain."
                            autoFocus="off"
                            autocomplete={"off"}
                          />
                        </div>
                      </div>
                      <MDBox display="flex" alignItems="center">
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mb: 1, mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Add Location Id For Test Store
                        </MDTypography>
                        <CopyToClipboard
                          text={copyurl}
                          onCopy={handlecopy}
                          sx={{ mb: 1, mt: 4, ml: 2 }}
                        >
                          <Button
                            variant="contained"
                            type="button"
                            size="small"
                            color="primary"
                            className={"text-light btn-success"}
                          >
                            {copy ? "Copied" : "Copy Store Url"}
                          </Button>
                        </CopyToClipboard>
                      </MDBox>
                      <MDTypography
                        id="modal-modal-title"
                        variant="caption"
                        component="h2"
                        mb={1}
                      >
                        Please add the location id of the account you wish to
                        test the apps and widgets on. You can find the location
                        ID in the url of your Go High Level Account. Example:
                        <b>OT2zVLJMI3a3HvXJhu4Me5c</b> The test account or
                        sandbox account is a location where you will not be
                        charge ANYTHING for testing the apps. It is a great
                        place to make snapshots that have apps as part of the
                        snapshot so you can make bundles. You can only have 1
                        test account at a time and if you wish to change your
                        current location you will need to contact support.
                      </MDTypography>
                      <div className="row">
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className=" form-control"
                            onChange={(e) => setloc_id(e.target.value)}
                            name="loc_id"
                            defaultValue={loc_id}
                            placeholder="Add Location Id For Test Store."
                            autoFocus="off"
                            disabled={app_setup.loc_id ? true : false}
                            autocomplete={"off"}
                          />
                        </div>
                      </div>
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Select Currency
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            disabled={true}
                            data={currency_data}
                            edit_data={app_setup.currency || ""}
                            name="currency"
                            label="currency"
                          />
                        </MDBox>
                      </div>
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which workflow would you like to trigger when a
                          customer creates an account?
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={workflows_data}
                            edit_data={app_setup.workflows || ""}
                            name="workflows"
                            label="workflow"
                          />
                        </MDBox>
                      </div>
                      {/* {workflows_data.length != 0 ? (
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which workflow would you like to trigger when a
                          customer creates an account?
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={workflows_data}
                            edit_data={workflows_edit}
                            name="workflows"
                            label="workflow"
                          />
                        </MDBox>
                      </div>
                    ) : (
                      <input type="hidden" name="workflows" />
                    )} */}
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Where would you like to save the location that
                          purchase the listing?
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={defeult_password}
                            edit_data={app_setup.defeult_password || ""}
                            name="location_purchased"
                            label="location field"
                          />
                        </MDBox>
                      </div>
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which field would you like store their password to?
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={defeult_password}
                            edit_data={app_setup.defeult_password || ""}
                            name="defeult_password"
                            label="password"
                          />
                        </MDBox>
                      </div>
                      {agency.type == "premium" ? (
                        <>
                          <div>
                            <MDTypography
                              id="modal-modal-title"
                              sx={{ mt: 4 }}
                              variant="h6"
                              component="h2"
                            >
                              Which field would you like store their Loggen In
                              Value to?
                            </MDTypography>
                            <MDTypography
                              id="modal-modal-title"
                              variant="caption"
                              component="h2"
                              mb={1}
                            >
                              must complete*
                            </MDTypography>
                            <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                              <Singleselect
                                data={defeult_password}
                                edit_data={app_setup.logged_in || ""}
                                name="logged_in"
                                label="Loggen In Value"
                              />
                            </MDBox>
                          </div>
                          <div>
                            <MDTypography
                              id="modal-modal-title"
                              sx={{ mt: 4 }}
                              variant="h6"
                              component="h2"
                            >
                              Which field would you like store their LocationId
                              to?
                            </MDTypography>
                            <MDTypography
                              id="modal-modal-title"
                              variant="caption"
                              component="h2"
                              mb={1}
                            >
                              must complete*
                            </MDTypography>
                            <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                              <Singleselect
                                data={defeult_password}
                                edit_data={app_setup.LocationId || ""}
                                name="LocationId"
                                label="LocationId"
                              />
                            </MDBox>
                          </div>
                          <div>
                            <MDTypography
                              id="modal-modal-title"
                              sx={{ mt: 4 }}
                              variant="h6"
                              component="h2"
                            >
                              Which field would you like store their User Role
                              to?
                            </MDTypography>
                            <MDTypography
                              id="modal-modal-title"
                              variant="caption"
                              component="h2"
                              mb={1}
                            >
                              must complete*
                            </MDTypography>
                            <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                              <Singleselect
                                data={defeult_password}
                                edit_data={app_setup.user_role || ""}
                                name="user_role"
                                label="User Role"
                              />
                            </MDBox>
                          </div>
                        </>
                      ) : (
                        <>
                          <input type="hidden" name="logged_in" />
                          <input type="hidden" name="LocationId" />
                          <input type="hidden" name="user_role" />
                        </>
                      )}
                      {/* {defeult_password.length != 0 ? (
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which field would you like store their password to?
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={defeult_password}
                            edit_data={defeult_password_edit}
                            name="defeult_password"
                            label="password"
                          />
                        </MDBox>
                      </div>
                    ) : (
                      <input type="hidden" name="defeult_password" />
                    )} */}
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which workflow would you like to trigger when a
                          customer forgets their password?
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={workflows_data}
                            edit_data={app_setup.forget_password || ""}
                            name="forget_password"
                            label="forget password"
                          />
                        </MDBox>
                      </div>
                      {/* {workflows_data.length != 0 ? (
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which workflow would you like to trigger when a
                          customer forgets their password?
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={workflows_data}
                            edit_data={forget_password_edit}
                            name="forget_password"
                            label="forget password"
                          />
                        </MDBox>
                      </div>
                    ) : (
                      <input type="hidden" name="forget_password" />
                    )} */}
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which workflow would you like to trigger when a
                          customer makes a purchase?
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={workflows_data}
                            edit_data={app_setup.app_purchase || ""}
                            name="app_purchase"
                            label="App Purchase"
                          />
                        </MDBox>
                      </div>
                      {agency.type == "free" ? (
                        <>
                          <div>
                            <MDTypography
                              id="modal-modal-title"
                              sx={{ mt: 4 }}
                              variant="h6"
                              component="h2"
                            >
                              Which workflow would you like to trigger when you
                              load snapshot for the customer?
                            </MDTypography>
                            <MDTypography
                              id="modal-modal-title"
                              variant="caption"
                              component="h2"
                            >
                              must complete*
                            </MDTypography>
                            <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                              <Singleselect
                                data={workflows_data}
                                edit_data={app_setup.task_completed || ""}
                                name="task_completed"
                                label="App Purchase"
                              />
                            </MDBox>
                          </div>
                        </>
                      ) : (
                        <>
                          <input type="hidden" name="task_completed" />
                        </>
                      )}

                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          What Message would you like to Show when a customer
                          makes a purchase?
                        </MDTypography>
                        {/* <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                        >
                          must complete*
                           </MDTypography> */}
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={1}
                            className="form-control"
                            defaultValue={app_setup.purchase_message}
                            name="purchase_message"
                            placeholder="description"
                            style={{ width: "100%", paddingLeft: "13px" }}
                          />
                        </MDBox>
                      </div>
                      <div className="row mt-4">
                        <div className="col-sm-12 d-flex justify-content-end ">
                          {saved_data === "" ? (
                            <>
                              <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                className="text-light btn-success "
                                loading={processing}
                              >
                                Save
                              </Button>
                            </>
                          ) : (
                            <>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                loading={processing}
                                disabled={processing}
                              >
                                Update
                              </MDButton>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </MDBox>
                </Grid>
                <Grid item xs={11}>
                  <MDBox pt={4} px={3}>
                    <MDTypography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Connect white label domain
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      Connect your own domain to the system (without https://)
                      levelupmarketplace.yourdomain.com
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      You can use any domain you own or you can create a
                      sub-domain like levelupmarketplace.yourdomain.com to get
                      white-labeled access to the platform.
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>1.</b> Refer to your domain registrar's control panel,
                      choose desired domain zone.
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>2.</b>Create a record of{" "}
                      <u>
                        <b>ANAME</b>
                      </u>{" "}
                      type, enter your domain/subdomain name and point it to{" "}
                      <u>
                        <b>167.71.131.180</b>
                      </u>
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>3.</b> We will do the rest!
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </>
  );
};

export default Integration;

import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDemoData } from "@mui/x-data-grid-generator";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import { useState } from "react";
import { GridApi } from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import "../category.css";
import CategoryEdit from "./CategoryEdit";
import Category_model from "./Category_model";
import localforage from "localforage";
import env from "config";
import Category_Edit from "layouts/agency/components/Category_Modal/Edit";
import Chip from "@mui/material/Chip";
// import Stack from '@mui/material/Stack';
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Editcustom_link from "./Editcustom_link";
function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export default function Dragtable(props) {
  const [rows, setRows] = React.useState(props.rows);
  const [update, setupdate] = React.useState(false);
  const [errormsg, seterrormsg] = React.useState("");
  const [response, setresponse] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [copyurl, setcopyurl] = useState("");
  const [myappcopy, setmyappcopy] = useState(false);
  const [myapp_url, setmyapp_url] = useState(false);
  const [copyscript, setcopyscript] = useState("");
  const [copy, setcopy] = React.useState(false);
  const [copyscriptvalue, setcopyscriptvalue] = React.useState(false);
  const [Custom_link, setCustom_link] = React.useState([]);

  const handleMessage = (e, type) => {
    if (type == "true") {
      setresponse(e);
    } else {
      seterrormsg(e);
    }
    setTimeout(() => {
      seterrormsg("");
      setresponse("");
    }, 3000);
  };
  const BASE_URL = `${env.API_URL}/v1`;
  const BASE_URL_V2 = `${env.API_URL}/v2`;

  const columns_data = [
    {
      field: "name",
      headerName: "Name",
      width: 380,
    },
    {
      field: "description",
      headerName: "Description",
      width: 380,
    },
    {
      field: "type",
      headerName: "Type",
      width: 450,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          console.log(params.row);
          return alert("JSON.stringify(thisRow, null, 4)");
        };

        return (
          <>
            <Stack spacing={1} alignItems="left">
              <Stack direction="row" spacing={1}>
                {params.row.type.map((category, key) => (
                  <Chip label={category.label} size="small" color="primary" />
                ))}
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "id",
      width: 180,
      headerName: "Action",
      renderCell: (params) => {
        console.log(params);
        return (
          <>
            <CategoryEdit
              handleMessage={handleMessage}
              multiselect={props.custom_link}
              data={params.row}
              type={props.type}
              handleChange={props.handleChange}
            />

            <DeleteIcon
              sx={{ cursor: "pointer" }}
              onClick={handledelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];
  var axios = require("axios");
  const handleRow = (e) => {
    e.stopPropagation(); // don't select this row after clicking
  };

  const handledelete = (id) => () => {
    var axios = require("axios");

    axios
      .post(BASE_URL + "/snapshot/category/delete/" + id)
      .then(async function (response) {
        console.log(response.data);
        setresponse("Category Deleted Successfully");
        setTimeout(() => {
          setresponse("");
        }, 3000);
        props.handleChange(true);
      })
      .catch(function (error) {
        console.log(error);
        seterrormsg(error.response.data.message);
        setTimeout(() => {
          seterrormsg("");
        }, 3000);
      });
  };
  const handleDelete = (id) => {
    axios
      .post(BASE_URL + "/snapshot/custom_link/delete/" + id)
      .then(async function (response) {
        console.log(response.data);
        props.handleChange(true);
        // setOpenAddProduct(false)
        // props.handleMessage('Category Added Successfully','true')
      })
      .catch(function (error) {
        console.log(error);
        // setOpenAddProduct(false)
        // props.handleMessage(error.response.data.message,'false')
      });
  };
  const handlecategory = () => {
    console.log(rows);
    const type = props.type;
    var name = "";
    if (type == "custom") {
      name = props.name;
    }

    axios
      .post(BASE_URL + "/snapshot/category/indexchange", { rows, type, name })
      .then(async function (response) {
        console.log(response.data);
        setresponse("Priority Changed Successfully");
        setTimeout(() => {
          setresponse("");
        }, 3000);
        props.handleChange(true);
      })
      .catch(function (error) {
        console.log(error.response.data);
        seterrormsg(error.response.data.message);
        setTimeout(() => {
          seterrormsg("");
        }, 3000);
      });
    setupdate(false);
  };
  React.useEffect(async () => {
    var axios = require("axios");
    const localBrand = await localforage.getItem("user");
    var domain = "https://app.levelupmarketplace.io";
    const response = await axios
      .get(
        BASE_URL_V2 +
          "/snapshot/agency/location_settings/filter/" +
          localBrand.agency_id
      )
      .then((response) => {
        // alert(response.data.data.domain,'domain')
        console.log(response.data.data.domain);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    // alert(props)
    if (response?.data?.domain) {
      domain = "https://" + response?.data?.domain;
    }
    setmyapp_url(
      `${domain}/apps/myapps/{{location.id}}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}?sessionkey={{user.sessionKey}}`
    );
    const copyfunc = {
      both: () => {
        setcopyurl(
          `${domain}/store/everything/{{location.id}}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}/Snapshot?sessionkey={{user.sessionKey}}`
        );
      },
      snapshot: () => {
        setcopyurl(
          `${domain}/store/snapshot/{{location.id}}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}/Snapshot?sessionkey={{user.sessionKey}}`
        );
      },
      funnels: () => {
        setcopyurl(
          `${domain}/store/funnels/{{location.id}}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}/Snapshot?sessionkey={{user.sessionKey}}`
        );
        setcopyscript(`<script src="https://api.ghl.store/api/v1/script/funnel/${localBrand.agency_id}"></script>
      <script src="https://api.ghl.store/api/v1/script/63c6bd1324f7588d5041332a"></script>
      `);
      },
      service: () => {
        setcopyurl(
          `${domain}/store/service/{{location.id}}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}/Snapshot?sessionkey={{user.sessionKey}}`
        );
      },
      websites: () => {
        setcopyurl(
          `${domain}/store/websites/{{location.id}}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}/Snapshot?sessionkey={{user.sessionKey}}`
        );
        setcopyscript(`<script src="https://api.ghl.store/api/v1/script/sites/${localBrand.agency_id}"></script>
      <script src="https://api.ghl.store/api/v1/script/63c6bd1324f7588d5041332a"></script>
      `);
      },
      automation: () => {
        setcopyurl(
          `${domain}/store/automation/{{location.id}}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}/Snapshot?sessionkey={{user.sessionKey}}`
        );
        setcopyscript(`<script src="https://api.ghl.store/api/v1/script/automation/${localBrand.agency_id}"></script>
      <script src="https://api.ghl.store/api/v1/script/63c6bd1324f7588d5041332a"></script>
      `);
      },
      custom: () => {
        setcopyurl(
          `${domain}/store/${props.name}/{{location.id}}/{{location.name}}/{{location.address}}/agency/${localBrand.agency_id}/Snapshot?sessionkey={{user.sessionKey}}`
        );
      },
    };
    copyfunc[props.type]();
    setupdate(false);
    setRows(props.rows);
  }, [props]);
  const handleRowOrderChange = async (params) => {
    setLoading(true);
    setupdate(true);
    console.log(params);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );
    // console.log(newRows)
    var updates = [];
    for (let index = 0; index < newRows.length; index++) {
      var element = newRows[index];
      // element['priority']=index++;
      // updates.push
      console.log(index, element);
    }
    setRows(newRows);
    setLoading(false);
  };
  const handlecopy = (e) => {
    // alert(e)
    if (e == "script") {
      setcopyscriptvalue(true);

      setTimeout(() => {
        setcopyscriptvalue(false);
      }, 3000);
    } else if (e == "my apps") {
      setmyappcopy(true);

      setTimeout(() => {
        setmyappcopy(false);
      }, 3000);
    } else {
      setcopy(true);

      setTimeout(() => {
        setcopy(false);
      }, 3000);
    }
  };
  return (
    <Grid container mt={props.mt}>
      <Grid item xs={12} mb={2}>
        {errormsg != "" ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">{errormsg}</Alert>
          </Stack>
        ) : (
          <></>
        )}
        {response != "" ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">{response}</Alert>
          </Stack>
        ) : (
          <></>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          <h3>{props.heading} </h3>
          {props.type == "custom" ? (
            <>
              <Editcustom_link
                data={props.edit_data}
                custom_link={props.custom_link}
                type={props.type}
                handleChange={props.handleChange}
              />
              <DeleteIcon
                onClick={() => handleDelete(props.edit_data.value)}
                sx={{ marginTop: "8px", cursor: "pointer" }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <CopyToClipboard
            text={myapp_url}
            onCopy={() => handlecopy("everything")}
            sx={{ mr: 2 }}
          >
            <Button
              variant="contained"
              type="button"
              color="primary"
              className={"text-light btn-success"}
            >
              {myappcopy ? "Copied" : "Copy My Apps Url"}
            </Button>
          </CopyToClipboard>
          <CopyToClipboard
            text={copyurl}
            onCopy={() => handlecopy("everything")}
            sx={{ mr: 2 }}
          >
            <Button
              variant="contained"
              type="button"
              color="primary"
              className={"text-light btn-success"}
            >
              {copy ? "Copied" : "Copy Store Url"}
            </Button>
          </CopyToClipboard>
          {props.type == "funnels" ||
          props.type == "websites" ||
          props.type == "automation" ? (
            <CopyToClipboard
              text={copyscript}
              onCopy={() => handlecopy("script")}
              sx={{ mr: 2 }}
            >
              <Button
                variant="contained"
                type="button"
                color="primary"
                className={"text-light btn-success"}
              >
                {copyscriptvalue ? "Copied" : "Copy Script Url"}
              </Button>
            </CopyToClipboard>
          ) : (
            <></>
          )}

          <Category_model
            type={props.type}
            name={props.name}
            custom_link={props.custom_link}
            data={props.rows}
            handleChange={props.handleChange}
            handleMessage={handleMessage}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className={
              update
                ? "button text-light btn-success"
                : "text-light btn-success"
            }
            onClick={handlecategory}
          >
            Update Priority Order
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} mt={2}>
        <div style={{ height: 500, width: "100%" }}>
          <DataGridPro
            id="cat_table"
            loading={props.loading}
            rows={props.rows}
            columns={columns_data}
            rowReordering
            onRowOrderChange={handleRowOrderChange}
            onRowClick={handleRow}
          />
        </div>
      </Grid>
    </Grid>
  );
}
